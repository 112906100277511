import React, { useState, useEffect } from 'react'
import { capitaliseFirstLetter } from '../../utils/helper';
import config from '../../app-config';
import Select from 'react-select'
import Switch from 'react-switch'
import notify from '../../utils/IANotification';


export default function VerseComponent({ readOnly, data, onChange, onChangeOptions, options }) {
    const [bibleData, setBibleData] = useState([])
    const [bookData, setBookData] = useState([])
    const [chapterData, setChapterData] = useState([])
    const [verseData, setVerseData] = useState([])

    var content_tidied
    if (data) {
        content_tidied = data
    } else {
        content_tidied = ["", "", "", "", ""]
    }
    // console.log(content_tidied)
    const [selectedBibleData, setSelectedBibleData] = useState(content_tidied)

    const [passageData, setPassageData] = useState([])
    const [versesSelected, setVersesSelected] = useState([])
    const [selectedColor, setSelectedColor] = useState("yellow")
    const [highlightedVerses, setHighlightedVerses] = useState([])
    const [previewData, setPreviewData] = useState()

    const fetchAllBooks = async () => {
        // let endpoint = `${API_URL}/v1/bibles`
        let endpoint = config.endpoint + "/bible"
        let response = await fetch(endpoint, {
            headers: {
                "Accept": "application/json"
            }
        })
        let data = await response.json()
        if (data.error) return console.log(data.error)
        setBibleData(data.data)
    }

    const fetchBooks = async (bibleId) => {
        // let endpoint = `${API_URL}/v1/bibles/${bibleId}/books`
        if (!bibleId) return
        let endpoint = config.endpoint + `/bible/stats/books/${bibleId}`
        let response = await fetch(endpoint, {
            headers: {
                "Accept": "application/json"
            }
        })
        let data = await response.json()
        if (data.error) return console.log(data.error)
        setBookData(data.data)
    }

    const fetchChapters = async (bibleId, bookId) => {
        // let endpoint = `${API_URL}/v1/bibles/${bibleId}/books/${bookId}/chapters`
        if (!bookId || !bibleId) return
        let endpoint = config.endpoint + `/bible/stats/chapters/${bookId}?bible_id=${bibleId}`
        let response = await fetch(endpoint, {
            headers: {
                "Accept": "application/json"
            },
            credentials: "include",
        })
        let data = await response.json()
        if (data.error) return console.log(data.error)
        setChapterData(data.data)
    }

    const fetchPassage = async (bibleId, bookId, chapterId) => {
        // let endpoint = `${API_URL}/v1/bibles/${bibleId}/passages/${chapterId}`
        if (!chapterId || !bookId || !bibleId) return
        let endpoint = config.endpoint + `/bible/read?bible_id=${bibleId}&book_id=${bookId}&chapter_id=${chapterId}`
        let response = await fetch(endpoint, {
            headers: {
                "Accept": "application/json"
            },
            credentials: "include",
        })
        let data = await response.json()
        if (data.error) return console.log(data.error)
        setPassageData(data.data)
    }

    const fetchPassageRange = async (bibleId, bookId, chapterId, verseStart, verseEnd) => {
        // let endpoint = `${API_URL}/v1/bibles/${bibleId}/passages/${chapterId}`
        if (!chapterId || !bookId || !bibleId) return
        let endpoint = config.endpoint + `/bible/read/range?bible_id=${bibleId}&book_id=${bookId}&chapter_id=${chapterId}&verse_start=${verseStart}&verse_end=${verseEnd}`
        let response = await fetch(endpoint, {
            headers: {
                "Accept": "application/json"
            },
            credentials: "include",
        })
        let data = await response.json()
        if (data.error) return console.log(data.error)
        setPreviewData(data.data)
    }

    useEffect(() => {
        fetchAllBooks()
        // if (selectedBible.code) {
        //     fetchBooks(selectedBible.code)
        // }
        // if (selectedBook.book) {
        //     fetchChapters(selectedBible.code, selectedBook.book)
        // }

        // if (selectedChapter.chapter) {
        //     fetchPassage(selectedBible.code, selectedBook.book, selectedChapter.chapter)
        // }
        // setVersesSelected([])

        if (selectedBibleData[0]) {
            fetchBooks(selectedBibleData[0])
        }
        if (selectedBibleData[1]) {
            fetchChapters(selectedBibleData[0], selectedBibleData[1])
        }
        if (selectedBibleData[2]) {
            fetchPassage(selectedBibleData[0], selectedBibleData[1], selectedBibleData[2])
        }
        if (selectedBibleData[2] && selectedBibleData[3] && selectedBibleData[4]) {
            fetchPassageRange(selectedBibleData[0], selectedBibleData[1], selectedBibleData[2], selectedBibleData[3], selectedBibleData[4])
            onChange(selectedBibleData)
        }
        setVersesSelected([])

    }, [selectedBibleData])

    return (
        <>
            {readOnly ? <>
                <div
                    style={{

                    }}
                >
                    {previewData?.length > 0 && previewData?.map((verse, index) => {
                        return (
                            <p
                                style={{
                                    textAlign: "justify",
                                    padding: 10,
                                    fontSize: 16,
                                    whiteSpace: "pre-wrap",
                                }}
                            ><sup style={{ fontSize: 12 }}>{verse.verse}</sup> {verse.text}</p>
                        )
                    })}
                </div>
            </> :
                <>
                    <div className="input-container">
                        <label htmlFor="" className="input-label">Bible</label>
                        <Select
                            options={bibleData?.map(bible => ({ label: bible.name, value: bible.code }))}
                            value={bibleData?.map(bible => ({ label: bible.name, value: bible.code }))?.find(bible => bible.value == selectedBibleData[0])}
                            onChange={selected => {
                                setSelectedBibleData([selected?.value, selectedBibleData[1], selectedBibleData[2], selectedBibleData[3], selectedBibleData[4]])
                            }}
                        />
                    </div>
                    <div className="row gap-10 alignCenter">
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Book</label>
                            <Select
                                options={bookData?.map(book => ({ label: book.book_name, value: book.book }))}
                                value={bookData?.map(book => ({ label: book.book_name, value: book.book }))?.find(book => book.value == selectedBibleData[1])}
                                onChange={selected => {
                                    setSelectedBibleData([selectedBibleData[0], selected?.value, selectedBibleData[2], selectedBibleData[3], selectedBibleData[4]])
                                }}
                            />
                        </div>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Chapter</label>
                            <Select
                                options={chapterData?.map(chapter => ({ label: chapter.chapter, value: chapter.chapter }))}
                                value={chapterData?.map(chapter => ({ label: chapter.chapter, value: chapter.chapter }))?.find(chapter => chapter.value == selectedBibleData[2])}
                                onChange={selected => {
                                    setSelectedBibleData([selectedBibleData[0], selectedBibleData[1], selected?.value, selectedBibleData[3], selectedBibleData[4]])
                                }}
                            />
                        </div>
                        <p>:</p>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Verse</label>
                            <Select
                                options={passageData?.map(verse => ({ label: verse.verse, value: verse.verse }))}
                                value={passageData?.map(verse => ({ label: verse.verse, value: verse.verse }))?.find(verse => verse.value == selectedBibleData[3])}
                                onChange={selected => {
                                    setSelectedBibleData([selectedBibleData[0], selectedBibleData[1], selectedBibleData[2], selected?.value, selectedBibleData[4]])
                                }}
                            />
                        </div>
                        <p>-</p>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Verse</label>
                            <Select
                                options={passageData?.map(verse => ({ label: verse.verse, value: verse.verse }))}
                                value={passageData?.map(verse => ({ label: verse.verse, value: verse.verse }))?.find(verse => verse.value == selectedBibleData[4])}
                                onChange={selected => {
                                    if (selectedBibleData[3] > selected?.value) {
                                        notify({ message: "Invalid verse range", type: "error" })
                                        return
                                    }
                                    setSelectedBibleData([selectedBibleData[0], selectedBibleData[1], selectedBibleData[2], selectedBibleData[3], selected?.value])
                                }}
                            />
                        </div>
                    </div>
                    <div
                        style={{

                        }}
                    >
                        {previewData?.length > 0 && previewData?.map((verse, index) => {
                            return (
                                <p
                                    style={{
                                        textAlign: "justify",
                                        padding: 10,
                                        fontSize: 16,
                                        whiteSpace: "pre-wrap",
                                    }}
                                ><sup style={{ fontSize: 12 }}>{verse.verse}</sup> {verse.text}</p>
                            )
                        })}
                    </div>
                </>
            }
        </>
    )
}

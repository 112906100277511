import { useState, useEffect } from "react";
import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import Accordion from "../components/Accordion";
import config from "../app-config";
import { Helmet } from "react-helmet";

const MissionsOpportunities = () => {
  const [missionOpp, setMissionOpp] = useState([]);

  useEffect(() => {
    const fetchMissionsOpportunities = async () => {
      const res = await fetch(`${config.endpoint}/missions`);
      const data = await res.json();
      const missionOppData = data.data;
      setMissionOpp(missionOppData);
    };
    fetchMissionsOpportunities();
  });

  return (
    <MainLayout>
      <main
        style={{
          background: "#ffffff",
          padding: 20,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          gap: 30,
        }}
      >
        <Helmet>
          <title>Missions Opportunities - Bedok Methodist Church</title>
        </Helmet>
        <section>
          <div
            style={{
              textDecoration: "none",
              display: "inline-block",
              border: "1px solid #0099ab",

              color: "#ffffff",
              backgroundColor: "#0099ab",
              paddingBlock: 10,
              paddingInline: 20,

              borderRadius: 10,

              fontWeight: "bold",

              boxShadow: "0 3px 10px 1px grey",
              marginBottom: 30,
            }}
          >
            LOCAL OUTREACH (AGENCIES)
          </div>
          <div>
            {missionOpp.map((mission) => (
              <Accordion
                question={mission.country_id}
                answer={
                  <>
                    <tr>
                      <th>Country</th>
                      <th>Mission Opportunity</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Ages</th>
                      <th>Contact</th>
                    </tr>
                    <tr>
                      <td style={{ padding: 10 }}>Singapore</td>
                      <td style={{ padding: 10 }}>
                        OM Ministry to Migant Construction Workers
                      </td>
                      <td style={{ padding: 10 }}>Weekly, Thu 7.30pm – 10pm</td>
                      <td style={{ padding: 10 }}>
                        Befriend UPGs in Singapore – migrant construction
                        workers from South Asia
                      </td>
                      <td style={{ padding: 10 }}>18 and above</td>
                      <td style={{ padding: 10 }}>
                        {" "}
                        <a
                          style={{ textDecoration: "none", color: "#334862" }}
                          href="mailto:crossroads.SG@om.org"
                        >
                          crossroads.SG@om.org
                        </a>
                      </td>
                    </tr>
                  </>
                }
              />
            ))}
          </div>
        </section>
        <section>
          <div
            style={{
              textDecoration: "none",
              display: "inline-block",
              border: "1px solid #0099ab",

              color: "#ffffff",
              backgroundColor: "#0099ab",
              paddingBlock: 10,
              paddingInline: 20,

              borderRadius: 10,

              fontWeight: "bold",

              boxShadow: "0 3px 10px 1px grey",
              marginBottom: 30,
            }}
          >
            GLOBAL SHORT-TERM TRIPS (AGENCIES)
          </div>
          <div>
            {missionOpp.map((mission) => (
              <Accordion
                question={mission.country_id}
                answer={
                  <>
                    <tr>
                      <th>Country</th>
                      <th>Mission Opportunity</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Ages</th>
                      <th>Contact</th>
                    </tr>
                    <tr>
                      <td style={{ padding: 10 }}>Singapore</td>
                      <td style={{ padding: 10 }}>
                        OM Ministry to Migant Construction Workers
                      </td>
                      <td style={{ padding: 10 }}>Weekly, Thu 7.30pm – 10pm</td>
                      <td style={{ padding: 10 }}>
                        Befriend UPGs in Singapore – migrant construction
                        workers from South Asia
                      </td>
                      <td style={{ padding: 10 }}>18 and above</td>
                      <td style={{ padding: 10 }}>
                        {" "}
                        <a
                          style={{ textDecoration: "none", color: "#334862" }}
                          href="mailto:crossroads.SG@om.org"
                        >
                          crossroads.SG@om.org
                        </a>
                      </td>
                    </tr>
                  </>
                }
              />
            ))}
          </div>
        </section>
        <section>
          <div
            style={{
              textDecoration: "none",
              display: "inline-block",
              border: "1px solid #0099ab",

              color: "#ffffff",
              backgroundColor: "#0099ab",
              paddingBlock: 10,
              paddingInline: 20,

              borderRadius: 10,

              fontWeight: "bold",

              boxShadow: "0 3px 10px 1px grey",
              marginBottom: 30,
            }}
          >
            MISSIONS EXPOSURE AND TRAINING (AGENCIES)
          </div>
          <div>
            {missionOpp.map((mission) => (
              <Accordion
                question={mission.country_id}
                answer={
                  <>
                    <tr>
                      <th>Country</th>
                      <th>Mission Opportunity</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Ages</th>
                      <th>Contact</th>
                    </tr>
                    <tr>
                      <td style={{ padding: 10 }}>Singapore</td>
                      <td style={{ padding: 10 }}>
                        OM Ministry to Migant Construction Workers
                      </td>
                      <td style={{ padding: 10 }}>Weekly, Thu 7.30pm – 10pm</td>
                      <td style={{ padding: 10 }}>
                        Befriend UPGs in Singapore – migrant construction
                        workers from South Asia
                      </td>
                      <td style={{ padding: 10 }}>18 and above</td>
                      <td style={{ padding: 10 }}>
                        <a
                          style={{ textDecoration: "none", color: "#334862" }}
                          href="mailto:crossroads.SG@om.org"
                        >
                          crossroads.SG@om.org
                        </a>
                      </td>
                    </tr>
                  </>
                }
              />
            ))}
          </div>
        </section>
        <p style={{ color: "#777777" }}>
          For further enquiries, email{" "}
          <a
            style={{ textDecoration: "none", color: "#334862" }}
            href="mailto:missions@bmc.org.sg"
          >
            missions@bmc.org.sg
          </a>
        </p>
      </main>
      <SubFooter />
    </MainLayout>
  );
};

export default MissionsOpportunities;

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd } from 'react-icons/md';
import { tidyDate, tidyDateRaw, tidyFrequencyName } from '../../utils/helper';

export default function PrayerPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [prayerListData, setPrayerListData] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchPrayerList()
  }, [])

  const fetchPrayerList = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/prayer/list"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setPrayerListData(data.data)
    }
    setLoading(false)
  }


  return (
    <AdminLayout
      navSelected={"Corporate Prayer"}
      toolbar={
        <div className='card row alignCenter justifySplit w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Corporate Prayer</h1>
          <button
            className="button row alignCenter gap-10"
            onClick={() => {
              navigate("/admin/prayer/details?prayer_list_id=new")
            }}
          >
            <MdAdd size={15} />
            Prayer
          </button>
        </div>
      }
    >
       <div className="column alignCenter justifySplit w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>

      {loading ? (
        <div className="row alignCenter justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {prayerListData.map((prayer, index) => {
            if (!prayer.title) return 
            return (
              <div
                key={index}
                className="row alignCenter justifySplit w-100 card mt-10"
              >
                <div className="">

                  <h3>{prayer.title}</h3>
                  <h3 className='card-subtitle'>{prayer.description}</h3>
                </div>
                <button
                  className="button"
                  onClick={() => {
                    navigate("/admin/prayer/details?prayer_list_id=" + prayer.id)
                  }}
                >
                  View
                </button>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}

import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import { useState, useEffect } from "react";
import config from "../app-config";
import { Helmet } from "react-helmet";

const New = () => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const submitForm = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      const response = await fetch(`${config.endpoint}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      setFormData({
        newName: "",
        newEmail: "",
        newMessage: "",
        newPhone: "",
        newInvitationBy: "",
        newHearFrom: "",
      });

      return response.json();
    } catch (err) {
      console.error("Error submitting form:", err);
    }
  };
  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>New - Bedok Methodist Church</title>
        </Helmet>
        <div
          style={{
            position: "relative",
            background: "url('Church.jpeg')",
            minHeight: 400,
            backgroundSize: "cover",
            backgroundColor: "#777777",
            backgroundBlendMode: "multiply",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <h1
            className="new-cta"
            style={{
              fontWeight: 600,
              color: "#ffffff",
              margin: "auto",
              position: "absolute",
              padding: 20,
            }}
          >
            WELCOME!
            <br />
            欢迎！
          </h1>
        </div>
        <section
          className="new-user-form"
          style={{
            marginTop: 30,
            backgroundColor: "#ffffff",
            padding: 20,
            borderRadius: 10,
          }}
        >
          <div style={{ fontWeight: "bold", color: "#555555", flex: 0.6 }}>
            <h2>
              Welcome to Bedok Methodist Church! Please fill in this form so
              that we can connect with you.
            </h2>
            <br />
            <h2>
              欢迎您来到卫理公会勿洛堂！请填写此表格，以便我们能与您联系。
            </h2>
          </div>

          <form
            onSubmit={submitForm}
            className="user-form-section"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              flex: 1,
            }}
          >
            <div>
              <label htmlFor="name" aria-label="Name" hidden>
                Name:{" "}
              </label>
              <input
                onChange={handleChange}
                value={formData.newName}
                id="name"
                name="newName"
                placeholder="Name 姓名"
                required
                style={{
                  width: "100%",
                  height: 40,
                  padding: 10,
                  borderRadius: 999,
                  outline: "none",
                  border: "1px solid rgba(0, 0, 0, .09)",
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                }}
              />
            </div>

            <div>
              <label htmlFor="email" aria-label="Email" hidden>
                Email:{" "}
              </label>
              <input
                type="email"
                value={formData.newEmail}
                onChange={handleChange}
                id="email"
                name="newEmail"
                placeholder="Email 电子邮件"
                required
                style={{
                  width: "100%",
                  height: 40,
                  padding: 10,
                  borderRadius: 999,
                  outline: "none",
                  border: "1px solid rgba(0, 0, 0, .09)",
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                }}
              />
            </div>
            <div>
              <label htmlFor="phone" aria-label="phone" hidden>
                Phone:{" "}
              </label>
              <input
                type="tel"
                id="phone"
                onChange={handleChange}
                value={formData.newPhone}
                name="newPhone"
                placeholder="Contact Number 联系电话"
                required
                style={{
                  width: "100%",
                  height: 40,
                  padding: 10,
                  borderRadius: 999,
                  outline: "none",
                  border: "1px solid rgba(0, 0, 0, .09)",
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                }}
              />
            </div>
            <div>
              <label htmlFor="invitationBy" aria-label="invitationBy" hidden>
                invitationBy:{" "}
              </label>
              <input
                onChange={handleChange}
                id="invitationBy"
                name="newInvitationBy"
                value={formData.newInvitationBy}
                placeholder="Name of family/friend who invited you 邀请您来的家人/朋友的姓名"
                required
                style={{
                  width: "100%",
                  height: 40,
                  padding: 10,
                  borderRadius: 999,
                  outline: "none",
                  border: "1px solid rgba(0, 0, 0, .09)",
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                }}
              />
            </div>
            <div>
              <label htmlFor="new-hearFrom" aria-label="new-hearFrom" hidden>
                hearFrom:{" "}
              </label>
              <input
                onChange={handleChange}
                id="hearFrom"
                name="newHearFrom"
                value={formData.newHearFrom}
                placeholder="How did you hear about BMC? 您是如何认识到 BMC？"
                required
                style={{
                  width: "100%",
                  height: 40,
                  padding: 10,
                  borderRadius: 999,
                  outline: "none",
                  border: "1px solid rgba(0, 0, 0, .09)",
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                }}
              />
            </div>
            <div>
              <label htmlFor="new-message" aria-label="new-message" hidden>
                Message:{" "}
              </label>
              <textarea
                onChange={handleChange}
                value={formData.newMessage}
                required
                name="newMessage"
                placeholder="Leave us a message 请给我们留言"
                style={{
                  resize: "none",
                  width: "100%",
                  borderRadius: 10,
                  outline: "none",
                  border: "1px solid rgba(0, 0, 0, .09)",
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                  padding: 10,
                }}
              ></textarea>
            </div>
            <span>
              <span>
                <span>
                  <input
                    name="newConsent"
                    onChange={handleChange}
                    type="checkbox"
                    value="By filling in this form, I consent to Bedok Methodist Church using or disclosing my data and contacting me for the purposes I have indicated above. I am aware that I may update the personal data and/or withdraw the consent provided by me at any time by sending a request to office@bmc.org.sg 填写此表格，即表示我同意卫理公会勿洛堂使用或揭露我的个人资料并与我联系。 我确认我可以随时向 office@bmc.org.sg 发送请求来更新个人数据和/或撤回我所提供的资料。"
                  />
                  <span style={{ marginLeft: 4, color: "#555555" }}>
                    By filling in this form, I consent to Bedok Methodist Church
                    using or disclosing my data and contacting me for the
                    purposes I have indicated above. I am aware that I may
                    update the personal data and/or withdraw the consent
                    provided by me at any time by sending a request to
                    office@bmc.org.sg
                    填写此表格，即表示我同意卫理公会勿洛堂使用或揭露我的个人资料并与我联系。
                    我确认我可以随时向 office@bmc.org.sg
                    发送请求来更新个人数据和/或撤回我所提供的资料。
                  </span>
                </span>
              </span>
            </span>
            <button
              type="submit"
              className="white b-600 services-button"
              style={{
                textDecoration: "none",
                display: "inline-block",
                borderRadius: 99,
                paddingBlock: 10,
                paddingInline: 20,
                border: "1px solid currentColor",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </form>
        </section>
      </main>
      <SubFooter />
    </MainLayout>
  );
};

export default New;

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <footer
      style={{
        backgroundColor: "#0094b3",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#FFFFFF80",
      }}
    >
      <ul
        className="mb-20 mt-20"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          listStyle: "none",
          gap: 10,
        }}
      >
        <Link
          to="/"
          className="footer"
          style={{ textDecoration: "none", color: "#FFFFFF80" }}
        >
          <li>HOME</li>
        </Link>
        <Link
          to="/about"
          className="footer"
          style={{ textDecoration: "none", color: "#FFFFFF80" }}
        >
          <li>ABOUT US</li>
        </Link>

        <Link
          to="/announcements"
          className="footer"
          style={{ textDecoration: "none", color: "#FFFFFF80" }}
        >
          <li>ANNOUNCEMENTS</li>
        </Link>
        <Link
          to="/getting-here"
          className="footer"
          style={{ textDecoration: "none", color: "#FFFFFF80" }}
        >
          <li>GETTING TO BMC</li>
        </Link>
        <Link
          to="/contact"
          className="footer"
          style={{ textDecoration: "none", color: "#FFFFFF80" }}
        >
          <li>CONTACT US</li>
        </Link>
        <Link
          to="/whistle-blowing-policy"
          className="footer"
          style={{ textDecoration: "none", color: "#FFFFFF80" }}
        >
          <li>WHISTLE-BLOWING POLICY</li>
        </Link>
        <Link
          to="/site-map"
          className="footer"
          style={{ textDecoration: "none", color: "#FFFFFF80" }}
        >
          <li>SITE-MAP</li>
        </Link>
      </ul>
      <small>
        Copyright {date} © <em className="b-600">Bedok Methodist Church</em>
      </small>
    </footer>
  );
};

export default Footer;

const pages = [
  {
    page_name: "Home",
    url: "/",
  },
  {
    page_name: "Announcements",
    url: "/announcements",
  },
  {
    page_name: "Tithes & Offerings",
    url: "/tithes",
  },
  {
    page_name: "Prayer Requests",
    url: "/prayer-requests",
  },
  {
    page_name: "New Member Sign-Up",
    url: "/new",
  },
  {
    page_name: "Our Online Platforms",
    url: "/whatsapp",
  },
  {
    page_name: "Who We Are",
    url: "/about",
  },
  {
    page_name: "Our Pastors & Staff",
    url: "/our-pastors-staff",
  },
  {
    page_name: "All Ministries",
    url: "/ministries",
  },
  {
    page_name: "Mission Release",
    url: "/missions-release",
  },
  {
    page_name: "Mission Opportunities",
    url: "/missions-opportunities",
  },
  {
    page_name: "Getting to BMC",
    url: "/getting-here",
  },
  {
    page_name: "Online Services",
    url: "/online-services",
  },
  {
    page_name: "Sermon Series",
    url: "/sermon-series",
  },
  {
    page_name: "Sermon Series (Gospel of Matthew)",
    url: "/sermon-series/gospel-of-matthew",
  },
  {
    page_name: "Contact Us",
    url: "/contact",
  },
  {
    page_name: "BMC+ Mobile App",
    url: "/bmcplus",
  },
];

export default pages;

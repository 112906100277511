import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd, MdCopyAll, MdMenuOpen, MdViewModule } from 'react-icons/md';
import { tidyDate, tidyDateRaw } from '../../utils/helper'
import { FaEye, FaOpenid } from 'react-icons/fa';
import notify from '../../utils/IANotification';

export default function ServicesPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")
  const [serviceFilter, setServiceFilter] = useState([])
  const [statusFilter, setStatusFilter] = useState(["all", "past", "upcoming"])

  const [selectedService, setSelectedService] = useState("all")
  const [selectedStatus, setSelectedStatus] = useState("all")
  const [selectedDate, setSelectedDate] = useState(new Date())


  useEffect(() => {
    fetchServices()
  }, [])

  const fetchServices = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/services/admin"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      let service_data = data.data
      service_data.sort((a, b) => {
        return new Date(b.service_start_datetime) - new Date(a.service_start_datetime)
      })
      setServices(service_data)

      let temp = []
      for (let i = 0; i < service_data.length; i++) {
        let service_type = service_data[i].service_type.name
        if (!temp.includes(service_type)) {
          temp.push(service_type)
        }
      }
      setServiceFilter(temp)
    }
    setLoading(false)
  }


  const duplicateService = async (service_id) => {
    let endpoint = config.endpoint + "/services/duplicate"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        service_id: service_id,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchServices()
      notify({ message: "Service duplicated", type: "success" })
    } else {
      notify({ message: "Failed to duplicate service", type: "error" })
    }
  }



  return (
    <AdminLayout
      navSelected={"Services"}
      toolbar={
        <div className='card row alignCenter justifySplit w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Services</h1>
          <button
            className="button row alignCenter gap-10"
            onClick={() => {
              navigate("/admin/services/details?service_id=new")
            }}
          >
            <MdAdd size={15} />
            Service
          </button>
        </div>
      }
    >
      <div className="column alignCenter justifySplit w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
        <div className="row gap-10 alignEnd w-100 mt-10">
          <select
            className="select-input-chip"
            value={selectedService}
            onChange={(e) => {
              setSelectedService(e.target.value)
            }}
          >
            <option value="all">All Service Types</option>
            {serviceFilter.map((service, index) => {
              return (
                <option key={index} value={service}>
                  {service}
                </option>
              )
            })}
          </select>

          <select
            className="select-input-chip"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value)
            }}
          >
            <option value="all">All Service</option>
            <option value="past">Past</option>
            <option value="upcoming">Upcoming</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="row alignCenter justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {services.map((service, index) => {
            if (!JSON.stringify(service)?.toLowerCase().includes(query.toLowerCase())) return null
            if (selectedService !== "all" && service?.service_type?.name !== selectedService) return null
            if (selectedStatus === "past" && new Date(service?.service_end_datetime) > new Date()) return null
            if (selectedStatus === "upcoming" && new Date(service?.service_end_datetime) < new Date()) return null

            return (
              <div
                key={index}
                className="row alignCenter justifySplit w-100 card mt-10"
              >
                <div className="">
                  <p className='small'>{tidyDateRaw(service?.service_start_datetime)?.day} {tidyDateRaw(service?.service_start_datetime)?.month} {tidyDateRaw(service?.service_start_datetime)?.year}</p>
                  <h3>{service.service_title}</h3>
                  <h3 className='card-subtitle'>{tidyDateRaw(service?.service_start_datetime)?.hour}:{tidyDateRaw(service?.service_start_datetime)?.minute} - {tidyDateRaw(service?.service_end_datetime)?.hour}:{tidyDateRaw(service?.service_end_datetime)?.minute} • {service?.service_type_ids?.length || 0} Services • {service?.sermon_title}</h3>
                </div>
                <div className="row gap-30 alignCenter">
                  <button
                    className="backgroundLess row gap-10 alignCenter"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      duplicateService(service.id)
                    }}
                  >
                    {/* <MdCopyAll size={15} /> */}
                    Duplicate
                  </button>
                  <button
                    className="backgroundLess row gap-10 alignCenter"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      navigate("/admin/services/details?service_id=" + service.id)
                    }}
                  >
                    {/* <FaEye size={15} /> */}
                    View
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import Select from 'react-select'
import notify from '../../utils/IANotification';
import FileUploadField from '../../components/FileUploadField';
import { capitaliseFirstLetter } from '../../utils/helper';

export default function MinistryFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let ministry_id = new URLSearchParams(window.location.search).get("ministry_id")

  const [ministryFormData, setMinistryFormData] = useState({
    name: "",
    description: "",
    poc_user_id: "",
    tagline: "",
    type: "connect", // connect, equip, release
  })

  const ministry_type = ["connect", "equip", "release"]
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchPlatformUsers = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/users/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setUsers(data.data)
      console.log(data.data)
    }
    setLoading(false)
  }

  const fetchMinistryById = async () => {
    if (!ministry_id || ministry_id === "new") return
    let endpoint = config.endpoint + "/ministry/id/" + ministry_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setMinistryFormData(data.data)
    }
  }

  const saveMinistry = async () => {
    let endpoint = config.endpoint + "/ministry/"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(ministryFormData),
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/admin/ministry/details?ministry_id=" + data.data.id)
      notify({ type: "success", message: "Ministry created" })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateMinistry = async () => {
    let endpoint = config.endpoint + "/ministry/id/" + ministry_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(ministryFormData),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Ministry updated" })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteMinistry = async () => {
    let endpoint = config.endpoint + "/ministry/id/" + ministry_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Ministry deleted" })
      navigate(-1)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchMinistryById()
    fetchPlatformUsers()
  }, [])
  return (
    <AdminLayout
      navSelected={"Ministry"}
    >
      <div className="card">
        <p className="card-title">Details</p>

        {ministry_id !== "new"&&
          <FileUploadField
            upload_url={config.endpoint + "/file/ministry/id/" + ministry_id}
            max_files={1}
            text={"Upload Ministry Image"}
            subtext={"Upload an image for the ministry cover page"}
            onChange={(files) => {
            }}
          />
        }
        <div className="row gap-10 alignStart">
          <div className="input-container">
            <label htmlFor="" className="input-label">Name</label>
            <input
              type="text"
              placeholder='e.g. Worship Team'
              className="text-input"
              value={ministryFormData.name}
              onChange={(e) => {

                setMinistryFormData({ ...ministryFormData, name: e.target.value })
              }}
            />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Point of Contact</label>
            <Select
              options={users.map((user) => {
                return {
                  value: user.id,
                  label: user.first_name + " " + user.last_name,
                }
              })}
              value={users.map((user) => {
                return {
                  value: user.id,
                  label: user.first_name + " " + user.last_name,
                }
              }).filter((user) => user.value == ministryFormData.poc_user_id)?.[0]}
              onChange={(e) => {
                setMinistryFormData({ ...ministryFormData, poc_user_id: e.value })
              }}
            />
            <p className="small">*POC contact information like phone number and email will be made public</p>
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Type</label>
            <Select
              options={ministry_type.map((type) => {
                return {
                  value: type,
                  label: capitaliseFirstLetter(type),
                }
              })}
              value={ministry_type.map((type) => {
                return {
                  value: type,
                  label: capitaliseFirstLetter(type),
                }
              }).filter((type) => type.value == ministryFormData.type)?.[0]}
              onChange={(e) => {
                setMinistryFormData({ ...ministryFormData, type: e.value })
              }}
            />
          </div>
        </div>

        <div className="input-container">
          <label htmlFor="" className="input-label">Tagline</label>
          <input
            type="text"
            placeholder='e.g. "Worship in the house of the Lord"'
            className="text-input"
            value={ministryFormData.tagline}
            onChange={(e) => {
              setMinistryFormData({ ...ministryFormData, tagline: e.target.value })
            }}
          />
        </div>

        <div className="input-container">
          <label htmlFor="" className="input-label">Description</label>
          <textarea
            placeholder='What is the ministry about? What are the commitments like? Is there any history about this ministry?'
            className="text-input"
            value={ministryFormData.description}
            style={{ minHeight: 200, resize: "none" }}
            onChange={(e) => {
              setMinistryFormData({ ...ministryFormData, description: e.target.value })
            }}
          />
        </div>

        <div className="column alignEnd">
          <div className="row gap-10 alignCenter">
            {ministry_id !== "new" && <button className="button-delete"
              onClick={() => {
                if (ministry_id !== "new") {
                  deleteMinistry()
                }
              }}
            >Delete</button>}
            <button className="button"
              onClick={() => {
                if (ministry_id === "new") {
                  saveMinistry()
                } else {
                  updateMinistry()
                }
              }}
            >Save</button>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

import React, { useState, useRef, useEffect } from 'react'
// import Globe from 'react-globe.gl';
// import ReactMapboxGl from "react-mapbox-gl";
import Globe from 'react-globe.gl';
import config from '../../app-config';


export default function MissionsGlobePage() {
    const globeEl = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false)
    // const myData = [
    //     {
    //         lat: 51.5074,
    //         lng: 0.1278,
    //         name: "London",
    //         value: 1
    //     },
    //     {
    //         lat: 48.8566,
    //         lng: 2.3522,
    //         name: "Paris",
    //         value: 1
    //     },
    //     {
    //         lat: 40.7128,
    //         lng: -74.0060,
    //         name: "New York",
    //         value: 1
    //     },
    //     {
    //         lat: 1.3521,
    //         lng: 103.8198,
    //         name: "Singapore",
    //     }
    // ];

    const eventListener = (e) => {
        window.ReactNativeWebView.postMessage(JSON.stringify(e));
    }

    useEffect(() => {
        if (!globeEl.current) return;
        globeEl.current.pointOfView({ lat: 1.3521, lng: 103.8198, altitude: 2 });
        globeEl.current.controls().autoRotate = true;
    }, [isLoaded]);

    // Disable rotation when inactive
    window.addEventListener('blur', () => {
        if (!globeEl.current) return;
        globeEl.current.controls().autoRotate = false
    });

    // Enable rotation when active
    window.addEventListener('focus', () => {
        if (!globeEl.current) return;
        globeEl.current.controls().autoRotate = true
    });


    const [allMissions, setAllMissions] = useState([])
    const fetchAllMission = async () => {
        setIsLoaded(false)
        let endpoint = `${config.endpoint_1}/missions/`
        let response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        })
        let data = await response.json()
        if (data.status === 200) {
            let temp = []
            let country_code = []


            for (let i = 0; i < data.data.length; i++) {
                let mission = data.data[i]
                let country = mission.country_data
                let prayer = mission.prayer
                let prayer_list = mission.prayer_list_data
                if (country_code.includes(mission?.country_id)) continue
                country_code.push(mission?.country_id)
                let tempMission = {
                    // ...data.data,
                    lat: country.latlng[0],
                    lng: country.latlng[1],
                    name: country.name,
                    value: 1,
                    mission: mission,
                    prayer: prayer,
                    prayer_list: prayer_list,
                    country_id: mission?.country_id
                }

                temp.push(tempMission)
            }

            setIsLoaded(true)
            setAllMissions(temp)
        }
    }

    useEffect(() => {
        fetchAllMission()
    }, [])



    return (
        <div style={{ backgroundColor: "gray" }}>
            {isLoaded && <Globe
                ref={globeEl}
                // pointsData={myData}
                globeImageUrl={"/res/earth-day.jpg"}
                globe
                // backgroundColor=''
                // ringMaxRadius={0.5}
                htmlElementsData={allMissions}
                htmlElement={d => {
                    let div = document.createElement('div');
                    // div.style.background = 'white';
                    div.style.color = 'white';
                    div.innerHTML = d.name;
                    div.style['pointer-events'] = 'auto';
                    div.style.cursor = 'pointer';
                    div.onclick = () => eventListener(d);
                    return div;
                }}
                animateIn={true}
                showAtmosphere={true}
                // constant rotation
                // pointOfView={{ lat: 1.3521, lng: 0.1278, altitude: 2 }}

                onObjectClick={(obj) => {
                    return console.log(obj)
                }}

            />}
        </div>
    )
}

import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import Accordion from "../components/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const MissionRelease = () => {
  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Missions Release - Bedok Methodist Church</title>
        </Helmet>
        <div
          className="mb-30"
          style={{
            position: "relative",
            backgroundColor: "grey",
          }}
        >
          <iframe
            id="ytplayer-636"
            style={{
              width: "100%",
              aspectRatio: "16/9",

              mixBlendMode: "multiply",
            }}
            frameborder="0"
            autoplay="1"
            referrerpolicy="strict-origin-when-cross-origin"
            title="Sangkhlaburi Missions Field (w/o Interview)"
            src="https://www.youtube.com/embed/V3X4mvgJLZI?html5=1&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;playlist=V3X4mvgJLZI"
          ></iframe>
          <div
            style={{
              position: "absolute",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <h1
              className="mission-release-title"
              style={{
                color: "#ffffff",
                textAlign: "center",
                fontWeight: "bold",
                textShadow: "0 2px 10px grey",
              }}
            >
              MISSION RELEASE 2024
            </h1>
          </div>
        </div>
        <section
          style={{ backgroundColor: "white", padding: 20, borderRadius: 10 }}
        >
          <div
            style={{
              width: 50,
              height: "4px",
              backgroundColor: "#0000001A",
              margin: "auto",
              marginBottom: 10,
            }}
          ></div>
          <h2
            style={{
              color: "#777777",
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            Pastor's Message
          </h2>
          <div
            style={{
              color: "#777777",
              display: "flex",
              flexDirection: "column",
              gap: 20,
              lineHeight: 1.5,
            }}
          >
            <p>
              Brothers and Sisters, we are children of God, blessed with His
              presence and abundant life, because someone, by faith, shared the
              gospel with us.
            </p>
            <p>
              Ultimately, Singapore received the gospel because missionaries
              from afar, by faith, endured hardships to bring us the good news.
              It is our privilege to pass on this blessing today.
            </p>
            <p>
              Therefore, let us pray earnestly and by faith, go to the earth’s
              desolate places, bringing God’s help and hope to people displaced
              by war and suffering.
            </p>
            <p>
              By faith, go to the mega-cities, bringing God’s love and peace to
              those harassed by the ravages of this world.
            </p>
            <p>
              By faith, go across the HDB corridor or your workplace, living out
              Jesus’ love to a neighbour or colleague who has come from a
              distant land.
            </p>
            <p>Disciple of Christ, by faith, go forth and shine!</p>
            <p>
              Rev Dr Aaron Tay
              <br />
              Pastor-in-charge
              <br />
              Bedok Methodist Church
            </p>
          </div>
          <div
            style={{
              width: 50,
              height: "4px",
              backgroundColor: "#0000001A",
              margin: "auto",
              marginBottom: 10,
              marginTop: 30,
            }}
          ></div>
          <h2
            style={{
              color: "#777777",
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            Take First Steps
          </h2>
          <div
            style={{
              color: "#777777",
              display: "flex",
              flexDirection: "column",
              gap: 5,
              lineHeight: 1.5,
            }}
          >
            <p>
              <span style={{ color: "#0099ab", fontWeight: "bold" }}>
                Step 1:{" "}
              </span>
              Pray and ask God where and what He is leading you to.
            </p>
            <p>
              <span style={{ color: "#0099ab", fontWeight: "bold" }}>
                Step 2:{" "}
              </span>
              Explore cross-cultural opportunities here. Contact the mission
              agency to indicate your interest in the mission field/programme.
            </p>
            <p>
              <span style={{ color: "#0099ab", fontWeight: "bold" }}>
                Step 3:{" "}
              </span>
              Register your mission trip with BMC here.
            </p>
            <p>
              <span style={{ color: "#0099ab", fontWeight: "bold" }}>
                Step 4:{" "}
              </span>
              Be trained. Participate in: (1) BMC’s Missions Summit in June 2024
              here; (2) Pre-field orientation offered by the mission agency you
              signed up with; (3) Sign up for missions mentoring here.
            </p>
            <p>
              <span style={{ color: "#0099ab", fontWeight: "bold" }}>
                Step 5:{" "}
              </span>
              Attend the mission trip, serving the community God sends you to.
            </p>
            <p>
              <span style={{ color: "#0099ab", fontWeight: "bold" }}>
                Step 6:{" "}
              </span>
              Journal your missions experience and collect photos.
            </p>
            <p>
              <span style={{ color: "#0099ab", fontWeight: "bold" }}>
                Step 7:{" "}
              </span>
              Pray for next steps in your journey, preferably to visit and
              invest in the same community again.
            </p>
          </div>
          <div
            className="mission-release-cta"
            style={{ textDecoration: "none", marginTop: 20 }}
          >
            <Link
              style={{
                textDecoration: "none",
                display: "block",
                border: "1px solid #0099ab",
                color: "#ffffff",
                backgroundColor: "#0099ab",
                paddingBlock: 10,
                paddingInline: 20,
                borderRadius: 10,
                fontWeight: "bold",
                boxShadow: "0 3px 10px 1px grey",
                flex: 1,
              }}
              to="/missions-opportunities"
            >
              EXPLORE OPPORTUNITIES
            </Link>
            <Link
              style={{
                textDecoration: "none",
                display: "block",
                border: "1px solid #0099ab",
                color: "#ffffff",
                backgroundColor: "#0099ab",
                paddingBlock: 10,
                paddingInline: 20,
                borderRadius: 10,
                fontWeight: "bold",
                boxShadow: "0 3px 10px 1px grey",
                flex: 1,
              }}
              to="/mission-release"
            >
              BE EQUIPPED
            </Link>
            <Link
              target="_blank"
              style={{
                textDecoration: "none",
                display: "block",
                border: "1px solid #0099ab",

                color: "#ffffff",
                backgroundColor: "#0099ab",
                paddingBlock: 10,
                paddingInline: 20,

                borderRadius: 10,

                fontWeight: "bold",

                boxShadow: "0 3px 10px 1px grey",
                flex: 1,
              }}
              to="https://forms.office.com/pages/responsepage.aspx?id=7y9JJpWLwkm2wIl8AP_vSRweiYTgDm5BkHo9UzrlnA5UMU1DWjdaUE9ISVAxRTNEVjBQRjRSTEk0Si4u"
            >
              MISSIONS MENTORING
            </Link>
            <Link
              target="_blank"
              style={{
                textDecoration: "none",
                display: "block",
                border: "1px solid #0099ab",

                color: "#ffffff",
                backgroundColor: "#0099ab",
                paddingBlock: 10,
                paddingInline: 20,

                borderRadius: 10,

                fontWeight: "bold",

                boxShadow: "0 3px 10px 1px grey",
                flex: 1,
              }}
              to="https://forms.office.com/pages/responsepage.aspx?id=7y9JJpWLwkm2wIl8AP_vSfLFjBm1nqJApWIyhc6RMw9UNlNaSzdEUjA2M0Y4NlBCTzNHNlBTT1E1Ry4u&origin=lprLink"
            >
              BMC MISSIONS REGISTRATION FORM
            </Link>
          </div>
          <section className="mt-30">
            <h2
              className="mb-20"
              style={{ color: "#777777", fontWeight: "bold" }}
            >
              Frequently Asked Questions
            </h2>
            <Accordion
              question="Why Missions?"
              answer="Jesus commissioned His people to carry the gospel to the ends of
              the earth. Two passages in Scripture expound on this: “Go
              therefore and make disciples of all nations, baptizing them in the
              name of the Father and of the Son and of the Holy Spirit, teaching
              them to observe all that I have commanded you. And behold, I am
              with you always, to the end of the age” (Matthew 28:18–20); “Jesus
              said to them again, “Peace be with you. As the Father has sent me,
              even so I am sending you” (John 20:21)."
            />
            <Accordion
              question="Where shall I begin?"
              answer="Seek the Lord and ask for discernment. Ask for compassion for a people group or nation. Explore opportunities to serve with BMC and missions organisations."
            />
          </section>
          <p className="mt-30" style={{ color: "#777777" }}>
            For further enquiries, email{" "}
            <a style={{ color: "#334862" }} href="mailto:missions@bmc.org.sg">
              missions@bmc.org.sg
            </a>
          </p>
        </section>

        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default MissionRelease;

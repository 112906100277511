import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import MinistryPhoto from "../components/MinistryPhoto";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import config from "../app-config";

const AllMinistries = () => {
  const endpoint = `${config.endpoint}/ministry`;
  const [ministries, setMinistries] = useState([]);
  const [filteredMinistries, setFilteredMinistries] = useState([]);
  const [isActiveType, setIsActiveType] = useState("ALL");

  useEffect(() => {
    const fetchAllMinistries = async () => {
      try {
        const res = await fetch(endpoint);
        const data = await res.json();
        if (data.status === 200) {
          setMinistries(data.data);
          setFilteredMinistries(data.data);
        } else {
          throw new Error("Failed to retrive ministries");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllMinistries();
  }, [endpoint]);

  const handleClick = (e) => {
    const type = e.target.innerHTML;
    setIsActiveType(type);

    if (type === "ALL") {
      setFilteredMinistries(ministries);
    } else {
      const filtered = ministries.filter((ministry) => ministry.type === type);
      setFilteredMinistries(filtered);
    }
  };

  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Ministries - Bedok Methodist Church</title>
        </Helmet>
        <div
          style={{
            position: "relative",
            background: "url('ministries.jpeg')",
            minHeight: 250,
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",

            backgroundColor: "#777777",
            backgroundBlendMode: "multiply",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1
            className="ministries-cta"
            style={{
              fontWeight: 600,
              color: "#ffffff",
              margin: "auto",
              textAlign: "center",
              position: "absolute",
              padding: 20,
            }}
          >
            MINISTRIES
          </h1>
        </div>
        <section
          className="mt-30"
          style={{ background: "#ffffff", padding: 20, borderRadius: 10 }}
        >
          <div
            className="ministries-nav"
            style={{ display: "flex", justifyContent: "center", gap: 20 }}
          >
            <h2
              onClick={handleClick}
              className={isActiveType === "ALL" ? "active" : ""}
            >
              ALL
            </h2>
            <h2
              onClick={handleClick}
              className={isActiveType === "CONNECT" ? "active" : ""}
            >
              CONNECT
            </h2>
            <h2
              onClick={handleClick}
              className={isActiveType === "EQUIP" ? "active" : ""}
            >
              EQUIP
            </h2>
            <h2
              onClick={handleClick}
              className={isActiveType === "RELEASE" ? "active" : ""}
            >
              RELEASE
            </h2>
          </div>
          <section className="ministries-photowall mt-30">
            {filteredMinistries.map((ministry, index) => (
              <MinistryPhoto
                key={index}
                name={ministry.name}
                type="CONNECT"
                img={ministry.ext_file_path}
              />
            ))}
          </section>
        </section>
      </main>
      <SubFooter />
    </MainLayout>
  );
};

export default AllMinistries;

import React from 'react'
import { MdAdd, MdClass, MdClose } from 'react-icons/md'

export default function CheckboxComponent({ data, onChange, state, type, readonly, field_options }) {
    return (
        <>
            {state === "answer" &&
                <div>
                    {field_options.map((option, index) => {
                        return (
                            <div className='row gap-10 alignCenter'
                                onClick={() => {
                                    let newData = [...data ||[]]
                                    if (data.includes(option)) {
                                        newData = newData.filter((item) => item !== option)
                                    } else {
                                        newData.push(option)
                                    }
                                    onChange(newData)
                                }}
                            >
                                <input type="checkbox" key={index} value={option} checked={data && data.includes(option)} readOnly={readonly} onChange={(e) => {
                                    let newData = [...data ||[]]
                                    if (e.target.checked) {
                                        newData.push(option)
                                    } else {
                                        newData = newData.filter((item) => item !== option)
                                    }
                                    onChange(newData)
                                }} />
                                <label className='input-label'>{option}</label>
                            </div>
                        )
                    })}
                </div>
            }
            {state === "builder" &&
                <>
                    <div>
                        <button className="backgroundLess row gap-10 alignCenter"
                            onClick={() => {
                                let newData = [...field_options]
                                newData.push("")
                                onChange(newData)
                            }}
                        >
                            <MdAdd size={20} />
                            Option
                        </button>
                    </div>

                    {field_options.map((option, index) => {
                        return (
                            <div key={index} className="mt-10 row gap-10 alignCenter">
                                <p style={{ width: 50 }}>{index + 1}</p>
                                <input type="text" className='text-input' value={option} onChange={(e) => {
                                    let newData = [...field_options]
                                    newData[index] = e.target.value
                                    onChange(newData)
                                }} />
                                <button className="backgroundLess row gap-10 alignCenter"
                                    onClick={() => {
                                        let newData = [...field_options]
                                        newData.splice(index, 1)
                                        onChange(newData)
                                    }}
                                >
                                    <MdClose size={20} />
                                </button>
                            </div>
                        )
                    })}
                </>
            }
        </>
    )
}

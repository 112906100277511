import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import { useState, useEffect } from "react";
import config from "../app-config";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Announcements = () => {
  const endpoint = `${config.endpoint}/announcements/ongoing`;
  const [annoucements, setAnnoucements] = useState([]);

  useEffect(() => {
    const fetchAllAnnoucements = async () => {
      try {
        const res = await fetch(endpoint);
        const data = await res.json();
        if (data.status === 200) {
          setAnnoucements(data.data);
        } else {
          throw new Error("Failed to retrive annoucements");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllAnnoucements();
  }, [endpoint]);
  return (
    <MainLayout>
      <main style={{ background: "#ffffff", padding: 20, borderRadius: 10 }}>
        <Helmet>
          <title>Announcements - Bedok Methodist Church</title>
        </Helmet>
        {annoucements.map((annoucement) => {
          const month = new Date(annoucement.start_datetime).toLocaleString(
            "default",
            { month: "long" }
          );
          const date = new Date(annoucement.start_datetime);
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  paddingInline: 10,
                  paddingBlock: 20,
                }}
              >
                <div style={{ textAlign: "center", flex: 0.1 }}>
                  <p
                    style={{
                      color: "#222222",
                      fontSize: "1.5rem",
                      fontWeight: 600,
                    }}
                  >
                    {date.getDate()}
                  </p>
                  <hr></hr>
                  <p style={{ color: "#333333" }}>
                    {month} {date.getFullYear()}
                  </p>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    marginLeft: 10,
                  }}
                >
                  <h2
                    style={{
                      color: "#555555",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                    }}
                  >
                    {annoucement.title}
                  </h2>
                  <p style={{ color: "#777777" }}>
                    The 2024 Primary One Registration Exercise for Singapore
                    Citizens and Permanent Residents will start from Tuesday, 2
                    July. Phase 2B is applicable for a child whose parent is a
                    member endorsed by the church directly connected with the
                    primary school. Phase 2B registration will open from Monday
                    22 July to Tuesday 23 July. Church members who wish to
                    register their children in our Methodist primary schools,
                    please email petrinayong@bmc.org.sg before 1 July.
                  </p>
                  {/* {annoucement.description} */}
                  <Link
                    className="read-more-btn"
                    to={`/news/${annoucement.title}`}
                    style={{
                      textDecoration: "none",
                      display: "block",
                      width: "fit-content",
                      border: "1px solid #0099ab",
                      color: "#ffffff",

                      paddingBlock: 10,
                      paddingInline: 20,
                      borderRadius: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Read More
                  </Link>
                </div>
              </div>
              <br />
              <hr />
            </div>
          );
        })}
      </main>
      <SubFooter />
    </MainLayout>
  );
};

export default Announcements;

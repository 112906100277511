import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import Switch from 'react-switch'
import Select from 'react-select'
import JoditEditor from 'jodit-react';
import notify from '../../utils/IANotification';
import ModalLayout from '../../layouts/ModalLayout';
import { MdAdd, MdDelete, MdPersonAdd } from 'react-icons/md';
import { FaPrayingHands } from 'react-icons/fa';

export default function MissionsFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const editor = useRef(null);
  const placeholder = "Start typing...";

  const liveConfig = {
    height: 500,
    placeholder,
    // set custom buttons
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'superscript',
      'subscript',
      'ul',
      'ol',
      'outdent',
      'indent',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'table',
      'link',
      'undo',
      'redo',
      'eraser',
      'copyformat',
    ],
    askBeforePasteHTML: false,
    hotkeys: {
      redo: 'ctrl+z',
      undo: 'ctrl+y,ctrl+shift+z',
      indent: 'ctrl+]',
      outdent: 'ctrl+[',
      bold: 'ctrl+b',
      italic: 'ctrl+i',
      removeFormat: 'ctrl+shift+m',
      insertOrderedList: 'ctrl+shift+7',
      insertUnorderedList: 'ctrl+shift+8',
      openSearchDialog: 'ctrl+f',
      openReplaceDialog: 'ctrl+r',
    },
  };

  let mission_id = new URLSearchParams(window.location.search).get("mission_id")
  const [missionData, setMissionsData] = useState({
    country_id: "",
    title: "",
    description: "",
    start_datetime: "",
    end_datetime: "",
  })
  const [prayerListData, setPrayerListData] = useState({
    type: "mission",
    title: "",
    description: "",
    is_archived: "0",
  })
  const [prayers, setPrayers] = useState([]) // {content: "", is_archived: "0", is_answered: "0", user_id: "", prayer_list_id: "", can_repeat: "0", prayers_count: [], testimony: ""}

  const [missionaryData, setMissionaryData] = useState([])
  const [countryData, setCountryData] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [showMemberModal, setShowMemberModal] = useState(false)
  const [tempUserData, setTempUserData] = useState({})
  let prayer_list_id = prayerListData?.id

  const fetchMissionById = async () => {
    if (mission_id === "new" || !mission_id) return
    let endpoint = config.endpoint + "/missions/id/" + mission_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setMissionsData(data.data?.mission_data)
      if (Object.keys(data.data?.prayer_list_data).length > 0) {
        setPrayerListData(data.data?.prayer_list_data)
      }
      setPrayers(data.data?.prayer)
    }
  }

  const fetchCountries = async () => {
    let endpoint = config.endpoint + "/country/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setCountryData(data.data)
    }
  }



  useEffect(() => {
    if (mission_id !== "new") {
      fetchMissionById()
    }
    fetchCountries()
    fetchMissionary()
    fetchPlatformUsers()
  }, [])


  const createMission = async () => {
    let endpoint = config.endpoint + "/missions/"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...missionData,
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/admin/missions/details?mission_id=" + data.data.id)
      fetchMissionById()
      notify({ type: "success", message: "Mission created successfully" })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateMission = async () => {
    if (mission_id === "new" || !mission_id) return
    let endpoint = config.endpoint + "/missions/id/" + mission_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...missionData,
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Mission updated successfully" })
      fetchMissionById()
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteMission = async () => {
    if (mission_id === "new" || !mission_id) return
    let endpoint = config.endpoint + "/missions/id/" + mission_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Mission deleted successfully" })
      navigate(-1)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const fetchMissionary = async () => {
    if (!mission_id || mission_id === "new") return
    let endpoint = config.endpoint + "/missions/members/" + mission_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setMissionaryData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const addMember = async (member_id, role) => {
    let endpoint = config.endpoint + "/missions/members/"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        mission_id: mission_id,
        user_id: member_id,
        role: role
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchMissionary()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const removeMember = async (member_id) => {
    if (!mission_id || mission_id === "new") return
    let endpoint = config.endpoint + "/missions/members/" + mission_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: member_id,
        mission_id: mission_id
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchMissionary()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const updateMember = async (member_id, role) => {
    if (!mission_id || mission_id === "new") return
    let endpoint = config.endpoint + "/missions/members/" + mission_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: member_id,
        mission_id: mission_id,
        role: role
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchMissionary()
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const fetchPlatformUsers = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/users/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setUsers(data.data)
      console.log(data.data)
    }
    setLoading(false)
  }

  const savePrayerList = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/prayer/list"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...prayerListData,
        prayers,
        mission_id  
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      // setPrayerListData(data.data)
      fetchMissionById()
      // navigate("/admin/prayer/details?prayer_list_id=" + data.data.id)
      notify({ type: "success", message: "Saved prayer list" })
    } else {
      notify({ type: "error", message: "Failed to save prayer list" })
    }
    setLoading(false)
  }

  const updatePrayerList = async () => {
    if (!prayerListData?.id) return
    setLoading(true)
    let endpoint = config.endpoint + "/prayer/list/id/" + prayer_list_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...prayerListData,
        prayers
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchMissionById()
      notify({ type: "success", message: "Updated prayer list" })
    } else {
      notify({ type: "error", message: "Failed to update prayer list" })
    }
    setLoading(false)
  }

  const ROLES = [
    { value: 0, label: "Missionary" },
    { value: 1, label: "Treasurer" },
    { value: 2, label: "Leader" },
  ]

  return (
    <AdminLayout
      navSelected={"Missions"}
      regularContainer={mission_id != "new"}
    >
      {/* Adding User Modal */}
      <ModalLayout isVisible={showMemberModal} setIsVisible={setShowMemberModal} modalTitle="Add User to mission">
        <div className="input-container">
          <label htmlFor="" className="input-label">User</label>
          <Select
            options={users.map(user => ({ label: user.first_name + " " + user.last_name, value: user.id }))}
            onChange={(e) => {
              setTempUserData(e)
            }}
          />
        </div>
        <div className="input-container">
          <label htmlFor="" className="input-label">Role</label>
          <Select
            options={ROLES}
            onChange={(e) => {
              setTempUserData({ ...tempUserData, role: e.value })
            }}
          />
        </div>
        <div className="row gap-10 alignEnd">
          <button className="button"
            onClick={() => {
              addMember(tempUserData.value, tempUserData.role)
              setShowMemberModal(false)
            }}
          >Add</button>
        </div>
      </ModalLayout>
      {/* <pre>{JSON.stringify(countryData,null,2)}</pre> */}
      {/* <pre>{JSON.stringify(prayers,null,2)}</pre> */}
      {/* <pre>{JSON.stringify(prayerListData, null, 2)}</pre> */}
      <div className="row gap-10 alignStart">
        <div className="w-100">
          <div className="card">
            <p className="card-title">Details</p>
            <div className="row gap-10 alignStart">
              <div className="input-container">
                <label htmlFor="" className="input-label">Name</label>
                <input
                  type="text"
                  className="text-input"
                  value={missionData?.title}
                  onChange={(e) => {
                    setMissionsData({ ...missionData, title: e.target.value })
                  }}
                  placeholder='e.g. Seekers Baray Mission Trip'
                />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Country</label>
                <Select
                  options={countryData.map((country) => {
                    return {
                      value: country?.altSpellings?.[0],
                      label: country.name
                    }
                  })}
                  value={countryData.map((country) => {
                    return {
                      value: country?.altSpellings?.[0],
                      label: country.name
                    }
                  }).find((country) => country.value == missionData?.country_id)}
                  onChange={(e) => {
                    setMissionsData({ ...missionData, country_id: e.value })
                  }}
                />
              </div>
            </div>
            <div className="row gap-10 alignCenter">
              <div className="input-container">
                <label htmlFor="" className="input-label">From</label>
                <input
                  type="date"
                  className="text-input"
                  value={missionData?.start_datetime?.split("T")[0]}
                  onChange={(e) => {
                    setMissionsData({ ...missionData, start_datetime: e.target.value })
                  }}
                />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">To</label>
                <input
                  type="date"
                  className="text-input"
                  value={missionData?.end_datetime?.split("T")[0]}
                  onChange={(e) => {
                    setMissionsData({ ...missionData, end_datetime: e.target.value })
                  }}
                />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Description</label>
              {/* <JoditEditor
                ref={editor}
                value={missionData?.description}
                config={liveConfig}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => {
                  setMissionsData({ ...missionData, description: newContent })
                }}
              /> */}

              <textarea
                className="textarea-input"
                value={missionData?.description}
                onChange={(e) => {
                  setMissionsData({ ...missionData, description: e.target.value })
                }}
              />
            </div>
            <div className="column alignEnd mt-10">
              <div className="row gap-10 alignCenter">
                {mission_id !== "new" && <button className="button-delete"
                  onClick={() => {
                    deleteMission()
                  }}
                >Delete</button>}
                <button className="button"
                  onClick={() => {
                    if (mission_id === "new") {
                      createMission()
                      savePrayerList()
                    } else {
                      updateMission()
                      updatePrayerList()
                    }
                  }}
                >Save</button>
              </div>
            </div>
          </div>
          {mission_id !== "new" && (
            <div className="mt-10 card">
              <div className="row justifySplit alignCenter">
                <p className="card-title">Prayers</p>
                <button className="row gap-10 alignCenter backgroundLess"
                  onClick={() => setPrayers([...prayers, { content: "", is_archived: "0", is_answered: "0", user_id: "", prayer_list_id: "", can_repeat: "0", prayers_count: [], testimony: "" }])}
                >
                  <MdAdd size={20} />
                  <p>Add Prayer</p>
                </button>
              </div>
              {!prayerListData?.id && (
                <div className='column center alignCenter mt-10'>
                  <p>No Prayer List created for the mission</p>
                  <button className="button"
                    onClick={() => {
                      savePrayerList()
                    }}
                  >Create list</button>
                </div>
              )}
              {prayers.length > 0 && prayers.map((prayer, index) => {
                return (
                  <div key={index} style={{ borderBottom: index != prayer?.length - 1 && "2px solid #f0f0f0", padding: 10 }}>
                    <div className="row alignCenter justifySplit">
                      <p className='card-title'>Prayer #{index + 1}</p>
                      <div className="row gap-10 alignCenter">
                        <div className="row gap-10 alignCenter">
                          <FaPrayingHands size={20} color='gray' />
                          <p className='gray'>{prayer.prayers_count?.length}</p>
                        </div>
                        <button className="backgroundLess gap-10 alignCenter row button-delete"
                          onClick={() => {
                            let newPrayers = [...prayers]
                            newPrayers.splice(index, 1)
                            setPrayers(newPrayers)
                          }}
                        >
                          <MdDelete size={20} />
                          <p>Delete</p>
                        </button>
                      </div>
                    </div>
                    <div className="row gap-10 alignStart mt-20">
                      <div className="input-container">
                        <label htmlFor="" className="input-label">Content</label>
                        <textarea className="textarea-input" value={prayer.content} onChange={(e) => {
                          let newPrayers = [...prayers]
                          newPrayers[index].content = e.target.value
                          setPrayers(newPrayers)
                        }} />
                      </div>
                      <div className="row gap-10 alignCenter">
                        <div className="input-container">
                          <label htmlFor="" className="input-label">Answered</label>
                          <Switch
                            checked={prayer.is_answered == "1"}
                            onChange={(checked) => {
                              let newPrayers = [...prayers]
                              newPrayers[index].is_answered = checked ? "1" : "0"
                              setPrayers(newPrayers)
                            }}
                          />
                        </div>
                        <div className="input-container">
                          <label htmlFor="" className="input-label">Repeatable</label>
                          <Switch
                            checked={prayer.can_repeat == "1"}
                            onChange={(checked) => {
                              let newPrayers = [...prayers]
                              newPrayers[index].can_repeat = checked ? "1" : "0"
                              setPrayers(newPrayers)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {prayer.is_answered == "1" && (
                      <div className="input-container">
                        <label htmlFor="" className="input-label">Testimony</label>
                        <textarea className="textarea-input" value={prayer.testimony} onChange={(e) => {
                          let newPrayers = [...prayers]
                          newPrayers[index].testimony = e.target.value
                          setPrayers(newPrayers)
                        }} />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </div>
        {mission_id !== "new" && (
          <div className="w-50">
            <div className="card">
              <p className="card-title">Missionaries</p>

              {/* <div style={{ width: "100%", height: 2, backgroundColor: "gray", marginBlock: 10 }} /> */}
              <div className="row w-100 alignCenter justifySplit">
                <p className="card-subtitle"></p>
                <button className="backgroundLess row gap-10 alignCenter"
                  onClick={() => {
                    setShowMemberModal(true)
                  }}
                >
                  <MdPersonAdd size={12} />
                  <p className="small">Missionary</p>
                </button>
              </div>
              {missionaryData?.length > 0 ? missionaryData?.map((member, index) => (
                <div key={index} className="row gap-10 alignCenter justifySplit mt-10 cursor-pointer">
                  <p style={{ fontSize: 14 }}
                    onClick={() => {
                      navigate("/admin/users/details?user_id=" + member.user_id)
                    }} className='cursor-pointer'>{member?.user_data?.first_name} {member?.user_data?.last_name}</p>
                  <div className="row gap-10 alignCenter">
                    <Select
                      options={ROLES}
                      value={ROLES.find(role => role.value == member.role)}
                      onChange={(e) => updateMember(member.user_id, e.value)}
                    />
                    <button
                      className="button-delete"
                      onClick={() => {
                        removeMember(member.user_id)
                      }}
                    >Remove</button>
                  </div>
                </div>
              )) :
                <p className="card-subtitle" style={{ fontStyle: "italic", fontWeight: "900" }}>No Missionary Added</p>
              }
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  )
}

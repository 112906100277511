import { useState } from "react";
import AboutCard from "../components/AboutCard";
import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import { Helmet } from "react-helmet";

const About = () => {
  const [openIframe, setOpenIframe] = useState(false);

  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>About Us - Bedok Methodist Church</title>
        </Helmet>
        <div
          className="video-iframe"
          style={{
            position: "fixed",
            inset: 0,
            right: 0,
            left: 0,
            marginInline: "auto",
            zIndex: 10,
            visibility: openIframe ? "visible" : "hidden",
            width: openIframe ? "100%" : 0,
            height: openIframe ? "100%" : 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 30,
          }}
        >
          <div
            style={{
              backgroundColor: "#555555",
              inset: 0,
              position: "absolute",
              opacity: 0.8,
              backgroundBlendMode: "darken",
            }}
          ></div>
          <div style={{ zIndex: 20 }}>
            <svg
              onClick={() => setOpenIframe(false)}
              clip-rule="evenodd"
              fill-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              width="5%"
              fill="#ffffff"
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginBottom: 10,
              }}
            >
              <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
            </svg>
            <iframe
              title="video"
              src={`//www.youtube.com/embed/_HydPSlwUNU?autoplay=1&${openIframe ? "" : "mute=1"}`}
              frameborder="0"
              style={{
                width: 900,
                aspectRatio: "16/9",
              }}
            ></iframe>
          </div>
        </div>
        <section
          style={{
            position: "relative",
            backgroundColor: "#999999",
          }}
        >
          <div style={{ mixBlendMode: "multiply" }}>
            <img src="About_Video_Thumbnail.jpeg" alt="Video Thumbnail" />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              position: "absolute",
              top: 0,
              bottom: 0,
              marginBlock: "auto",
              width: "100%",
              color: "#FFFFFF",
              textAlign: "center",
              padding: 15,
            }}
          >
            <h1 className="small about-video-title" style={{ fontWeight: 600 }}>
              BEDOK METHODIST CHURCH
            </h1>
            <p className="medium about-video-desc" style={{ fontWeight: 700 }}>
              We are disciples making disciples.
            </p>
            <svg
              className="about-play-video-btn"
              clip-rule="evenodd"
              fill-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              width="10%"
              onClick={() => setOpenIframe(true)}
            >
              <path d="m2.009 12.002c0-5.517 4.48-9.997 9.998-9.997s9.998 4.48 9.998 9.997c0 5.518-4.48 9.998-9.998 9.998s-9.998-4.48-9.998-9.998zm8.211-4.843c-.141-.108-.3-.157-.456-.157-.389 0-.755.306-.755.749v8.501c0 .445.367.75.755.75.157 0 .316-.05.457-.159 1.554-1.203 4.199-3.252 5.498-4.258.184-.142.29-.36.29-.592 0-.23-.107-.449-.291-.591z" />
            </svg>
          </div>
        </section>
        <section>
          <h2
            className="mt-30"
            style={{
              color: "#282828",
              fontSize: "1.2rem",
              fontWeight: "bold",
              textAlign: "center",
              backgroundColor: "#FFFFFF",
              paddingBlock: 20,
              paddingInline: 10,
              borderRadius: 10,
            }}
          >
            We are Christ’s disciples called to make disciples who are
            connected, equipped, mentored and released to fulfil God’s good
            purposes.
          </h2>
        </section>
        <section className="about-cards">
          <AboutCard
            imgUrl="/About1.jpeg"
            title="Connect"
            description="We have a personal relationship with Jesus, are in a life-giving disciple group (DG), and serve the cause of the Great Commission."
          />
          <AboutCard
            imgUrl="/About2.jpeg"
            title="Equip"
            description="We study and apply the Bible, equip and train ourselves to serve God and the community."
          />
          <AboutCard
            imgUrl="/About3.jpeg"
            title="Mentor"
            description="We intentionally invest our time and resources to journey with another disciple."
          />
          <AboutCard
            imgUrl="/About4.jpeg"
            title="Release"
            description="We are disciple makers released to share God’s love and make a difference in our sphere of influence and beyond."
          />
        </section>
        <section className="mt-30">
          <div
            style={{
              backgroundImage: "url(About_CTA_Banner.jpeg)",
              backgroundSize: "cover",
              backgroundColor: "rgba(0, 120, 128, 0.33)",
              backgroundPosition: "50% 50%",
              backgroundBlendMode: "darken",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 20,
            }}
          >
            <h3
              style={{
                fontSize: "2rem",
                fontWeight: "bold",
                textAlign: "center",
                color: "#FFFFFF",
              }}
            >
              CONNECT WITH A DISCIPLE GROUP (DG) TODAY!
            </h3>
            <a href="/" className="about-cta">
              REGISTER HERE
            </a>
          </div>
        </section>
        <section
          className="vision-mission mt-30"
          style={{
            backgroundColor: "#FFFFFF",
            paddingBlock: 20,
            paddingInline: 10,
            borderRadius: 10,
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h3
              style={{ color: "#008bad", fontWeight: "bold", fontSize: "2rem" }}
            >
              Our Vision
            </h3>
            <p style={{ color: "#777777" }}>To be disciples of Jesus Christ</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <h3
              style={{ color: "#008bad", fontWeight: "bold", fontSize: "2rem" }}
            >
              Our Mission
            </h3>
            <p style={{ color: "#777777" }}>
              We exist to make disciples who love God, love one another and love
              the lost
            </p>
          </div>
        </section>
        <section className="mt-30">
          <div
            style={{
              backgroundImage: "url(About_CTA_Banner.jpeg)",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 50,
            }}
          >
            <h3
              style={{
                fontSize: "2rem",
                fontWeight: "bold",
                textAlign: "center",
                color: "#008bad",
              }}
            >
              Our Statement of Faith
            </h3>
            <p style={{ textAlign: "center", fontWeight: 500 }}>
              Our Statement of Faith We believe in God, the Father Almighty,
              creator of heaven and earth: We believe in Jesus Christ, His only
              Son, our Lord; who was conceived by the Holy Spirit, born of the
              Virgin Mary, suffered under Pontius Pilate, was crucified, died,
              and was buried; he descended to the dead. On the third day he rose
              again; he ascended into heaven, is seated at the right hand of the
              Father, and will come again to judge the living and the dead. We
              believe in the Holy Spirit, the holy universal Church, the
              communion of saints, the forgiveness of sins, the resurrection of
              the body and the life everlasting. Amen.
            </p>
          </div>
        </section>
        <section
          className="mt-30 about-history"
          style={{
            backgroundColor: "#FFFFFF",
            paddingBlock: 20,
            paddingInline: 10,
            borderRadius: 10,
          }}
        >
          <div>
            <img
              src="About_History.jpeg"
              alt="Kampong Village"
              width="100%"
              style={{ borderRadius: 10 }}
            />
          </div>
          <div className="about-history-info">
            <h3
              style={{ color: "#008bad", fontWeight: "bold", fontSize: "2rem" }}
            >
              Our History
            </h3>
            <p className="mt-20" style={{ color: "#777777" }}>
              <p>
                <span style={{ fontWeight: "bold", marginRight: 4 }}>
                  Bedok Methodist Church (BMC)
                </span>
                was born on 21 October 1946 when 15 members of the Geylang
                Straits Chinese Methodist Church came together for their first
                service by Rev Chew Hock Hin in an attap house at Padang
                Terbakar.
              </p>
              <p className="mt-20 mb-20">
                On 29 September 1952, the cornerstone for a new church building
                was laid by Rev Chew, and the building was dedicated by Bishop
                R.L. Archer the following year. God continued to bless BMC, and
                our church grew steadily over the years.
              </p>
              In 2021, BMC celebrated 75 years of God’s goodness and
              faithfulness.
            </p>
          </div>
        </section>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default About;

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import Switch from 'react-switch'
import { MdAdd } from 'react-icons/md';

export default function AdminCafeFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let breakfast_id = new URLSearchParams(window.location.search).get("breakfast_id")

  const [breakfastData, setBreakfastData] = useState({
    name: "",
    is_selling: "1",
    menu: [], // {name, price, description}
    is_published: "0",
    datetime: new Date(),
  })

  const fetchBreakfastInformationById = async () => {
    if (!breakfast_id) return
    let endpoint = `${config.endpoint}/cafe/breakfast/id/${breakfast_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setBreakfastData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchBreakfastInformationById()
  }, [])


  const createBreakfast = async () => {
    let endpoint = `${config.endpoint}/cafe/breakfast/`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...breakfastData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/cafe/details?breakfast_id=" + data.data.id)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateBreakfast = async () => {
    if (!breakfast_id) return
    let endpoint = `${config.endpoint}/cafe/breakfast/id/${breakfast_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...breakfastData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteEvent = async () => {
    if (!breakfast_id) return
    let endpoint = `${config.endpoint}/cafe/breakfast/id/${breakfast_id}`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/cafe")
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  return (
    <AdminLayout
      navSelected={"Cafe"}
      regularContainer={breakfast_id !== "new"}
    >
      <div className="row gap-10 alignStart">
        <div className="w-100">
          <div className="card">
            <p className="card-title">Breakfast Information</p>
            <div className="row gap-10">
              <div className="column">
                <label htmlFor="name" className='input-label'>Name</label>
                <input type="text" id="name" className="text-input" value={breakfastData.name}
                  placeholder='Who is selling?'
                  onChange={(e) => {
                    setBreakfastData({
                      ...breakfastData,
                      name: e.target.value
                    })
                  }}
                />
              </div>
              <div className="row alignCenter gap-10">
                <label htmlFor="is_selling" className='input-label'>Open</label>
                <Switch onChange={(checked) => {
                  setBreakfastData({
                    ...breakfastData,
                    is_selling: checked ? "1" : "0"
                  })
                }} checked={breakfastData.is_selling == "1"} />
              </div>
              <div className="row alignCenter gap-10">
                <label htmlFor="is_selling" className='input-label'>Published</label>
                <Switch onChange={(checked) => {
                  setBreakfastData({
                    ...breakfastData,
                    is_published: checked ? "1" : "0"
                  })
                }} checked={breakfastData.is_published == "1"} />
              </div>
            </div>

            <div className="row gap-10 mt-10">
              <div className="column w-100">
                <label htmlFor="datetime" className='input-label'>Date</label>
                <input type="datetime-local" id="datetime" className="text-input w-100" value={breakfastData.datetime}
                  onChange={(e) => {
                    setBreakfastData({
                      ...breakfastData,
                      datetime: e.target.value
                    })
                  }}
                />
              </div>
            </div>

            {/* Menu */}
            <div className="row mt-20 alignCenter gap-10 justifySplit">
              <h3>Menu</h3>
              <button className="backgroundLess row alignCenter gap-10"
                onClick={() => {
                  let temp = [...breakfastData.menu]
                  temp.push({ name: "", price: 0, description: "" })
                  setBreakfastData({
                    ...breakfastData,
                    menu: temp
                  })
                }}
              >
                <MdAdd size={15} />
                Add</button>
            </div>

            {breakfastData?.menu?.length > 0 && breakfastData?.menu?.map((menu, index) => {
              return (
                <div key={index} className="mt-10" style={{ borderBottom: index + 1 !== breakfastData?.menu?.length && "2px solid gray", padding: 10, paddingBottom: 20, marginBottom: 20, }}>
                  <div className="row justifySplit alignCenter">
                    <p className='b'>Item #{index + 1}</p>
                    <button className="backgroundLess"
                      onClick={() => {
                        let temp = [...breakfastData.menu]
                        temp.splice(index, 1)
                        setBreakfastData({
                          ...breakfastData,
                          menu: temp
                        })
                      }}
                    >Delete</button>
                  </div>
                  <div className="row gap-10">
                    <div className="column">
                      <label htmlFor="name" className='input-label'>Name</label>
                      <input type="text" id="name" className="text-input" value={menu.name}
                        placeholder='e.g. Roti Prata'
                        onChange={(e) => {
                          let temp = [...breakfastData.menu]
                          temp[index].name = e.target.value
                          setBreakfastData({
                            ...breakfastData,
                            menu: temp
                          })
                        }}
                      />
                    </div>
                    <div className="column">
                      <label htmlFor="price" className='input-label'>Price</label>
                      <input type="text" id="price" className="text-input" value={menu.price}
                        placeholder='e.g. 2.50'
                        onChange={(e) => {
                          let temp = [...breakfastData.menu]
                          temp[index].price = e.target.value
                          setBreakfastData({
                            ...breakfastData,
                            menu: temp
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="column mt-10">
                    <label htmlFor="description" className='input-label'>Description</label>
                    <textarea id="description" className="textarea-input" value={menu.description}
                      placeholder='e.g. Comes with curry sauce'
                      onChange={(e) => {
                        let temp = [...breakfastData.menu]
                        temp[index].description = e.target.value
                        setBreakfastData({
                          ...breakfastData,
                          menu: temp
                        })
                      }}
                    />
                  </div>
                </div>
              )
            })}

            <div className="column alignEnd mt-30">
              <div className="row gap-10 alignCenter">
                {breakfast_id !== "new" && <button className="button-delete"
                  onClick={() => {
                    deleteEvent()
                  }}
                >Delete</button>}
                <button className="button"
                  onClick={() => {
                    if (breakfast_id === "new") {
                      createBreakfast()
                    } else {
                      updateBreakfast()
                    }
                  }}
                >Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

import { useEffect, useState } from "react"
import config from "../app-config"

export const logout = () => {
    let endpoint = config.endpoint + "/auth/logout"
    let response = fetch(endpoint, {
        credentials: "include",
        method: "POST",

    })
    localStorage.removeItem("user")
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/"
}


export const analyticsCollector = async () => {
    let endpoint = config.endpoint + "/analytics/collector/users"
    let fetchResponse = await fetch(endpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ device: "web" })
    })
    let data = await fetchResponse.json()
    return 
}

export const useFetchAuthorisation = (account_type) => {
    const [isAuthorised, setIsAuthorised] = useState(false);
    const [userRole, setUserRole] = useState("");
    let cred = parseInt(JSON.parse(window.sessionStorage.getItem("cred_warn"))) || 0

    useEffect(() => {
        const fetchAuthorisation = async () => {
            try {
                const endpoint = config.endpoint + "/auth/authorisation";
                const response = await fetch(endpoint, {
                    credentials: "include",
                    method: "POST",
                });
                const data = await response?.json();
                if (data.status === 200) {
                    sessionStorage.setItem("user", JSON.stringify(data.data));
                    setUserRole(data?.data?.account_type);
                    if (convertToRole(account_type) <= convertToRole(data?.data?.account_type)) {
                        window.sessionStorage.removeItem("cred_warn")
                        setIsAuthorised(true);
                    } else {
                        cred += 1
                        if (cred > 3) {
                            window.location.href = "/auth/login"
                            setIsAuthorised(false);
                        }
                        window.sessionStorage.setItem("cred_warn", JSON.stringify(cred))
                    }
                } else {
                    cred += 1
                    if (cred > 3) {
                        window.location.href = "/auth/login"
                        setIsAuthorised(false);
                    }
                    window.sessionStorage.setItem("cred_warn", JSON.stringify(cred))
                }
            } catch (error) {
                setIsAuthorised(false);
            }
        };
        fetchAuthorisation();
    }, [account_type]);
    return { isAuthorised, userRole };
};


export const useLogin = (account_type) => {
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        const fetchAuthorisation = async () => {
            try {
                const endpoint = config.endpoint + "/auth/authorisation";
                const response = await fetch(endpoint, {
                    credentials: "include",
                    method: "POST",
                });
                const data = await response?.json();
                if (data.status === 200) {
                    sessionStorage.setItem("user", JSON.stringify(data.data));
                    setIsLogin(true);
                } else {
                    setIsLogin(false);
                }
            } catch (error) {
                setIsLogin(false);
            }
        };
        fetchAuthorisation();
    }, [account_type]);
    return isLogin;
}

export const useFetchUserData = () => {
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const endpoint = config.endpoint + "/auth/authorisation";
                const response = await fetch(endpoint, {
                    credentials: "include",
                    method: "POST",
                });
                const data = await response?.json();
                if (data.status === 200) {
                    sessionStorage.setItem("user", JSON.stringify(data.data));
                    setUserData(data.data);
                } else {
                    setUserData({});
                }
            } catch (error) {
                setUserData({});
            }
        };
        fetchUserData();
    }, []);
    return userData;
}


export const authorisationConverter = ({ requirement, current_role }) => {
    return convertToRole(requirement) <= convertToRole(current_role)
}

const convertToRole = (role) => {
    switch (role) {
        case "member":
            return 1;
        case "staff":
            return 2;
        case "manager":
            return 3;
        case "admin":
            return 4;
        case "superadmin":
            return 5;
        default:
            return 0;
    }
};
import { useState } from "react";

const Accordion = ({ question, answer }) => {
  const [openAnswer, setOpenAnswer] = useState(false);
  return (
    <div className="faq" style={{ color: "#777777" }}>
      <div className="question" onClick={() => setOpenAnswer((prev) => !prev)}>
        <p>{question}</p>
      </div>
      <p className={openAnswer ? "answer active" : "answer"}>{answer}</p>
    </div>
  );
};

export default Accordion;

import React from 'react'

export default function ShortTextComponent({ data, onChange, state, type, readonly }) {
    return (
        <>
            {state === "answer" &&
                <input type={type || "text"} className="text-input" style={{width:"100%"}} value={data} readOnly={readonly} onChange={(e) => onChange(e.target.value)} />
            }
        </>
    )
}

import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import StaffCard from "../components/StaffCard";
import { FaEnvelope } from "react-icons/fa";
import { Helmet } from "react-helmet";

const StaffMembers = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Our Pastors & Staff - Bedok Methodist Church</title>
      </Helmet>
      <main>
        <section
          className="mt-30 about-history"
          style={{
            backgroundColor: "#FFFFFF",
            paddingBlock: 20,
            paddingInline: 10,
            borderRadius: 10,
          }}
        >
          <div>
            <img
              src="Aaron.jpeg"
              alt="Kampong Village"
              width="100%"
              style={{ borderRadius: 10 }}
            />
          </div>
          <div className="about-history-info">
            <h3
              style={{ color: "#008bad", fontWeight: "bold", fontSize: "2rem" }}
            >
              Rev Dr Aaron Tay
            </h3>
            <p style={{ color: "#777777" }}>Pastor-in-Charge</p>
            <p
              className="mt-20"
              style={{
                color: "#777777",
                fontSize: "0.9rem",
              }}
            >
              Rev Dr Aaron Tay has been a Methodist Pastor since 2005, serving 6
              years at Pentecost Methodist Church, and then Pastor-in-charge of
              Living Waters Methodist Church, as well as the Chaplain-in-charge
              of Anglo-Chinese School (Independent) for 10 years till 31 Mar
              2021. He also serves as the Director, Ministry in Schools, of the
              Methodist Church in Singapore (DMS of the 14 Methodist Schools in
              Singapore). Prior to being a pastor, Aaron served as a missionary.
              From 1996 to 1999, he was with the missionary ship, MV Doulos,
              where he was with the Advance Preparations Team. He was at Port
              Moresby (Papua New Guinea), Chennai (India), and led the teams in
              Mauritius and Adelaide (Australia). From 1999 to 2002, Aaron was
              with the Methodist Missions Society where he was involved in
              setting up COSI Children’s Village as well as with the youth
              ministry in the Cambodian church. A graduate in Computer Science,
              Aaron is married to Wendy (also a Methodist pastor), and they have
              two sons, Nathan (19) and Jerome (17). Aaron has a Doctor of
              Ministry from Perkins School of Theology, Southern Methodist
              University, USA. Pastor Aaron Tay was baptised in 1991 and was
              sent out by Bedok Methodist Church to be a missionary in 1996 and
              then a TRAC pastor in 2005. He is thankful that he is now back in
              his home church, which has been a blessing to him for many years.
            </p>
            <hr style={{ marginTop: 10 }} />

            <p
              className="mt-10 about-tooltip"
              style={{
                cursor: "pointer",
                width: "fit-content",
              }}
              onClick={() =>
                window.open("mailto:aarontay@bmc.org.sg", "_blank")
              }
            >
              <FaEnvelope className="w-100" />
              <span className="tooltiptext" style={{}}>
                Send us an email
              </span>
            </p>
          </div>
        </section>

        <section
          className="mt-30 about-history"
          style={{
            backgroundColor: "#FFFFFF",
            paddingBlock: 20,
            paddingInline: 10,
            borderRadius: 10,
          }}
        >
          <div>
            <img
              src="Timothy.jpeg"
              alt="Kampong Village"
              width="100%"
              style={{ borderRadius: 10 }}
            />
          </div>
          <div className="about-history-info">
            <h3
              style={{ color: "#008bad", fontWeight: "bold", fontSize: "2rem" }}
            >
              Rev Timothy Yong
            </h3>
            <p style={{ color: "#777777" }}>Pastor</p>
            <p
              className="mt-20"
              style={{ color: "#777777", fontSize: "0.9rem" }}
            >
              Before joining the pastoral ministry, Timothy used to work as an
              auditor in PwC. In his free time, he enjoys jogging, cycling,
              watching movies and chilling over a cup of coffee. His favourite
              foods include a good steak and ice cream. Tim is happily married
              to Valerie, whom he met at Trinity MC, and they are blessed with
              two lovely children, Zion and Zoe.
            </p>
            <hr style={{ marginTop: 10 }} />

            <p
              className="mt-10 about-tooltip"
              style={{
                cursor: "pointer",
                width: "fit-content",
              }}
              onClick={() =>
                window.open("mailto:timothy.yong@bmc.org.sg", "_blank")
              }
            >
              <FaEnvelope className="w-150" />
              <span className="tooltiptext" style={{}}>
                Send us an email
              </span>
            </p>
          </div>
        </section>

        <section
          className="mt-30"
          style={{
            backgroundColor: "#FFFFFF",
            paddingBlock: 20,
            paddingInline: 10,
            borderRadius: 10,
          }}
        >
          <h3
            className="mb-20"
            style={{ color: "#008bad", fontWeight: "bold", fontSize: "2rem" }}
          >
            Our Staff Members
          </h3>
          <div className="staff-members">
            <StaffCard
              name="ABRAHAM GOH"
              position="DISCIPLESHIP & NURTURE"
              imgUrl={"Abraham.jpeg"}
            />
            <StaffCard
              name="CHELSEA CHEANG"
              position="
PASTORAL CARE
"
              imgUrl={"Chelsea.jpeg"}
            />
            <StaffCard
              name="GRACE GOH"
              position="YOUTH & YOUNG ADULTS"
              imgUrl={"Grace.jpeg"}
            />
            <StaffCard
              name="HELEN LIU YI"
              position="CHINESE MINISTRY"
              imgUrl={"LiuYi.jpeg"}
            />
            <StaffCard
              name="JOANNA TNG"
              position="WORSHIP & MUSIC"
              imgUrl={"Joanna.jpeg"}
            />
            <StaffCard
              name="JOEL YONG"
              position="DISCIPLE GROUPS"
              imgUrl={"Joel.jpeg"}
            />
            <StaffCard
              name="NG SHIPING"
              position="BB & MISSIONS"
              imgUrl={"Shiping.jpeg"}
            />
            <StaffCard
              name="NG POH SUN"
              position="FINANCE & HR"
              imgUrl={"PohSun.jpeg"}
            />
          </div>
        </section>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default StaffMembers;

import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import { Link } from "react-router-dom";
import pages from "../utils/pages";
import { Helmet } from "react-helmet";

const SiteMap = () => {
  return (
    <MainLayout>
      <main
        style={{ backgroundColor: "#ffffff", padding: 20, borderRadius: 10 }}
      >
        <Helmet>
          <title>Site Map - Bedok Methodist Church</title>
        </Helmet>
        <h1 style={{ color: "#555555", fontSize: "1.5rem", fontWeight: 600 }}>
          PAGES
        </h1>
        <section
          style={{
            padding: 30,
          }}
        >
          <ul style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {pages.map((page) => (
              <li>
                <Link
                  to={page.url}
                  style={{ textDecoration: "none", color: "#334862" }}
                >
                  {page.page_name}
                </Link>
              </li>
            ))}
          </ul>
        </section>
      </main>
      <SubFooter />
    </MainLayout>
  );
};

export default SiteMap;

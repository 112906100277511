import React, { useState, useEffect } from "react";
// import AdminNavbar from '../components/AdminNavbar'
import MainNavbar from "../components/MainNavbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router";
import {
  useFetchAuthorisation,
  authorisationConverter,
} from "../utils/AppFunctions";
import config from "../app-config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function MainLayout({ children, navSelected, container_max }) {
  const navigate = useNavigate();
  // const { isAuthorised, userRole } = useFetchAuthorisation("user");

  const [hasScrolled, setHasScrolled] = useState(true);
  const handleScroll = (e) => {
    console.log(e.target.scrollTop);
    if (e.target.scrollTop > 0) {
      setHasScrolled(false);
    } else {
      setHasScrolled(true);
    }
  };

  return (
    <div style={{ width: "100vw" }}>
      <ToastContainer />
      <div className="row" style={{ width: "100%" }}>
        {/* <MainNavbar selected={navSelected} /> */}
        <div
          onScroll={handleScroll}
          style={{
            width: "100%",
            maxHeight: "100vh",
            overflowY: "scroll",
            scrollBehavior: "smooth",
          }}
        >
          <p id="/" style={{ visibility: "hidden", height: 0 }}></p>
          <Header hasScrolled={hasScrolled} />
          <div className="container">
            <div
              className="inner-container"
              style={{
                maxWidth: container_max || "1000px",
              }}
            >
              {children}
            </div>
            <a href="#/">
              <svg
                className="back-to-top-btn"
                style={{
                  position: "absolute",
                  right: "1vw",
                  bottom: "2vh",
                  opacity: hasScrolled ? 0 : 1,
                  transform: hasScrolled ? "scale(0)" : "scale(1)",
                  transition: "all 300ms",
                }}
                width="50px"
                clip-rule="evenodd"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m11.998 21.995c5.517 0 9.997-4.48 9.997-9.997 0-5.518-4.48-9.998-9.997-9.998-5.518 0-9.998 4.48-9.998 9.998 0 5.517 4.48 9.997 9.998 9.997zm4.843-8.211c.108.141.157.3.157.456 0 .389-.306.755-.749.755h-8.501c-.445 0-.75-.367-.75-.755 0-.157.05-.316.159-.457 1.203-1.554 3.252-4.199 4.258-5.498.142-.184.36-.29.592-.29.23 0 .449.107.591.291z"
                  fill-rule="nonzero"
                />
              </svg>
            </a>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

const Button = ({ title, href }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        bottom: "10%",
        width: "100%",
      }}
    >
      <a
        href={href}
        className="white b-600 medium services-button"
        style={{
          textDecoration: "none",
          display: "inline-block",
          borderRadius: 99,
          paddingBlock: 10,
          paddingInline: 20,
          textTransform: "uppercase",
        }}
      >
        {title}
      </a>
    </div>
  );
};

export default Button;

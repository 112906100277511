import React from "react";
import { Link } from "react-router-dom";

const MinistryPhoto = ({ name, type, img }) => {
  return (
    <Link to={`/featured-item/${name}`}>
      <div
        className="ministry-photo"
        style={{ position: "relative", borderRadius: 10 }}
      >
        <img
          src="bmc_img1.jpeg"
          alt=""
          style={{
            objectFit: "cover",
            aspectRatio: "1/1",
            borderRadius: 10,
          }}
        />
        <div
          className="ministry-desc"
          style={{
            position: "absolute",
            top: "50%",
            padding: "10px 20px",
            backgroundColor: "#0094b3",
            width: "100%",
            textAlign: "center",
          }}
        >
          <h3
            style={{
              color: "rgba(255, 255, 255, .95)",
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: "1.2rem",
            }}
          >
            {name}
          </h3>
          <p
            style={{
              color: "rgba(255, 255, 255, .75)",
              textTransform: "uppercase",
              fontSize: "0.8rem",
            }}
          >
            {type}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default MinistryPhoto;

import { useEffect, useState } from "react";
import config from "../app-config";
import { getMobileOS } from "../utils/helper";
import { Helmet } from "react-helmet";

const Promote = () => {
  const [downloadLinks, setDownloadLinks] = useState([]);

  useEffect(() => {
    const fetchDownloadLinks = async () => {
      try {
        const res = await fetch(`${config.endpoint}/config`);
        const data = await res.json();
        const links = data.data.general;
        console.log(links);
        setDownloadLinks(links);
      } catch (err) {
        return err;
      }
    };

    fetchDownloadLinks();
  }, []);

  // Auto redirection based on OS
  useEffect(() => {
    if (!downloadLinks.ios_link) return;
    if (!downloadLinks.android_link) return;
    const os = getMobileOS();
    console.log(`OS: ${os} detected`)
    switch (os) {
      case "macos":
        window.location.href = downloadLinks.ios_link;
        break;
      case "android":
        window.location.href = downloadLinks.android_link;
        break;
      case "ios":
        window.location.href = downloadLinks.ios_link;
        break;
      case "windows":
        break;
      default:
        break;
    }
  },[]);

  return (
    <main style={{}}>
      <Helmet>
        <title>BMC Plus - Bedok Methodist Church</title>
      </Helmet>
      <div
        className="promo-banner"
        style={{
          position: "relative",
        }}
      >
        <div className="promo-banner-bg-outer-circle">
          <div className="promo-banner-bg-inner-circle"></div>
        </div>

        <div>
          <img
            src="/logoWhite.png"
            alt="Bedok Methodist Church Logo"
            style={{
              width: "170px",
              objectFit: "contain",
              marginTop: 20,
              marginLeft: 30,
            }}
          />
        </div>
        <div
          className="promo-banner-main-content"
          style={{ padding: 30, color: "#ffffff" }}
        >
          <div style={{ marginTop: "auto" }}>
            {/* To be replaced by phone img
            <div
              style={{
                backgroundColor: "lightgrey",
                borderRadius: 10,
                aspectRatio: "9/16",
                maxWidth: 200,
                margin: "auto",
                boxShadow: "2px 3px 5px #999",
              }}
            ></div> */}
            <img
              src="/screenshots/phone.png"
              alt="Phone"
              style={{
                margin: "auto",
                boxShadow: "2px 3px 5px #999",
              }}
            />
          </div>
          {/* To be replaced by QRcode */}
          {/* <div
            className="promo-banner-qr"
            style={{
              backgroundColor: "lightgrey",
              width: 100,
              height: 100,
              boxShadow: "2px 3px 5px #999",
            }}
          ></div> */}

          <img src="/screenshots/qrcode.png" alt="QR Code" style={{width: 100, height: 100, margin: "auto", boxShadow: "2px 3px 5px #999"}} />
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 20,
            }}
          >
            <div>
              <h1 className="promo-banner-header">BEDOK METHODIST CHURCH</h1>
              <h2 className="promo-banner-subheader">
                YOUR GATEWAY TO FAITHFUL ENGAGEMENT
              </h2>
            </div>
            <p className="promo-banner-desc">Stay Engaged, Anytime, Anywhere</p>
            <h2 className="promo-banner-subheader">AVAILABLE ON THE</h2>
            <div className="promo-banner-download-links">
              <div>
                <a
                  href={downloadLinks.ios_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/ios.webp" alt="IOS Download" />
                </a>
              </div>
              <div>
                <a
                  href={downloadLinks.android_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/android.webp" alt="Android Download" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 319"
            height="80px"
            width="100%"
            preserveAspectRatio="none"
          >
            <path
              fill="#ffffff"
              fill-opacity="1"
              d="M0,224L80,229.3C160,235,320,245,480,224C640,203,800,149,960,138.7C1120,128,1280,160,1360,176L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>
          <div
            className="promo-contact-info"
            style={{
              backgroundColor: "#ffffff",
              paddingInline: 30,
              paddingBottom: 30,
            }}
          >
            <p
              className="promo-contact-info-name"
              style={{
                textAlign: "center",
                color: "#008BAD",
                fontWeight: "bold",
                fontFamily: "",
                fontStyle: "italic",
              }}
            >
              BMC
              <span className="promo-contact-info-name-suffix">PLUS</span>
            </p>

            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <img src="/speech.svg" alt="Email" />
              <a
                href="mailto:office@bmc.org.sg"
                style={{
                  fontWeight: "bold",
                  color: "#035472",
                  textDecoration: "none",
                }}
              >
                office@bmc.org.sg
              </a>
            </div>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <img src="/phone.svg" alt="Contact" />
              <p style={{ fontWeight: "bold", color: "#035472" }}>
                +65 6448 4215
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Promote;

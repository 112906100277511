import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "../app-config";
import { Helmet } from "react-helmet";

const News = () => {
  const { topic } = useParams();

  const endpoint = `${config.endpoint}/announcements/ongoing`;
  const [annoucements, setAnnoucements] = useState([]);

  useEffect(() => {
    const fetchAllAnnoucements = async () => {
      try {
        const res = await fetch(endpoint);
        const data = await res.json();
        if (data.status === 200) {
          const filteredNews = data.data.filter(
            (announcement) => announcement.title === topic
          );
          setAnnoucements(filteredNews[0]);
        } else {
          throw new Error("Failed to retrive annoucements");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllAnnoucements();
  }, [endpoint, topic]);

  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>{annoucements.title} - Bedok Methodist Church</title>
        </Helmet>
        <section
          style={{ background: "#ffffff", padding: 20, borderRadius: 10 }}
        >
          <div
            style={{
              flex: 0.8,
              display: "flex",
              flexDirection: "column",
              gap: 30,
            }}
          >
            <p
              style={{
                color: "#333333",
                fontSize: "1.5rem",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              {annoucements.title}
            </p>
            <div
              style={{
                backgroundColor: "#77777777",
                width: 50,
                height: 5,
                margin: "auto",
              }}
            ></div>
            <div style={{ flex: 1 }}>
              <img
                //   src={`${config.endpoint}/${annoucements.ext_file_path}`}
                src="/bmc_img5.jpeg"
                alt=""
                style={{ borderRadius: 5 }}
              />
            </div>

            <p
              style={{
                color: "#777777",
                marginBottom: 10,
              }}
            >
              {/* {annoucements.description} */}
              The 2024 Primary One Registration Exercise for Singapore Citizens
              and Permanent Residents will start from Tuesday, 2 July. Phase 2B
              is applicable for a child whose parent is a member endorsed by the
              church directly connected with the primary school. Phase 2B
              registration will open from Monday 22 July to Tuesday 23 July.
              Church members who wish to register their children in our
              Methodist primary schools, please email petrinayong@bmc.org.sg
              before 1 July.
            </p>
          </div>
        </section>
      </main>
      <SubFooter />
    </MainLayout>
  );
};

export default News;

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'

export default function CommerceProductViewPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const { id } = useParams()
  const [product, setProduct] = useState({})

  useEffect(() => {
    fetchProduct()
  }, [])

  const fetchProduct = async () => {
    try {
      const response = await fetch(`${config.endpoint}/commerce/products/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
      let data = await response.json()
      if (response.status === 200) {
        setProduct(data.data)
      } else {
        console.log(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <AdminLayout
      navSelected={"Home"}
    >
      {product  
        ? <div className="card wrap-row" style={{padding:"30px"}}>
            <img className="mr-40 header-link" src={`https://via.placeholder.com/400`} alt="product" onClick={() => navigate(`/admin/commerce/products/${product.id}`)}/>
            <div className="normal ph-20 pv-30 w-40">
              <h1 className="header medium">{product.name}</h1>
              <p>{product.description}</p>
              <p>Price: {product.price}</p>
              <p>Quantity Available: {product.quantity_available}</p>
              <p>Available From: {product.available_from}</p>
              <p>Available Until: {product.available_until}</p>
              <button onClick={() => navigate("/admin/commerce/products")}>Back</button>
            </div>
          </div>
        : <h2>Product does not exist.</h2>
      }
    </AdminLayout>
  )
}

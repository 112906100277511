import MainLayout from "../layouts/MainLayout";
import Home from "./Home";

export default function IndexPage() {
  return (
    <MainLayout>
      <Home />
    </MainLayout>
  );
}

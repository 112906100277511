import { useEffect, useState } from "react";
import config from "../app-config";
import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const SermonSeries = () => {
  const [sermonSeries, setSermonSeries] = useState([]);

  useEffect(() => {
    const fetchSermonSeries = async () => {
      const res = await fetch(`${config.endpoint}/services/all`);
      const data = await res.json();
      setSermonSeries(data.data);
    };

    fetchSermonSeries();
  }, []);

  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Sermon Series - Bedok Methodist Church</title>
        </Helmet>
        <div style={{ position: "relative" }}>
          <img src="sermon-series-banner.jpeg" alt="Opened Bible" />
          <Link
            to="/sermon-series/gospel-of-matthew"
            className="matthew-sermon-series-btn"
            style={{
              position: "absolute",
              padding: 10,
              inset: 0,
              margin: "auto auto",
              width: "fit-content",
              height: "fit-content",
              color: "#555555",
              borderRadius: 10,
              fontWeight: "bold",
              textDecoration: "none",
            }}
          >
            Click Here For Sermon Series on Matthew
          </Link>
        </div>

        <div>
          <h1
            className="mt-30"
            style={{ color: "#555555", fontWeight: "bold", fontSize: "1.5rem" }}
          >
            Sermon Series
          </h1>
          <div
            className="mt-20 sermon-series"
            style={{
              background: "#ffffff",
              padding: 20,
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            {sermonSeries.slice(0, 5).map((series) => {
              const formattedDate = new Date(series.createdAt).toLocaleString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );

              const title = "Introduction. The Start of Genesis";
              // series.sermon_outline
              //   .split(
              //     '<p><span style="font-size: 14px;">I. Introduction</span></p><p><span style="font-size: 14px;"><br></span></p><p><span style="font-size: 14px;">'
              //   )[1]
              //   .split("-")[0];
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#555555",
                    fontWeight: "bold",
                    alignItems: "center",
                  }}
                >
                  <p>{formattedDate}</p>
                  <a
                    className="sermon-series-vid-link"
                    href={series.video_link}
                  >
                    {title}
                  </a>
                  <p>Rev Dr Aaron Tay</p>
                  {/* <p>{ series.name}</p> */}
                  <img
                    width="50"
                    src="https://bmc.org.sg/wp-content/uploads/Notes1.jpg"
                    alt="notepad"
                  />
                </div>
              );
            })}
          </div>
        </div>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default SermonSeries;

import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import config from "../app-config";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const submitForm = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      const response = await fetch(`${config.endpoint}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      setFormData({
        contactName: "",
        contactEmail: "",
        contactMessage: "",
        contactPhone: "",
        contactEnquiry: "",
      });

      return response.json();
    } catch (err) {
      console.error("Error submitting form:", err);
    }
  };
  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Contact Us - Bedok Methodist Church</title>
        </Helmet>
        <div>
          <iframe
            title="Map Location of BMC"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7536263721045!2d103.9516029507405!3d1.3236803620219455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da22d3afb0d5f7%3A0x5b0226a59c477e4a!2sBedok%20Methodist%20Church!5e0!3m2!1sen!2ssg!4v1579462711523!5m2!1sen!2ssg"
            width="2000"
            height="450"
            frameborder="0"
            style={{ marginBottom: 30 }}
            allowfullscreen=""
          ></iframe>
        </div>
        <section
          className="contact-us-info"
          style={{
            backgroundColor: "#ffffff",
            padding: 20,
            borderRadius: 10,
            color: "#777777",
            lineHeight: 1.5,
          }}
        >
          <div>
            <h3
              className="mb-10"
              style={{ fontSize: "1.2rem", fontWeight: 600, color: "#555555" }}
            >
              BMC Office
            </h3>
            <p>86 Bedok Road</p>
            <p>Singapore 469371</p>
            <p>Tel: 6448 4215 </p>
            <p>
              Email:{" "}
              <Link style={{ color: "#334862" }} to="mailto:office@bmc.org.sg">
                office@bmc.org.sg
              </Link>
            </p>
          </div>
          <div>
            <h3
              className="mb-10"
              style={{ fontSize: "1.2rem", fontWeight: 600, color: "#555555" }}
            >
              BMC Office Opening Hours
            </h3>
            <p>Mondays to Fridays: 9am – 5pm</p>
            <p>BMC office will be closed on weekends and public holidays.</p>
          </div>
          <div>
            <h3
              className="mb-10"
              style={{ fontSize: "1.2rem", fontWeight: 600, color: "#555555" }}
            >
              Bedok Methodist Preschool
            </h3>
            <p>86 Bedok Road</p>
            <p>Singapore 469371</p>
            <p>Tel: 6445 8048</p>
            <p>Opening hours: 8.30am to 4pm</p>
            <p>Principal: Mrs Patsy Wee</p>
            <p>
              Email:{" "}
              <Link
                style={{ color: "#334862" }}
                to="mailto:enquiry.bmp@methodistpreschools.org"
              >
                enquiry.bmp@methodistpreschools.org
              </Link>
            </p>
          </div>
          <div></div>
          <div>
            <h3
              className="mb-10"
              style={{ fontSize: "1.2rem", fontWeight: 600, color: "#555555" }}
            >
              Contact Form
            </h3>
            <p>
              We will respond to your enquiry as soon as we can. Thank you for
              your patience and understanding.
            </p>
            <form
              onSubmit={submitForm}
              className="mt-10"
              style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
              <div>
                <label htmlFor="name" aria-label="Name" hidden>
                  Name:{" "}
                </label>
                <input
                  id="name"
                  name="contactName"
                  onChange={handleChange}
                  value={formData.contactName}
                  placeholder="Name"
                  required
                  style={{
                    width: "100%",
                    height: 40,
                    padding: 10,
                    borderRadius: 999,
                    outline: "none",
                    border: "1px solid rgba(0, 0, 0, .09)",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  }}
                />
              </div>

              <div>
                <label htmlFor="email" aria-label="Email" hidden>
                  Email:{" "}
                </label>
                <input
                  type="email"
                  id="email"
                  name="contactEmail"
                  onChange={handleChange}
                  value={formData.contactEmail}
                  placeholder="Email"
                  required
                  style={{
                    width: "100%",
                    height: 40,
                    padding: 10,
                    borderRadius: 999,
                    outline: "none",
                    border: "1px solid rgba(0, 0, 0, .09)",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  }}
                />
              </div>
              <div>
                <label htmlFor="enquiry" aria-label="enquiry" hidden>
                  Enquiry:{" "}
                </label>
                <input
                  id="enquiry"
                  name="contactEnquiry"
                  onChange={handleChange}
                  value={formData.contactEnquiry}
                  placeholder="Enquiry"
                  required
                  style={{
                    width: "100%",
                    height: 40,
                    padding: 10,
                    borderRadius: 999,
                    outline: "none",
                    border: "1px solid rgba(0, 0, 0, .09)",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  }}
                />
              </div>
              <div>
                <label htmlFor="phone" aria-label="phone" hidden>
                  Phone:{" "}
                </label>
                <input
                  id="phone"
                  name="contactPhone"
                  onChange={handleChange}
                  value={formData.contactPhone}
                  placeholder="Phone"
                  required
                  style={{
                    width: "100%",
                    height: 40,
                    padding: 10,
                    borderRadius: 999,
                    outline: "none",
                    border: "1px solid rgba(0, 0, 0, .09)",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  }}
                />
              </div>
              <div>
                <label htmlFor="message" aria-label="message" hidden>
                  Message:{" "}
                </label>
                <textarea
                  name="contactMessage"
                  onChange={handleChange}
                  value={formData.contactMessage}
                  required
                  placeholder="Message"
                  style={{
                    resize: "none",
                    width: "100%",
                    borderRadius: 10,
                    outline: "none",
                    border: "1px solid rgba(0, 0, 0, .09)",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                    padding: 10,
                  }}
                ></textarea>
              </div>
              <button
                type="submit"
                className="white b-600 services-button"
                style={{
                  textDecoration: "none",
                  display: "inline-block",
                  borderRadius: 99,
                  paddingBlock: 10,
                  paddingInline: 20,
                  border: "1px solid currentColor",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </form>
          </div>
          <div>
            <h3
              className="mb-10"
              style={{ fontSize: "1.2rem", fontWeight: 600, color: "#555555" }}
            >
              Quick Links
            </h3>
            <div
              className="quick-links"
              style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
              <div>
                <Link
                  to="/prayer-requests"
                  className="white b-600 services-button"
                  style={{
                    textDecoration: "none",
                    display: "inline-block",
                    borderRadius: 99,
                    paddingBlock: 10,
                    paddingInline: 20,
                    textTransform: "uppercase",
                  }}
                >
                  Prayer Support
                </Link>
              </div>
              <div>
                <Link
                  to="/"
                  className="white b-600 services-button"
                  style={{
                    textDecoration: "none",
                    display: "inline-block",
                    borderRadius: 99,
                    paddingBlock: 10,
                    paddingInline: 20,
                    textTransform: "uppercase",
                  }}
                >
                  Join a disciple group
                </Link>
              </div>
              <div>
                <Link
                  to="/whatsapp"
                  className="white b-600 services-button"
                  style={{
                    textDecoration: "none",
                    display: "inline-block",
                    borderRadius: 99,
                    paddingBlock: 10,
                    paddingInline: 20,
                    textTransform: "uppercase",
                  }}
                >
                  Join our telegram & whatsapp group
                </Link>
              </div>
            </div>
          </div>
        </section>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default ContactUs;

import { useState } from "react";
import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import { Helmet } from "react-helmet";

const GettingToBMC = () => {
  const [isActive1, setIsActive1] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);

  return (
    <MainLayout>
      <main>
        <h1
          className="mt-10 mb-20"
          style={{ color: "#555555", fontSize: "1.2rem", fontWeight: 600 }}
        >
          <Helmet>
            <title>Getting to BMC - Bedok Methodist Church</title>
          </Helmet>
          Getting to BMC
        </h1>
        <nav>
          <ul
            className="getting-to-bmc-nav"
            style={{ listStyle: "none", display: "flex", gap: 2 }}
          >
            <li
              className={`${isActive1 ? "active" : ""}`}
              onClick={() => {
                setIsActive1(true);
                setIsActive2(false);
                setIsActive3(false);
              }}
            >
              PUBLIC TRANSPORT
            </li>
            <li
              className={`${isActive2 ? "active" : ""}`}
              onClick={() => {
                setIsActive2(true);
                setIsActive1(false);
                setIsActive3(false);
              }}
            >
              DRIVE
            </li>
            <li
              className={`${isActive3 ? "active" : ""}`}
              onClick={() => {
                setIsActive3(true);
                setIsActive2(false);
                setIsActive1(false);
              }}
            >
              BMC SUNDAY BUS SERVICE
            </li>
          </ul>

          <div
            style={{
              border: "1px solid #ddd",
              backgroundColor: "#FFFFFF",
              padding: 20,
            }}
          >
            <div style={isActive1 ? {} : { display: "none" }}>
              <div style={{ color: "#555555" }}>
                <p className="mb-10">
                  Nearest MRT Station: Tanah Merah (EW4/CG)
                </p>
                <p>Buses: 10, 14, 45, 10E, 531</p>
              </div>

              <iframe
                className="mt-30"
                title="Map to BMC"
                src="https://gothere.sg/maps#q:bedok%20methodist%20church,loc:1.323844%2C103.953848,z:18"
                style={{ width: "100%", height: 500 }}
                frameBorder={0}
              ></iframe>
            </div>

            <div
              style={isActive2 ? { color: "#555555" } : { display: "none" }}
              className="mt-30"
            >
              <div className="getting-to-bmc-info">
                <div
                  className="mb-20"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    lineHeight: 1.5,
                  }}
                >
                  <p>
                    There is limited parking available within the church
                    premises. Car park ushers (BMC members in vests) will be
                    stationed at strategic points to facilitate traffic flow.{" "}
                    <strong style={{ fontWeight: "bold" }}>
                      Please give them your full co-operation for everyone to
                      have a pleasant experience.
                    </strong>
                  </p>
                  <p>
                    We are working towards making BMC a safe place of worship. A{" "}
                    <strong style={{ fontWeight: "bold" }}>
                      fire engine access way
                    </strong>{" "}
                    has been drawn in front of the church driveway, in adherence
                    to BMC Secure and SCDF’s Fire Safety requirements.{" "}
                    <strong style={{ fontWeight: "bold" }}>
                      This access way has to be kept clear at all times.
                    </strong>
                  </p>
                  <p>
                    Please{" "}
                    <strong style={{ fontWeight: "bold" }}>do not</strong> park
                    in the areas indicated by the{" "}
                    <strong style={{ fontWeight: "bold" }}>
                      red dotted lines
                    </strong>{" "}
                    in the pictures below.
                  </p>
                </div>
                <div>
                  <img
                    src="bmc_park1.jpeg"
                    alt="No parking spot 1"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div
                className="mt-20 getting-to-bmc-info"
                style={{
                  display: "flex",

                  gap: 20,
                }}
              >
                <div>
                  <img
                    src="bmc_park2.jpg"
                    alt="No parking spot 2"
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <img
                    src="bmc_park3.jpg"
                    alt="No parking spot 3"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div className="mt-20 getting-to-bmc-info">
                <div
                  className="mb-20"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    lineHeight: 1.5,
                  }}
                >
                  <p>
                    On Sundays, please park only along Eastwood Road between 8am
                    to 5pm as indicated on the map here.
                  </p>
                  <p>
                    Left side of Taman Bedok towards Pesari Walk, between
                    <strong style={{ fontWeight: "bold", marginLeft: 4 }}>
                      lamp post 23F to 27F.
                    </strong>
                  </p>
                  <p>
                    Left side of Eastwood Road towards Eastwood Centre
                    direction, between
                    <strong style={{ fontWeight: "bold", marginLeft: 4 }}>
                      lamp post 1 to 5 and lamp post 16 to 17.
                    </strong>
                  </p>
                  <p>
                    Paid parking is available at Bedok Corner Food Centre public
                    car park and Eastwood Centre basement car park.
                  </p>
                  <p>
                    Please be considerate to the residents and other road users
                    in these areas.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Non-compliance will be subject to action by LTA.
                  </p>
                </div>
                <div>
                  <img
                    src="CarPark_Areas.jpeg"
                    alt="Map of Car Park Areas"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>

            <div style={isActive3 ? {} : { display: "none" }}>
              <div>
                <img
                  src="bmc_bus_service.jpeg"
                  style={{ width: "80%", margin: "auto" }}
                  alt="Shuttle Bus Timetable"
                />
              </div>
            </div>
          </div>
        </nav>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default GettingToBMC;

import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const OnlineServices = () => {
  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Online Services - Bedok Methodist Church</title>
        </Helmet>
        <div
          className="mb-30"
          style={{
            backgroundColor: "#0094b3",
            mixBlendMode: "multiply",
            padding: 20,
            position: "relative",
          }}
        >
          <h1
            style={{
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              textShadow: "2px 2px 4px #000000",
              fontSize: "1.5rem",
            }}
          >
            OUR SUNDAY WORSHIP SERVICES
          </h1>
        </div>
        <div className="online-services">
          <div style={{ cursor: "pointer" }}>
            <Link to="/traditional">
              <img
                src="LIVE_Traditional.jpg"
                alt="Live Traditional"
                style={{ width: "100%" }}
              />
            </Link>
          </div>
          <div>
            <img
              src="Contemporary.jpg"
              alt="Contemporary"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ cursor: "pointer" }}>
            <Link to="/chinese">
              <img
                src="LIVE_Chinese.jpg"
                alt="Live Chinese"
                style={{ width: "100%" }}
              />
            </Link>
          </div>
        </div>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default OnlineServices;

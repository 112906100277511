import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import AdminEventsNavbar from '../AdminEventsNavbar';
import Switch from "react-switch";
import Select from 'react-select'
import { MdAdd, MdCancel, MdDelete } from 'react-icons/md';
import FileUploadField from '../FileUploadField';

export default function EventsAccommodation() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let event_id = new URLSearchParams(window.location.search).get("event_id")
  let selectedItem = new URLSearchParams(window.location.search).get("state") ? new URLSearchParams(window.location.search).get("state") : "general"

  const [eventData, setEventData] = useState({
    name: "",
    description: "",
    event_start_datetime: "",
    event_end_datetime: "",
    venue: "",
    price: "0",
    is_unlimited_capacity: "0",
    capacity: "100",
    has_accommodation: "0",
    has_transportation: "0",
    accommodation_id: "",
    transportation_id: "",
    registration_start_datetime: "",
    registration_end_datetime: "",
  })

  const [accommodations, setAccommodations] = useState([]) //
  const [selectedAccommodation, setSelectedAccommodation] = useState(null)


  const fetchAccommodations = async () => {
    if (!event_id) return
    let endpoint = `${config.endpoint}/events/accommodations/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setAccommodations(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }


  const saveAccommodation = async (accommodation) => {
    setAccommodations([])
    let endpoint = `${config.endpoint}/events/accommodations/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...accommodation,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      fetchAccommodations()
      setSelectedAccommodation(null)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateAccommodation = async (accommodation) => {
    if (!event_id || !accommodation?.id) return
    let endpoint = `${config.endpoint}/events/accommodations/id/${event_id}/${accommodation.id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...accommodation,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      fetchAccommodations()
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteAccommodation = async (accommodation_id) => {
    if (!event_id || !accommodation_id) return
    let endpoint = `${config.endpoint}/events/accommodations/id/${event_id}/${accommodation_id}`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      fetchAccommodations()
    } else {
      notify({ type: "error", message: data.message })
    }
  }



  const fetchEventById = async () => {
    if (!event_id || event_id === "new") return
    let endpoint = `${config.endpoint}/events/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setEventData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchEventById()
    fetchAccommodations()
  }, [])


  const createEvent = async () => {
    let endpoint = `${config.endpoint}/events/`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...eventData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/events/details?event_id=" + data.data.id)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateEvent = async () => {
    if (!event_id) return
    let endpoint = `${config.endpoint}/events/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...eventData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteEvent = async () => {
    if (!event_id) return
    let endpoint = `${config.endpoint}/events/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/events")
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const variantModel = {
    name: "Adults",
    min_age: 13,
    limit: 2
  }

  return (
    <div className="row gap-10 alignStart">
      <div className="w-100">
        <div className="column alignEnd right mb-20">
          <button className="button row gap-10 alignCenter"
            onClick={() => {
              // Check if the whole field is filled in
              if (accommodations.length > 0) {
                let lastAccommodation = accommodations[accommodations.length - 1]
                if (lastAccommodation.name === "" || lastAccommodation.description === "" || lastAccommodation.limits === "" || lastAccommodation.occupants.length === 0) {
                  notify({ type: "error", message: "Please fill in the accommodation details" })
                  return
                }

                for (let i = 0; i < lastAccommodation.occupants.length; i++) {
                  let occupant = lastAccommodation.occupants[i]
                  if (occupant.name === "" || occupant.min_age === "" || occupant.price === "") {
                    notify({ type: "error", message: "Please fill in the occupant details" })
                    return
                  }
                }
              }


              let temp = [...accommodations]
              temp.push({
                name: "",
                description: "",
                event_id: event_id,
                occupants: [],
                is_unlimited_capacity: "0",
                media_asset_id: "",
                limits: "0",
                is_unlimited: "0",
                allow_extra_beddings: "0",
                extra_bedding_price: "0",
                extra_bedding_limit: "0",
              })
              setAccommodations(temp)
              setSelectedAccommodation(temp.length - 1)

              // save the last accommodation
              // if (accommodations.length > 0) {
              //   let lastAccommodation = accommodations[accommodations.length - 1]
              //   if (lastAccommodation.name === "" || lastAccommodation.description === "" || lastAccommodation.limits === "" || lastAccommodation.occupants.length === 0) {
              //     notify({ type: "error", message: "Please fill in the accommodation details" })
              //     return
              //   }

              //   for (let i = 0; i < lastAccommodation.occupants.length; i++) {
              //     let occupant = lastAccommodation.occupants[i]
              //     if (occupant.name === "" || occupant.min_age === "" || occupant.price === "" || occupant.limit === "") {
              //       notify({ type: "error", message: "Please fill in the occupant details" })
              //       return
              //     }
              //   }
              //   saveAccommodation(lastAccommodation)
              // }
            }}
          >
            <MdAdd size={15} />
            Accommodation
          </button>
        </div>
        <div className="">
          {/* <p className="card-title">Accommodations</p> */}
          {accommodations.length > 0 ? accommodations.map((accommodation, index) => {
            if (selectedAccommodation === index) {
              return <div className="card mb-10">
                <p className="card-title mb-20">{accommodation?.id ? "Update Accommodation" : "Add Accommodation"}</p>
                {accommodation?.id && <FileUploadField
                  upload_url={`${config.endpoint}/file/event/accommodation/id/${accommodation.id}`}
                  text={"Upload Accommodation Image"}
                  subtext={"Upload an image for the accommodation"}
                  max_file_size={2}
                  max_files={1}
                  onChange={(file) => { }}
                />}
                <div>
                  <div className="row gap-10 alignCenter">
                    <div className="input-container">
                      <label htmlFor="name" className='input-label'>Name</label>
                      <input type="text" id="name" className='text-input' value={accommodation.name}
                        onChange={(e) => {
                          let temp = [...accommodations]
                          temp[index].name = e.target.value
                          setAccommodations(temp)
                        }}
                      />
                    </div>
                    <div className="input-container">
                      <label htmlFor="limits" className='input-label'>Total Available</label>
                      <input type="text" id="limits" className='text-input' value={accommodation.limits}
                        onChange={(e) => {
                          if (isNaN(e.target.value)) return
                          if (e.target.value < 0) return
                          let temp = [...accommodations]
                          temp[index].limits = e.target.value
                          setAccommodations(temp)
                        }}
                      />
                    </div>
                  </div>
                  <div className="input-container">
                    <label htmlFor="description" className='input-label'>Description</label>
                    <textarea id="description" className='textarea-input' value={accommodation.description}
                      onChange={(e) => {
                        let temp = [...accommodations]
                        temp[index].description = e.target.value
                        setAccommodations(temp)
                      }}
                    />
                  </div>
                  <div className="row gap-10 alignCenter mt-20 justifySplit">
                    <label htmlFor="" className="input-label b">Occupant Types</label>
                    <button className="backgroundLess row alignCenter gap-10" style={{ fontSize: 12 }}
                      onClick={() => {
                        let temp = [...accommodations]
                        let occupants = [...temp[index].occupants]
                        occupants.push({
                          name: "Adults",
                          min_age: 13,
                          price: 100,
                          limit: 2
                        })
                        temp[index].occupants = occupants
                        setAccommodations(temp)
                      }}
                    >
                      <MdAdd size={15} />
                      Occupant
                    </button>
                  </div>
                  {accommodation.occupants.length > 0 ? accommodation.occupants.map((occupant, oIndex) => {
                    return (
                      <div className="mb-10">
                        {/* Occupants - Price */}
                        <div className="row gap-10 alignCenter">
                          <div className="input-container" style={{ minWidth: 300 }}>
                            <label htmlFor="name" className='input-label'>Name</label>
                            <input type="text" id="name" className='text-input' value={occupant.name}
                              onChange={(e) => {
                                let temp = [...accommodations]
                                let occupants = [...temp[index].occupants]
                                occupants[oIndex].name = e.target.value
                                temp[index].occupants = occupants
                                setAccommodations(temp)
                              }}
                            />
                          </div>
                          <div className="input-container" style={{ minWidth: 0 }}>
                            <label htmlFor="min_age" className='input-label'>Min Age</label>
                            <input type="number" id="min_age" className='text-input' value={occupant.min_age}
                              onChange={(e) => {
                                let temp = [...accommodations]
                                let occupants = [...temp[index].occupants]
                                occupants[oIndex].min_age = e.target.value
                                temp[index].occupants = occupants
                                setAccommodations(temp)
                              }}
                            />
                          </div>
                          <div className="input-container" style={{ minWidth: 0 }}>
                            <label htmlFor="price" className='input-label'>Price</label>
                            <input type="number" id="price" className='text-input' value={occupant.price}
                              onChange={(e) => {
                                let temp = [...accommodations]
                                let occupants = [...temp[index].occupants]
                                occupants[oIndex].price = e.target.value
                                temp[index].occupants = occupants
                                setAccommodations(temp)
                              }}
                            />
                          </div>
                          <button className="backgroundLess" style={{ fontSize: 12, minWidth: 20 }}
                            onClick={() => {
                              let temp = [...accommodations]
                              let occupants = [...temp[index].occupants]
                              occupants.splice(oIndex, 1)
                              temp[index].occupants = occupants
                              setAccommodations(temp)
                            }}
                          >
                            <MdDelete size={15} />
                          </button>
                        </div>
                      </div>
                    )
                  })
                    :
                    <p className="gray small text-center mt-10 mb-20">Select the type of occupants allowed</p>
                  }
                </div>

                <label htmlFor="" className="input-label b ">Extra Beddings</label>
                <div className="row gap-10 alignCenter">
                  <div className="row gap-10 alignCenter">
                    <label htmlFor="allow_extra_beddings" style={{ whiteSpace: "nowrap" }} className='input-label'>Allow Extra Beddings</label>
                    <Switch id="allow_extra_beddings" checked={accommodation.allow_extra_beddings == "1"} onChange={(checked) => {
                      let temp = [...accommodations]
                      temp[index].allow_extra_beddings = checked ? "1" : "0"
                      setAccommodations(temp)
                    }} />
                  </div>
                  <div className="input-container">
                    <label htmlFor="extra_bedding_price" className='input-label'>Price</label>
                    <input type="number" id="extra_bedding_price" className='text-input' value={accommodation.extra_bedding_price}
                      onChange={(e) => {
                        let temp = [...accommodations]
                        temp[index].extra_bedding_price = e.target.value
                        setAccommodations(temp)
                      }}
                    />
                  </div>
                  <div className="input-container">
                    <label htmlFor="extra_bedding_limit" className='input-label'>Max per room</label>
                    <input type="number" id="extra_bedding_limit" className='text-input' value={accommodation.extra_bedding_limit}
                      onChange={(e) => {
                        let temp = [...accommodations]
                        temp[index].extra_bedding_limit = e.target.value
                        setAccommodations(temp)
                      }}
                    />
                  </div>
                </div>

                {/* Grouping - Limits */}
                <div className="row gap-10 alignCenter mt-20 justifySplit">
                  <div>
                    <label htmlFor="" className="input-label b">Variations</label>
                    <p htmlFor="" className="gray small">To Calculate the limits</p>
                  </div>
                  <button className="backgroundLess row alignCenter gap-10" style={{ fontSize: 12 }}
                    onClick={() => {
                      let temp = [...accommodations]
                      let groupings = accommodations[selectedAccommodation]?.groupings ? [...accommodations[selectedAccommodation].groupings] : [{ ...variantModel }]
                      let tempGroup = [...groupings]
                      tempGroup.push([{ ...variantModel }])
                      temp[selectedAccommodation].groupings = tempGroup

                      setAccommodations(temp)
                    }}
                  >
                    <MdAdd size={15} />
                    Group
                  </button>
                </div>
                {/* <pre>{JSON.stringify({ groupings: accommodation?.groupings }, null, 2)}</pre> */}
                {accommodation?.groupings?.length > 0 ? accommodation.groupings.map((group, gIndex) => {
                  return (
                    <div
                      style={{ borderBottom: gIndex + 1 == accommodation?.groupings?.length ? "" : "2px solid #E5E5E5", padding: 10, marginTop: 10 }}
                    >
                      <div className="row gap-10 alignCenter justifySplit">
                        <p className="b mt-10" style={{ fontSize: 12 }}>Group {gIndex + 1}</p>
                        <div className="row gap-10 alignCenter">
                          <button className="backgroundLess row gap-10 alignCenter" style={{ fontSize: 12 }}
                            onClick={() => {
                              let temp = [...accommodations]
                              let groupings = [...temp[index].groupings]
                              let tempGroup = [...groupings[gIndex]]
                              tempGroup.push({ ...variantModel })
                              groupings[gIndex] = tempGroup
                              temp[index].groupings = groupings
                              setAccommodations(temp)
                            }}
                          >
                            <MdAdd size={15} />
                            Variant
                          </button>
                          <button className="backgroundLess"
                            onClick={() => {
                              let temp = [...accommodations]
                              let groupings = [...temp[index].groupings]
                              groupings.splice(gIndex, 1)
                              temp[index].groupings = groupings
                              setAccommodations(temp)
                            }}
                          >
                            <MdDelete size={15} />
                          </button>
                        </div>
                      </div>

                      {group?.length > 0 && group.map((g, hIndex) => {
                        return (
                          <div className="row gap-10 alignCenter">
                            <div className="input-container" style={{ minWidth: 300 }}>
                              <label htmlFor="name" className='input-label'>Name</label>
                              <input type="text" id="name" className='text-input' value={g.name}
                                onChange={(e) => {
                                  let temp = [...accommodations]
                                  let groupings = [...temp[index].groupings]
                                  let tempGroup = [...groupings[gIndex]]
                                  tempGroup[hIndex].name = e.target.value
                                  groupings[gIndex] = tempGroup
                                  temp[index].groupings = groupings
                                  setAccommodations(temp)
                                }}
                              />
                            </div>
                            <div className="input-container" style={{ minWidth: 0 }}>
                              <label htmlFor="min_age" className='input-label'>Min Age</label>
                              <input type="number" id="min_age" className='text-input' value={g.min_age}
                                onChange={(e) => {
                                  let temp = [...accommodations]
                                  let groupings = [...temp[index].groupings]
                                  let tempGroup = [...groupings[gIndex]]
                                  tempGroup[hIndex].min_age = e.target.value
                                  groupings[gIndex] = tempGroup
                                  temp[index].groupings = groupings
                                  setAccommodations(temp)
                                }}
                              />
                            </div>
                            <div className="input-container" style={{ minWidth: 0 }}>
                              <label htmlFor="limit" className='input-label'>Limit</label>
                              <input type="number" id="limit" className='text-input' value={g.limit}
                                onChange={(e) => {
                                  let temp = [...accommodations]
                                  let groupings = [...temp[index].groupings]
                                  let tempGroup = [...groupings[gIndex]]
                                  tempGroup[hIndex].limit = e.target.value
                                  groupings[gIndex] = tempGroup
                                  temp[index].groupings = groupings
                                  setAccommodations(temp)
                                }}
                              />
                            </div>
                            <button className="backgroundLess" style={{ fontSize: 12, minWidth: 20 }}
                              onClick={() => {
                                let temp = [...accommodations]
                                let groupings = [...temp[index].groupings]
                                let tempGroup = [...groupings[gIndex]]
                                tempGroup.splice(hIndex, 1)
                                groupings[gIndex] = tempGroup
                                temp[index].groupings = groupings
                                setAccommodations(temp)
                              }}
                            >
                              <MdCancel size={15} />
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  )
                })
                  :
                  <p className="gray small text-center mt-10 mb-20">No groupings created yet, click the button above to add a group</p>
                }



                {/* Button Groups */}
                <div className="column alignEnd right w-100">
                  <div className="row gap-10 alignCenter">
                    <button className="button-delete"
                      onClick={() => {
                        deleteAccommodation(accommodation.id)
                      }}
                    >Delete</button>
                    <button className="button"
                      onClick={() => {
                        if (accommodation.id) {
                          updateAccommodation(accommodation)
                        } else {
                          saveAccommodation(accommodation)
                        }
                      }}
                    >Save</button>
                  </div>
                </div>
              </div>
            } else {
              return <div className="card mb-10">
                <div className="row gap-10 alignCenter justifySplit">
                  <div>
                    <p className="card-title">{accommodation.name}</p>
                    <p className="gray small">{accommodation.description}</p>
                  </div>
                  <div className="row gap-10 alignCenter">
                    <button className="button"
                      onClick={() => {
                        setSelectedAccommodation(index)
                      }}
                    >View</button>
                  </div>
                </div>
              </div>
            }
          })
            :
            <p className="gray small text-center mt-10 mb-20">No rooms created yet, click the button above to add a room</p>
          }
        </div>
      </div>
    </div>
  )
}

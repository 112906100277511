import { useEffect, useState } from "react";
import config from "../app-config";
import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import { Helmet } from "react-helmet";

const SermonSeriesMatthew = () => {
  const [sermonSeries, setSermonSeries] = useState([]);

  useEffect(() => {
    const fetchSermonSeries = async () => {
      const res = await fetch(`${config.endpoint}/services/all`);
      const data = await res.json();
      setSermonSeries(data.data);
    };

    fetchSermonSeries();
  }, []);

  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>The Gospel of Matthew - Bedok Methodist Church</title>
        </Helmet>
        <div style={{ position: "relative" }}>
          <div style={{ backgroundColor: "#ffffff", opacity: 0.7 }}>
            <img
              src="/matthew-sermon-series.jpeg"
              alt="Opened Bible"
              style={{
                maxHeight: 350,
                width: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            className=""
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "fit-content",
              bottom: 0,
              marginBlock: "auto",
              padding: 20,
              textAlign: "end",
            }}
          >
            <h1 style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
              THE GOSPEL OF MATTHEW
            </h1>
            <p
              style={{ fontWeight: "bold", fontSize: "1.2rem", marginTop: 10 }}
            >
              Sermon Series (27 Jun – 28 Nov)
            </p>
            <a
              href="https://bmc.org.sg/wp-content/uploads/BMC-Devotions-on-Gospel-of-Matthew_2021.pdf"
              target="_blank"
              rel="noreferrer"
              className="sermon-series-matthew-btn"
              style={{
                display: "inline-block",
                paddingBlock: 10,
                paddingInline: 20,
                color: "#ffffff",
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              DOWNLOAD DEVOTION
            </a>
          </div>
        </div>

        <div>
          <h1
            className="mt-30"
            style={{ color: "#555555", fontWeight: "bold", fontSize: "1.5rem" }}
          >
            Sermon Series
          </h1>
          <div
            className="mt-20 sermon-series"
            style={{
              background: "#ffffff",
              padding: 20,
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            {sermonSeries.slice(0, 5).map((series) => {
              const formattedDate = new Date(series.createdAt).toLocaleString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );

              const title = "The Gospel of Matthew";
              // series.sermon_outline
              //   .split(
              //     '<p><span style="font-size: 14px;">I. Introduction</span></p><p><span style="font-size: 14px;"><br></span></p><p><span style="font-size: 14px;">'
              //   )[1]
              //   .split("-")[0];
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#555555",
                    fontWeight: "bold",
                    alignItems: "center",
                  }}
                >
                  <p>{formattedDate}</p>
                  <a
                    className="sermon-series-vid-link"
                    href={series.video_link}
                  >
                    {title}
                  </a>
                  <p>Rev Dr Aaron Tay</p>
                  {/* <p>{ series.name}</p> */}
                  <img
                    width="50"
                    src="https://bmc.org.sg/wp-content/uploads/Notes1.jpg"
                    alt="notepad"
                  />
                </div>
              );
            })}
          </div>
        </div>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default SermonSeriesMatthew;

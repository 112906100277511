import React, { useEffect, useState } from 'react'
import { MdUploadFile } from 'react-icons/md'
import notify from '../../utils/IANotification'
import config from '../../app-config'

export default function WebComponent({ id, readOnly, data, onChangeFile, onChangeUrl }) {

    return (
        <>
            {
                readOnly ?
                    <>
                        <iframe src={data?.content} alt="" style={{ width: 600, height: 300, objectFit: "contain" }} />
                    </>
                    :
                    <>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">URL</label>
                            <input type="text" className="text-input" placeholder="Enter URL"
                                value={data.content}
                                onChange={(e) => {
                                    onChangeUrl(e.target.value)
                                }} />
                        </div>
                    </>
            }
        </>
    )
}

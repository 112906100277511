import React, { useState, useEffect } from 'react'
import AdminNavbar from '../components/AdminNavbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function AdminLayout({ children, navSelected, regularContainer, toolbar }) {
    const navigate = useNavigate()
    const { isAuthorised, userRole } = useFetchAuthorisation("user")

    return (
        <div style={{ width: "100vw", }}>
            <ToastContainer />
            <div className='row' style={{ width: "100%" }}>
                <AdminNavbar selected={navSelected} />
                <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll", }}>
                    <UserHeader />
                    {toolbar}
                    {!regularContainer ?
                        <div className="container">
                            <div className="inner-container">
                                {children}
                            </div>
                        </div>

                        :
                        <div className="reg-container">
                            {children}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

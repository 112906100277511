import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd, MdDelete } from 'react-icons/md';
import { tidyDate, tidyDateRaw, tidyFrequencyName } from '../../utils/helper';
import Select from 'react-select'
import Switch from 'react-switch'
import { FaPray, FaPrayingHands } from 'react-icons/fa';
import notify from '../../utils/IANotification';

export default function PrayerFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let prayer_list_id = new URLSearchParams(window.location.search).get("prayer_list_id")

  const [prayerListData, setPrayerListData] = useState({
    type: "corporate",
    title: "",
    description: "",
    is_archived: "0",
  })
  const [prayers, setPrayers] = useState([]) // {content: "", is_archived: "0", is_answered: "0", user_id: "", prayer_list_id: "", can_repeat: "0", prayers_count: [], testimony: ""}
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchPrayerListById()
  }, [])

  const fetchPrayerListById = async () => {
    if (prayer_list_id === "new" || !prayer_list_id) {
      setLoading(false)
      return
    }
    setLoading(true)
    let endpoint = config.endpoint + "/prayer/list/id/" + prayer_list_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setPrayerListData(data.data?.prayer_list)
      setPrayers(data.data.prayers)
    }
    setLoading(false)
  }

  const savePrayerList = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/prayer/list"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...prayerListData,
        prayers
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      setPrayerListData(data.data)
      navigate("/admin/prayer/details?prayer_list_id=" + data.data.id)
      notify({type: "success", message: "Saved prayer list"})
    }else{
      notify({type: "error", message: "Failed to save prayer list"})
    }
    setLoading(false)
  }

  const updatePrayerList = async () => {
    setLoading(true)
    if (prayer_list_id === "new" || !prayerListData.id) {
      savePrayerList()
      return
    }
    if (!prayer_list_id) {
      return
    }
    let endpoint = config.endpoint + "/prayer/list/id/" + prayer_list_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...prayerListData,
        prayers
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchPrayerListById()
      notify({type: "success", message: "Updated prayer list"})
    }else{
      notify({type: "error", message: "Failed to update prayer list"})
    }
    setLoading(false)
  }

  const deletePrayerList = async () => {
    setLoading(true)
    if (prayer_list_id === "new" || !prayerListData.id || !prayer_list_id) {
      return
    }
    let endpoint = config.endpoint + "/prayer/list/id/" + prayer_list_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setPrayerListData(data.data)
      navigate(-1)
    }else{
      notify({type: "error", message: "Failed to delete prayer list"})
    }
    setLoading(false)
  }






  return (
    <AdminLayout
      navSelected={"Corporate Prayer"}
    >
      <div className="card">
        <p className="card-title">Prayer List</p>
        <div className="input-container">
          <label htmlFor="" className="input-label">Title</label>
          <input type="text" className="text-input" placeholder='e.g. BMC Family Camp' value={prayerListData.title} onChange={(e) => setPrayerListData({ ...prayerListData, title: e.target.value })} />
        </div>
        <div className="input-container">
          <label htmlFor="" className="input-label">Description</label>
          <textarea className="textarea-input" value={prayerListData.description} placeholder='e.g. Prayers for the upcoming BMC Family Camp at Meleka' onChange={(e) => setPrayerListData({ ...prayerListData, description: e.target.value })} />
        </div>
        <div className="column alignEnd">
          <div className="mt-10 row gap-10 alignCenter">
            {prayer_list_id !== "new" && <button className="button-delete"
              onClick={() => deletePrayerList()}
            >Delete</button>}
            <button className="button"
              onClick={() => {
                if (prayer_list_id === "new") {
                  savePrayerList()
                } else {
                  updatePrayerList()
                }

              }}
            >Save</button>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(prayerListData,null,2)}</pre>
      <pre>{JSON.stringify(prayers,null,2)}</pre> */}
      <div className="mt-10 card">
        <div className="row justifySplit alignCenter">
          <p className="card-title">Prayers</p>
          <button className="row gap-10 alignCenter backgroundLess"
            onClick={() => setPrayers([...prayers, { content: "", is_archived: "0", is_answered: "0", user_id: "", prayer_list_id: "", can_repeat: "0", prayers_count: [], testimony: "" }])}
          >
            <MdAdd size={20} />
            <p>Add Prayer</p>
          </button>
        </div>
        {prayers.length > 0 && prayers.map((prayer, index) => {
          return (
            <div key={index} style={{ borderBottom: index != prayer?.length - 1 && "2px solid #f0f0f0", padding: 10 }}>
              <div className="row alignCenter justifySplit">
                <p className='card-title'>Prayer #{index + 1}</p>
                <div className="row gap-10 alignCenter">
                  <div className="row gap-10 alignCenter">
                    <FaPrayingHands size={20} color='gray' />
                    <p className='gray'>{prayer.prayers_count?.length}</p>
                  </div>
                  <button className="backgroundLess gap-10 alignCenter row button-delete"
                    onClick={() => {
                      let newPrayers = [...prayers]
                      newPrayers.splice(index, 1)
                      setPrayers(newPrayers)
                    }}
                  >
                    <MdDelete size={20} />
                    <p>Delete</p>
                  </button>
                </div>
              </div>
              <div className="row gap-10 alignStart mt-20">
                <div className="input-container">
                  <label htmlFor="" className="input-label">Content</label>
                  <textarea className="textarea-input" value={prayer.content} onChange={(e) => {
                    let newPrayers = [...prayers]
                    newPrayers[index].content = e.target.value
                    setPrayers(newPrayers)
                  }} />
                </div>
                <div className="row gap-10 alignCenter">
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Answered</label>
                    <Switch
                      checked={prayer.is_answered == "1"}
                      onChange={(checked) => {
                        let newPrayers = [...prayers]
                        newPrayers[index].is_answered = checked ? "1" : "0"
                        setPrayers(newPrayers)
                      }}
                    />
                  </div>
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Repeatable</label>
                    <Switch
                      checked={prayer.can_repeat == "1"}
                      onChange={(checked) => {
                        let newPrayers = [...prayers]
                        newPrayers[index].can_repeat = checked ? "1" : "0"
                        setPrayers(newPrayers)
                      }}
                    />
                  </div>
                </div>
              </div>
              {prayer.is_answered == "1" && (
                <div className="input-container">
                  <label htmlFor="" className="input-label">Testimony</label>
                  <textarea className="textarea-input" value={prayer.testimony} onChange={(e) => {
                    let newPrayers = [...prayers]
                    newPrayers[index].testimony = e.target.value
                    setPrayers(newPrayers)
                  }} />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </AdminLayout>
  )
}

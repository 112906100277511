import React, { useLayoutEffect, useState } from 'react'
import { MdAppBlocking, MdChurch, MdClass, MdFlag, MdFlagCircle, MdGroup, MdHome, MdPerson, MdPerson3, MdPersonPin, MdPolicy, MdSettings, MdVpnLock } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { TbReport } from "react-icons/tb";
import { IoDocumentsOutline, IoEaselOutline, IoHomeOutline, IoJournalOutline, IoLibraryOutline, IoMegaphone, IoMegaphoneOutline, IoNewspaperOutline, IoPeopleOutline, IoSettingsOutline } from "react-icons/io5";

export default function AdminSettingsNavbar({ selected }) {

    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("admin-header")?.clientHeight + 1)

    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("admin-header")?.clientHeight + 1)
    }, [])

    const ICON_SIZE = 20

    const ITEMS = [
        {
            "name": "General",
            "state": "general",
            "link": `/admin/settings?state=general`,
            "icon": <MdHome size={ICON_SIZE} />,
        },
        // {
        //     "name": "Access Control",
        //     "state": "access",
        //     "link": `/admin/settings?state=access`,
        //     "icon": <MdVpnLock size={ICON_SIZE} />,
        // },
        {
            "name": "Policy",
            "state": "policy",
            "link": `/admin/settings?state=policy`,
            "icon": <MdPolicy size={ICON_SIZE} />,
        },
        {
            "name": "Contact Information",
            "state": "contact",
            "link": `/admin/settings?state=contact`,
            "icon": <MdPerson size={ICON_SIZE} />,
        },
        {
            "name": "Service Profile",
            "state": "service",
            "link": `/admin/settings?state=service`,
            "icon": <MdChurch size={ICON_SIZE} />,
        },
    ]
    return (
        <div id='course-nav' className='row gap-20 mb-20 alignCenter hide-scrollbars' style={{
            width: "100%",
            borderBottom: "1px solid #E5E5E5",
            position: "sticky",
            top: headerHeight,
            zIndex: 5,
            backgroundColor: "#eff1f6",
            marginTop: 10,
            marginBottom: 10,
            paddingTop: 10,
            overflowY: "auto",
        }}>
            {ITEMS.map((item, index) => {
                return (
                    <div className={`row gap-10 alignCenter pr-10 cursor-pointer`} style={{ borderBottom: selected === item.state ? "2px solid #0222FF" : "", }} onClick={() => {
                        window.location.href = item.link
                        selected = item.name
                    }}>
                        {item.icon}
                        <p style={{ fontSize: 12, fontWeight: 500 }}>{item.name}</p>
                    </div>
                )
            })}
        </div>
    )
}

const config = {
    endpoint: "https://backend.bmc.ourcodelab.com",
    local_url: "https://bmc.ourcodelab.com",
    endpoint_1: "https://backend.bmc.ourcodelab.com",
    version: "1.0.4 (300624)",
    developer: "OurCodeLab LLP",
    website: "https://ourcodelab.com",

    feature_flag: {
        allow_registration_web: false,
    }

}

export default config
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'

export default function CommerceProductsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [search, setSearch] = useState("")
  const [products, setProducts] = useState([
    {
      "id": 2,
      "linked_image_id": 2,
      "name": "example product",
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "price": "24",
      "quantity_available": 5,
      "available_from": "2024-03-18T00:00:00.000Z",
      "available_until": "2024-03-25T00:00:00.000Z",
      "is_available": "1",
      "is_deleted": "0",
      "createdAt": "2024-03-18T10:12:35.000Z",
      "updatedAt": "2024-03-18T10:12:35.000Z"
    },
    {
      "id": 3,
      "linked_image_id": 3,
      "name": "another product",
      "description": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "price": "32",
      "quantity_available": 10,
      "available_from": "2024-03-20T00:00:00.000Z",
      "available_until": "2024-03-30T00:00:00.000Z",
      "is_available": "1",
      "is_deleted": "0",
      "createdAt": "2024-03-18T10:15:42.000Z",
      "updatedAt": "2024-03-18T10:15:42.000Z"
    },
    {
      "id": 2,
      "linked_image_id": 2,
      "name": "example product",
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "price": "24",
      "quantity_available": 5,
      "available_from": "2024-03-18T00:00:00.000Z",
      "available_until": "2024-03-25T00:00:00.000Z",
      "is_available": "1",
      "is_deleted": "0",
      "createdAt": "2024-03-18T10:12:35.000Z",
      "updatedAt": "2024-03-18T10:12:35.000Z"
    },
    {
      "id": 3,
      "linked_image_id": 3,
      "name": "another product",
      "description": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "price": "32",
      "quantity_available": 10,
      "available_from": "2024-03-20T00:00:00.000Z",
      "available_until": "2024-03-30T00:00:00.000Z",
      "is_available": "1",
      "is_deleted": "0",
      "createdAt": "2024-03-18T10:15:42.000Z",
      "updatedAt": "2024-03-18T10:15:42.000Z"
    },
    {
      "id": 2,
      "linked_image_id": 2,
      "name": "example product",
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "price": "24",
      "quantity_available": 5,
      "available_from": "2024-03-18T00:00:00.000Z",
      "available_until": "2024-03-25T00:00:00.000Z",
      "is_available": "1",
      "is_deleted": "0",
      "createdAt": "2024-03-18T10:12:35.000Z",
      "updatedAt": "2024-03-18T10:12:35.000Z"
    },
    {
      "id": 4,
      "linked_image_id": 3,
      "name": "another product",
      "description": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "price": "32",
      "quantity_available": 10,
      "available_from": "2024-03-20T00:00:00.000Z",
      "available_until": "2024-03-30T00:00:00.000Z",
      "is_available": "1",
      "is_deleted": "0",
      "createdAt": "2024-03-18T10:15:42.000Z",
      "updatedAt": "2024-03-18T10:15:42.000Z"
    }
  ])

  useEffect(() => {
    //fetchProducts()
  }, [])

  const fetchProducts = async () => {
    let response = await fetch(`${config.endpoint}/commerce/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    })
    let data = await response.json()
    console.log(data)
    if (response.status === 200) {
      setProducts(data.data)
    } else {
      console.log("Error fetching products")
    }
  }

  const filteredProducts = () => {
    return products.filter(product => product.name.toLowerCase().includes(search.toLowerCase()))
  }

  return (
    <AdminLayout
      navSelected={"Home"}
    >
      <div className="button-container left mb-20">
        <button className="button justifyCenter">
          <p className="medium">Create New Product!</p>
        </button>
      </div>
      
      {/* Can isolate as component for use on user's page as well */}
      <input type="text" className="text-input w-100 mb-10 large" placeholder='Search for products here...' 
          onChange={e => setSearch(e.target.value)}/>
      <h1 className="medium" style={{ marginBottom:"5px" }}>Available Products:</h1>
      {filteredProducts().length === 0 
        ? <p className="normal">No products found</p>
        : filteredProducts().map((product, index) => {
          return (
            <div key={index} className="card mb-10 wrap-row">
              <img className="mr-10 header-link" src={`https://via.placeholder.com/150`} alt="product" onClick={() => navigate(`/admin/commerce/products/${product.id}`)}/>
              <div className="card-body paddingAll">
                <h1 className="medium header-link" onClick={() => navigate(`/admin/commerce/products/${product.id}`)}>{product.name}</h1>
                <p className="normal">${product.price}.00</p>
              </div>
            </div>
          )
        })
      }
    </AdminLayout>
  )
}

import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "../app-config";
import { Helmet } from "react-helmet";

const FeaturedItem = () => {
  const { item } = useParams();

  const endpoint = `${config.endpoint}/ministry`;
  const [ministries, setMinistries] = useState([]);

  useEffect(() => {
    const fetchAllMinistries = async () => {
      try {
        const res = await fetch(endpoint);
        const data = await res.json();
        if (data.status === 200) {
          const filteredItem = data.data.filter(
            (ministry) => ministry.name === item
          );
          setMinistries(filteredItem[0]);
        } else {
          throw new Error("Failed to retrive ministries");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllMinistries();
  }, [endpoint, item]);

  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>{ministries.name} - Bedok Methodist Church</title>
        </Helmet>
        <section
          className="featured-item"
          style={{ background: "#ffffff", padding: 20, borderRadius: 10 }}
        >
          <div
            style={{
              flex: 0.8,
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <p style={{ color: "#666666B3" }}>Connect</p>
            {/* <p>{ministries.type}</p> */}
            <h2
              style={{
                fontWeight: 600,
                color: "#555555",
                textTransform: "uppercase",
                fontSize: "1.5rem",
              }}
            >
              {ministries.name}
            </h2>
            <p style={{ color: "#777777", marginBottom: 10 }}>
              {ministries.description}
            </p>
          </div>
          <hr className="featured-item-hr" style={{ marginInline: 30 }} />
          <div style={{ flex: 1 }}>
            <img src="/bmc_img2.jpeg" alt="" style={{ borderRadius: 5 }} />
          </div>
        </section>
      </main>
      <SubFooter />
    </MainLayout>
  );
};

export default FeaturedItem;

import React from 'react'
import { MdAdd, MdClass, MdClose } from 'react-icons/md'

export default function ListComponent({ data, onChange, state, type, readonly, field_options }) {
    return (
        <>
            {state === "answer" &&
                <select className="select-input" style={{ width: "100%" }} value={data} readOnly={readonly} onChange={(e) => onChange(e.target.value)}>
                    {field_options.map((option, index) => {
                        return (
                            <option key={index} value={option}>{option}</option>
                        )
                    })}
                </select>
            }
            {state === "builder" &&
                <>
                    <div>
                        <button className="backgroundLess row gap-10 alignCenter"
                            onClick={() => {
                                let newData = [...field_options]
                                newData.push("")
                                onChange(newData)
                            }}
                        >
                            <MdAdd size={20} />
                            Option
                        </button>
                    </div>

                    {field_options.map((option, index) => {
                        return (
                            <div key={index} className="mt-10 row gap-10 alignCenter">
                                <p style={{ width: 50}}>{index + 1}</p>
                                <input type="text" className='text-input' value={option} onChange={(e) => {
                                    let newData = [...field_options]
                                    newData[index] = e.target.value
                                    onChange(newData)
                                }} />
                                <button className="backgroundLess row gap-10 alignCenter"
                                    onClick={() => {
                                        let newData = [...field_options]
                                        newData.splice(index, 1)
                                        onChange(newData)
                                    }}
                                >
                                    <MdClose size={20} />
                                </button>
                            </div>
                        )
                    })}
                </>
            }
        </>
    )
}

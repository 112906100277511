import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import JoditEditor from 'jodit-react';
import Switch from 'react-switch';
import { HexColorPicker } from "react-colorful";
import FileUploadField from '../../components/FileUploadField';


export default function SpecialServicesFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let special_service_id = new URLSearchParams(window.location.search).get("special_service_id")

  const [specialServiceData, setSpecialServiceData] = useState({
    name: "",
    description: "",
    tagline: "",
    media_asset_id: "",
    start_datetime: "",
    end_datetime: "",
    is_published: 0,
  })

  const fetchSpecialServiceById = async () => {
    if (!special_service_id || special_service_id == "new") return
    let endpoint = `${config.endpoint}/services/special/id/${special_service_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setSpecialServiceData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchSpecialServiceById()
  }, [])


  const createSpecialService = async () => {
    let endpoint = `${config.endpoint}/services/special/`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...specialServiceData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/special/services/details?special_service_id=" + data.data.id)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateSpecialService = async () => {
    if (!special_service_id) return
    let endpoint = `${config.endpoint}/services/special/id/${special_service_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...specialServiceData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteSpecialService = async () => {
    if (!special_service_id) return
    let endpoint = `${config.endpoint}/services/special/id/${special_service_id}`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/special/services")
    } else {
      notify({ type: "error", message: data.message })
    }
  }


  const editor = useRef(null);

  const placeholder = "Start typing...";

  const liveConfig = {
    height: 500,
    placeholder,
    // set custom buttons
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'superscript',
      'subscript',
      'ul',
      'ol',
      'outdent',
      'indent',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'table',
      'link',
      'undo',
      'redo',
      'eraser',
      'copyformat',
    ],
    askBeforePasteHTML: false,
    hotkeys: {
      redo: 'ctrl+z',
      undo: 'ctrl+y,ctrl+shift+z',
      indent: 'ctrl+]',
      outdent: 'ctrl+[',
      bold: 'ctrl+b',
      italic: 'ctrl+i',
      removeFormat: 'ctrl+shift+m',
      insertOrderedList: 'ctrl+shift+7',
      insertUnorderedList: 'ctrl+shift+8',
      openSearchDialog: 'ctrl+f',
      openReplaceDialog: 'ctrl+r',
    },
  };


  return (
    <AdminLayout
      navSelected={"Special Services"}
      regularContainer={special_service_id !== "new"}
    >
      <div className="row gap-10 alignStart">
        <div className="w-100">
          <div className="card">
            <p className="card-title">Special Service</p>

            {special_service_id !== "new" && <div className='mt-20 mb-10'>
              <FileUploadField
                text={"Upload Special Service Image"}
                upload_url={`${config.endpoint}/file/special/services/id/${special_service_id}`}
                max_file_size={5}
                max_files={1}
                onChange={(file_id) => { }}
              />
            </div>}
            <div className="row gap-10 alignCenter">
              <div className="input-container">
                <label htmlFor="" className="input-label">Name</label>
                <input type="text" className="text-input" placeholder='e.g. Christmas 2024' value={specialServiceData.name} onChange={(e) => setSpecialServiceData({ ...specialServiceData, name: e.target.value })} />
              </div>
              {special_service_id !== "new" && <div className="row gap-10 alignCenter">
                <p className="input-label">Draft</p>
                <Switch onChange={(checked) => setSpecialServiceData({ ...specialServiceData, is_published: checked ? 1 : 0 })} checked={specialServiceData.is_published == 1} />
                <p className="input-label">Published</p>
              </div>}
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Tagline</label>
              <input type="text" placeholder='e.g. Come join us this advent season!' className="text-input" value={specialServiceData.tagline} onChange={(e) => setSpecialServiceData({ ...specialServiceData, tagline: e.target.value })} />
            </div>
            <div className="row alignCenter gap-10">
              <div className="input-container">
                <label htmlFor="" className="input-label">Start Date</label>
                <input type="datetime-local" className="text-input" value={specialServiceData.start_datetime} onChange={(e) => setSpecialServiceData({ ...specialServiceData, start_datetime: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">End Date</label>
                <input type="datetime-local" className="text-input" value={specialServiceData.end_datetime} onChange={(e) => setSpecialServiceData({ ...specialServiceData, end_datetime: e.target.value })} />
              </div>
            </div>

            <div className='input-container'>
              <label htmlFor="" className="input-label">Theme</label>
              <div className={`row gap-10 alignStart`}>
                <div style={{ width: "100%", height: 50, borderRadius: 10, border: "2px solid black", backgroundColor: specialServiceData?.theme }}></div>
                <HexColorPicker color={specialServiceData?.theme?.toLowerCase()}
                  style={{ width: "100%", height: 100, borderRadius: 10 }}
                  onChange={
                    (color) => setSpecialServiceData({ ...specialServiceData, theme: color })
                  } />
              </div>
            </div>


            <div className="input-container">
              <label htmlFor="" className="input-label">Description</label>
              <JoditEditor
                ref={editor}
                value={specialServiceData.description}
                config={liveConfig}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => {
                  setSpecialServiceData({ ...specialServiceData, description: newContent })
                }}

              />
            </div>

            <div className="column alignEnd">
              <div className="row gap-10 alignCenter">
                {special_service_id !== "new" && <button className="button-delete"
                  onClick={() => {
                    deleteSpecialService()
                  }}
                >Delete</button>}
                <button className="button"
                  onClick={() => {
                    if (special_service_id === "new") {
                      createSpecialService()
                    } else {
                      updateSpecialService()
                    }
                  }}
                >Save</button>
              </div>
            </div>
          </div>
        </div>
        {special_service_id !== "new" && <div className="w-50">
          <div className="card">
            <p className="card-title">Services</p>
          </div>
        </div>}
      </div>
    </AdminLayout>
  )
}

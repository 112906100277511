import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import { Helmet } from "react-helmet";

const WhistleBlowingPolicy = () => {
  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Whistle Blowing Policy - Bedok Methodist Church</title>
        </Helmet>
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: 20,
            borderRadius: 10,
            color: "#777777",
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
            BEDOK METHODIST CHURCH (BMC)
            <br />
            WHISTLE-BLOWING POLICY
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>1. OBJECTIVE</strong>
          </p>
          <p>1.1 To set out a whistle blowing policy for BMC.</p>
          <p>
            1.2 Whistle-blowing is the disclosure by a person who is usually an
            employee in an organisation (termed a whistle-blower), of
            mismanagement, corruption, illegality, or some other wrongdoing
            within an organisation.
          </p>
          <p>1.3 The objectives of this policy are:</p>
          <p>
            1.3.1 to provide a platform for employees, volunteers, business
            partners, and other stakeholders to duly disclose mismanagement,
            corruption, illegality or some other wrong doing, in BMC.
          </p>
          <p>
            1.3.2 to ensure that such concerns are treated seriously and
            appropriately, and
          </p>
          <p>
            1.3.3 to ensure that that any person(s) raising such concerns in
            good faith will be protected from reprisals or retaliation.
          </p>
          <p>
            1.4 BMC envisages an environment where timely and appropriate
            actions are taken to deal with misconduct or malpractice, thereby
            promoting a culture of integrity and transparency.
          </p>
          <p>
            1.5 BMC’s Local Church Executive Committee (LCEC) is responsible for
            initiating any reviews and update to these guidelines.
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>2. SCOPE</strong>
          </p>
          <p>
            2.1 This policy outlines the reporting process for serious and
            sensitive concerns, defined as any (or suspicion of) misconduct,
            irregularity, breach of law, regulation, code, principle, or policy,
            that could have an adverse impact on the operations and performance
            of BMC and which, due to the nature of the concern, cannot be
            reported through the normal reporting line.
          </p>
          <p>
            2.2 This policy relies upon the reasonable belief of employees,
            volunteers, business partners, and other stakeholders, and should
            not be used as a mechanism for raising malicious or unfounded
            allegations against colleagues.
          </p>
          <p>
            2.3 The nature of the concerns covers, but is not limited to, any of
            the following acts:
          </p>
          <p>2.3.1 abuse of power or authority;</p>
          <p>
            2.3.2 financial fraud e.g. misappropriation of assets, embezzlement
            of funds, accounting manipulation;
          </p>
          <p>
            2.3.3 non-compliance of internal controls procedure or applicable
            code(s) of conduct;
          </p>
          <p>2.3.4 material conflicts of interest without disclosure;</p>
          <p>
            2.3.5 bribery and corruption e.g. inappropriate personal gain, gift,
            or facilitation payments;
          </p>
          <p>
            2.3.6 serious endangerment to environment, health, and safety of
            individuals;
          </p>
          <p>2.3.7 breach of law and justice;</p>
          <p>2.3.8 improper conduct; and</p>
          <p>
            2.3.9 concealment, or deliberate failure to report, a known control
            breach or weakness.
          </p>
          <p>
            2.4 This policy does not cover grievances. Grievance relates to an
            individual or personal matter and is not a whistle-blowing concern.
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>
              3. REPORTING PROCEDURE
            </strong>
          </p>
          <p>
            3.1 BMC encourages whistle-blowers to put their names to their
            submission. Concerns expressed anonymously are more difficult to act
            upon effectively, but they will be considered, taking into account
            the seriousness and credibility of the issue raised and the
            likelihood of confirming the allegation from attributable sources
            and information provided.
          </p>
          <p>
            3.2 The whistle-blower may make report via email to:{" "}
            <a
              href="mailto:governance@bmc.org.sg"
              style={{
                color: "#334862",
                textDecoration: "none",
              }}
            >
              governance@bmc.org.sg
            </a>{" "}
            or submit a written and sealed report, marked ‘Private &amp;
            Confidential’ to <em>[BMC Church Office]</em>, attention to: Church
            Governance Chairperson (“Governance Chairperson”).
          </p>
          <p>
            3.3. As it is essential for BMC to have all critical information in
            order to be able to efficiently evaluate and investigate a concern,
            the report should include: (a) background, history, and reason for
            the concern; (b) date(s) or period of time; (c) nature of concern;
            (d) name of the person involved in the events; (e) evidence
            substantiating the concern (eg. documents, e-mails, voice logs, or
            witness); and (f) contact details (in case further information is
            required).
          </p>
          <p>
            3.4 The whistle-blower who reports a concern must meet any
            reasonable request to clarify any facts and/or circumstances,
            provide information, and cooperate with an investigation. A lack of
            information can be a reason to decide not to conduct an
            investigation and/or to conclude that the concern has no factual
            basis.
          </p>
          <p>
            3.5 The whistle-blower is required to keep all information
            confidential.
          </p>
          <p>
            3.6 Allegations made in bad faith may result in disciplinary or
            other appropriate action.
          </p>
          <p>
            3.7 No disciplinary measure or other step will be taken against the
            whistle-blower in the case of a concern made in good faith which
            later turns out to be mistaken, misguided, or inconclusive.
          </p>
          <p>
            3.8 The LCEC will be kept informed of all whistle-blowing cases.
          </p>
          <p>
            <strong>4. HANDLING OF REPORTING AND INVESTIGATION</strong>
          </p>
          <p>
            4.1 All concerns received in accordance with Section 3.2 above will
            be directed to the Governance Chairperson and/or his delegate.
          </p>
          <p>
            4.2 Upon receipt of concerns either via the whistle-blower report or
            email, the Governance Chairperson and/or his delegate shall:
          </p>
          <p>4.2.1 record all reported concerns in a master register;</p>
          <p>
            4.2.2 form a whistle-blowing panel (the “Panel”) comprising the LCEC
            Chairperson, Governance Chairperson, PPRC Chairperson, Personnel
            Management Chairperson and Finance Chairperson. Where the report or
            concern is made against or relates in any way to a member of the
            Panel, that member shall recuse him/herself) from any deliberations
            and decisions relating to the convening of an investigation into
            that report/concern and the manner in which that investigation shall
            be conducted.
          </p>
          <p>
            4.2.3 inform the Panel of the whistle-blowing concern and provide
            the Panel with a copy of the whistle-blowing report and/or other
            information received in connection with the whistle-blowing report;
          </p>
          <p>4.2.4 arrange a meeting of the Panel; and</p>
          <p>
            4.2.5 send an acknowledgement to the whistle-blower informing that
            the concern has been received.
          </p>
          <p>4.3 The Panel shall:</p>
          <p>4.3.1 review the details provided by the whistle-blower;</p>
          <p>
            4.3.2 consider the seriousness and credibility of the concern
            raised; and
          </p>
          <p>
            4.3.3 decide on action to be taken with respect to the concern
            raised.
          </p>
          <p>
            4.4 The Panel may appoint an independent committee of inquiry
            (“COI”) or appropriate advisor or external service provider to
            assist in conducting an investigation where necessary. Where the
            whistle-blower has provided contact details, he/she may be contacted
            for further clarification or information.
          </p>
          <p>
            4.5 The Panel may, in consultation with LCEC, refer any concern at
            any time to the appropriate regulatory authorities.
          </p>
          <p>
            4.6 Depending on the nature of the concern, the Panel may inform the
            subject of the concern of the allegations against him/ her and
            provide him/her with an opportunity to reply to such allegations.
            Any such communication must be strictly confidential and must
            preserve the anonymity of the whistle-blower.
          </p>
          <p>
            4.7 All concerns received in accordance to paragraph 3.2 will be
            reviewed as provided in paragraph 4.2 and 4.3 within a reasonable
            timeframe, but not in excess of 30 days unless there are
            circumstances that require an extension.
          </p>
          <p>
            4.8 When deemed appropriate by the Panel, an Inquiry Committee may
            be convened by the Panel comprising independent LCEC and/or
            Sub-Committee members as soon as possible; but no later than 30 days
            from the date of the completion of the review stated in paragraph
            4.7 above. The Inquiry Committee shall complete its investigations
            and submit its findings no later than 90 days of being convened. The
            Inquiry Committee may request for an extension of time to the Panel
            to submit its findings, and Panel shall consider such requests on a
            case by case basis.
          </p>
          <p>
            4.9 The process for the review in 4.7 till the submission of
            findings in 4.8 shall take no longer than 180 days from the receipt
            of the whistle-blower report by the Governance Chairperson. The
            Inquiry Committee may request for an extension of time to LCEC to
            complete its investigations and submit its findings and LCEC shall
            consider such requests on a case by case basis.
          </p>
          <p>
            4.10 The report of the Inquiry Committee will be reviewed by the
            Panel and its findings recorded.
          </p>
          <p>
            4.11 The Panel will decide on the appropriateness of placing any
            report of the Inquiry Committee, once completed, before LCEC,
            depending on the gravity and magnitude of the violation.
          </p>
          <p>
            4.12 With regards to a whistle-blower report against a minister or
            lay person, the disciplinary proceedings for Inquiry Committee,
            Committee of Investigation and Disciplinary Panel specified in para
            742-761 (minister) and para 762-774 (lay person) of the Book of
            Discipline shall be adopted, if applicable.
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>5. REMEDIAL ACTION</strong>
          </p>
          <p>
            5.1 If, at the conclusion of an investigation, the Panel determines
            that a violation or allegation(s) is substantiated, effective and
            appropriate remedial action commensurate with the severity of the
            offence will be recommended to the appropriate authority.
          </p>
          <p>
            5.2 The Whistle-blower will receive a notification of the outcome.
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>
              5. PROTECTION AND CONFIDENTIALITY
            </strong>
          </p>
          <p>
            5.1 BMC will treat all concerns and issues raised under this policy
            in a confidential manner except to the extent necessary to conduct a
            complete and fair investigation or as required by law. The identity
            of the whistle-blower will be kept confidential.
          </p>
          <p>
            5.2 If necessary or lawful circumstances dictate the disclosure of
            the whistle-blower’s identity, BMC will inform the whistle-blower
            prior to such disclosure.
          </p>
          <p>
            <em>
              Adapted from TRAC Whistle-blowing Policy, November 2019
              <br />
              Revised and Approved by BMC LCEC, 17 July 2022
            </em>
          </p>
          <p>
            <a
              href="https://bmc.org.sg/wp-content/uploads/BMCWhistleblowingPolicy.pdf"
              target="_blank"
              rel="norefferer"
              style={{
                color: "#334862",
                textDecoration: "none",
                fontWeight: 600,
              }}
            >
              Click here to download BMC’s Whistleblowing Policy
            </a>
          </p>
        </div>
      </main>
      <SubFooter />
    </MainLayout>
  );
};

export default WhistleBlowingPolicy;

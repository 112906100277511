import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import { MdAdd } from 'react-icons/md';
import { tidyDate, tidyDateRaw, tidyFrequencyName } from '../../utils/helper';
import notify from '../../utils/IANotification';

export default function PrayerSupportPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  const [prayerListData, setPrayerListData] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchPrayerList()
  }, [])

  const fetchPrayerList = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/prayer/users/prayer"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setPrayerListData(data.data)
    }
    setLoading(false)
  }


  const prayForPrayer = async (prayer_id) => {
    if (!prayer_id) return null
    let endpoint = config.endpoint + `/prayer/pray/${prayer_id}`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchPrayerList()
      notify({ message: "Saved", type: "success" })
    } else {
      notify({ message: "Unable", type: "error" })
    }
  }



  return (
    <AdminLayout
      navSelected={"Prayer Support"}
      toolbar={
        <div className='card row alignCenter justifySplit w-100' style={{ backgroundColor: "white" }}>
          <h1 className='medium'>Prayer Support</h1>
        </div>
      }
    >
      <div className="column alignCenter justifySplit w-100 mb-40">
        <input
          type="text"
          placeholder="Search..."
          className='text-input w-100'
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </div>

      {loading ? (
        <div className="row alignCenter justifyCenter w-100">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="w-100">
          {prayerListData.map((prayer, index) => {
            if (!JSON.stringify(prayer).toLowerCase().includes(query.toLowerCase())) return null
            return (
              <div
                key={index}
                className="row alignStart justifySplit w-100 card mt-10"
              >
                <div className="">
                  <h3 className='card-subtitle gray'>praying since {tidyDate(prayer.createdAt)}  |  {prayer?.prayers_count?.length} times</h3>
                  <h3 style={{ color: prayer?.is_answered == 1 ? "gray" : "black" }}>{prayer.content}</h3>
                  {prayer?.is_answered == 1 &&
                    <div style={{ marginTop: 10, paddingTop: 10, borderTop: "1px solid gray" }}>
                      <p className="small gray">Testimony</p>
                      <h3 style={{}}>{prayer.testimony}</h3>
                    </div>}
                </div>
                {prayer?.is_answered == 0 ? <button
                  className="button"
                  onClick={() => {
                    prayForPrayer(prayer.id)
                  }}
                >
                  Pray
                </button>
                  :
                  <p style={{ padding: "5px 10px", borderRadius: 15, backgroundColor: "yellowgreen", color: "black", fontSize: 12 }}>Answered</p>
                }

              </div>
            )
          })}
        </div>
      )}
    </AdminLayout>
  )
}

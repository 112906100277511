import React from 'react'
import Header from '../components/Header'
import { useState } from 'react'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import notify from '../utils/IANotification'
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react'
import { analyticsCollector } from '../utils/AppFunctions'

export default function AuthPage({ pageState }) {
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [contactNumber, setContactNumber] = useState("")


  let token = new URLSearchParams(window.location.search).get("token") || ""

  const register = async () => {
    let endpoint = config.endpoint + "/auth/register"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        contact_number: contactNumber,
        password: password,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      window.location.href = "/auth/login"
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const login = async () => {
    let endpoint = config.endpoint + "/auth/login"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: user,
        password: password
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      await analyticsCollector()
      window.location.href = "/admin"
      window.sessionStorage.removeItem("cred_warn")
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  const initiateRecovery = async () => {
    let endpoint = config.endpoint + "/auth/email/recovery"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: user
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const resetPasword = async () => {
    let endpoint = config.endpoint + "/auth/email/recovery/reset"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        password: password,
        token: window.location.search.split("?token=")[1]
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      window.location.href = "/auth/login"
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const verifyRecovery = async () => {
    if (pageState !== "reset") return
    if (!token && pageState === "reset") return window.location.href = "/"
    let endpoint = config.endpoint + "/auth/email/recovery/verify"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: token
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      // notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
      window.location.href = "/auth/login"
    }
  }

  useEffect(() => {
    verifyRecovery()
  }, [])


  return (
    <div>
      <Header />
      <div>
        <ToastContainer />
        <div className='column justifyCenter alignCenter' style={{ width: "100%", marginTop: 40 }}>
          {pageState === "login" && <>
            <h1 className="headerText" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Log In to Your Account</h1>
            <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
              <div className="input-container">
                <input type="text" placeholder="Phone Number, username or email address" className="text-input" style={{ marginBottom: 10 }} value={user} onChange={(e) => {
                  setUser(e.target.value)
                }} />
                <input type="password" placeholder="Password" className="text-input" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                  setPassword(e.target.value)
                }} />
              </div>
              <button className="backgroundLess"
                onClick={() => {
                  window.location.href = "/auth/recovery"
                }}
              >Forgot Password</button>
              <div className="button-container" style={{ marginTop: 30 }}>
                <button className="button" style={{ width: "100%" }}
                  onClick={() => {
                    login()
                  }}
                >Log In</button>

              </div>
              <p style={{ marginTop: 20, fontSize: 12 }}>{config.version}</p>
              {config.feature_flag.allow_registration_web && <>
                <div style={{ marginTop: 30 }}>
                  <p style={{
                    backgroundColor: "#ebeff3", position: "relative", translate: "transformY(-50%)", top: "50%", bottom: -17, textAlign: "center", fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, width: "fit-content", margin: "auto"
                  }}>Or create an Account</p>
                  <div style={{ width: "100%", height: 0.5, backgroundColor: "black" }} />
                </div>

                <div className="button-container" style={{ marginTop: 30 }}>
                  <button className="button-cta" style={{ width: "100%", padding: "10px 20px" }}
                    onClick={() => {
                      window.location.href = "/auth/register"
                    }}
                  >Create an Account</button>
                </div>

              </>}
            </div>
          </>}

          {pageState === "register" && config.feature_flag.allow_registration_web && <>
            <h1 className="headerText" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Welcome to Omega.</h1>
            <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
              <div className="input-container">
                <div className="row gap-20 alignCenter" style={{ marginBottom: 10 }}>
                  <input type="name" placeholder="First Name" className="text-input" style={{ width: "100%" }} value={firstName} onChange={(e) => {
                    setFirstName(e.target.value)
                  }} />
                  <input type="name" placeholder="Last Name" className="text-input" style={{ width: "100%" }} value={lastName} onChange={(e) => {
                    setLastName(e.target.value)
                  }} />
                </div>
                <input type="email" placeholder="Email address" className="text-input" style={{ marginBottom: 10 }} value={email} onChange={(e) => {
                  setEmail(e.target.value)
                }} />
                <input type="tel" placeholder="Phone number" className="text-input" style={{ marginBottom: 10 }} value={contactNumber} onChange={(e) => {
                  setContactNumber(e.target.value)
                }} />
                <input type="password" placeholder="Password" className="text-input" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                  setPassword(e.target.value)
                }} />
              </div>
              <div className="button-container" style={{ marginTop: 30 }}>
                <button className="button" style={{ width: "100%" }}
                  onClick={() => {
                    register()
                  }}
                >Register</button>
              </div>

              <p style={{ marginTop: 20, textAlign: "center", padding: 20 }}>By proceeding, you agree to our <span style={{ color: "blue", fontWeight: "bold" }}>Terms and Condition</span> and <span style={{ color: "blue", fontWeight: "bold" }}>Privacy Policy</span></p>

              <div style={{ marginTop: 30 }}>
                <p style={{
                  backgroundColor: "#ebeff3", position: "relative", translate: "transformY(-50%)", top: "50%", bottom: -17, textAlign: "center", fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, width: "fit-content", margin: "auto"
                }}>Already have an account? Log In</p>
                <div style={{ width: "100%", height: 0.5, backgroundColor: "black" }} />
              </div>

              <div className="button-container" style={{ marginTop: 30 }}>
                <button className="button-cta" style={{ width: "100%", padding: "10px 20px" }}
                  onClick={() => {
                    window.location.href = "/auth/login"
                  }}
                >Log into your Account</button>
              </div>
            </div>
          </>}

          {pageState === "recovery" && <>
            <h1 className="headerText" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Recover your Account</h1>
            <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
              <div className="input-container">
                <input type="text" placeholder="Email address" className="text-input" style={{ marginBottom: 10 }} value={user} onChange={(e) => {
                  setUser(e.target.value)
                }} />
              </div>
              <div className="button-container" style={{ marginTop: 30 }}>
                <button className="button" style={{ width: "100%" }}
                  onClick={() => {
                    initiateRecovery()
                  }}
                >Send Recovery Link</button>
              </div>
            </div>
          </>}

          {pageState === "reset" && <>
            <h1 className="headerText" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Reset your Password</h1>
            <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
              <div className="input-container">
                <input type="password" placeholder="New Password" className="text-input" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                  setPassword(e.target.value)
                }} />
              </div>
              <div className="button-container" style={{ marginTop: 30 }}>
                <button className="button" style={{ width: "100%" }}
                  onClick={() => {
                    resetPasword()
                  }}
                >Reset Password</button>
              </div>
            </div>
          </>}
        </div>
      </div>
    </div>
  )
}

const AboutCard = ({ imgUrl, title, description }) => {
  return (
    <div
      className="card mt-30"
      style={{
        borderRadius: 20,
        padding: 10,
      }}
    >
      <div>
        <img
          src={imgUrl}
          alt={title}
          width="100%"
          style={{ borderRadius: 10 }}
        />
      </div>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          textAlign: "center",
          paddingBlock: 20,
          paddingInline: 10,
        }}
      >
        <h3
          style={{
            color: "#555555",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          {title}
        </h3>
        <p style={{ color: "#777777", fontSize: "0.8rem" }}>{description}</p>
      </div>
    </div>
  );
};

export default AboutCard;

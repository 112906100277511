import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import { Helmet } from "react-helmet";

const PrayerSupport = () => {
  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Prayer Support - Bedok Methodist Church</title>
        </Helmet>
        <div
          style={{
            position: "relative",
            background: "url('prayer-support.jpeg')",
            minHeight: 400,
            backgroundSize: "cover",
            backgroundPosition: "50% 20%",
            backgroundColor: "#555555",
            backgroundBlendMode: "multiply",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1
            className="prayer-support"
            style={{
              fontWeight: 600,
              color: "#ffffff",
              margin: "auto",
              textAlign: "center",
              position: "absolute",
              padding: 20,
            }}
          >
            PRAYER SUPPORT
          </h1>
        </div>
        <section
          style={{
            backgroundColor: "#ffffff",
            padding: 20,
            borderRadius: 10,
            marginTop: 30,
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: "#777777",
              width: "80%",
              margin: "auto",
            }}
          >
            <h2
              style={{
                fontWeight: "bold",
                color: "#555555",
                fontSize: "1.5rem",
                marginBottom: 10,
              }}
            >
              Do You Have A Prayer Need?
            </h2>
            <p>
              Our prayer intercessors are ready to pray for you and your loved
              ones. We invite you to submit your prayer requests via this form.
              You can choose to remain anonymous, or you can provide your email
              address if you would like to be contacted. All prayer requests
              will be kept strictly confidential.
            </p>
            <br />
            <p>
              Our online altar ministry team will pray with you after service on
              Sunday mornings or by phone during the week. Please leave your
              contact details and we will connect with you. For more details,
              contact{" "}
              <a
                href="mailto:prayer@bmc.org.sg."
                style={{ color: "#334862", textDecoration: "none" }}
              >
                prayer@bmc.org.sg.
              </a>
            </p>
          </div>
        </section>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default PrayerSupport;

import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../components/Button";
import Carousel from "react-multi-carousel";
import SubFooter from "../components/SubFooter";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Home = () => {
  function glowingStar() {
    const starElement = document.createElement("div");
    starElement.className = "star";
    return starElement;
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      const starElement = glowingStar();
      const starBackdrop = document.querySelector("#star-backdrop");

      starElement.style.left =
        Math.random() * getComputedStyle(starBackdrop).width.replace("px", "") +
        "px";
      let size = Math.random() * 12;

      starElement.style.width = size + "px";
      starElement.style.height = size + "px";

      starBackdrop.appendChild(starElement);
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
    }, 15000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Bedok Methodist Church</title>
      </Helmet>
      <section className="mb-30">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition=""
          arrows={false}
          transitionDuration={2000}
          containerClass="carousel-container"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="carousel-img" style={{ position: "relative" }}>
            <img
              className="carousel-img"
              src="bmc_img1.jpeg"
              alt="SHINE Banner"
              style={{ filter: " brightness(70%)" }}
            />
            <h1
              className="header-text"
              style={{
                position: "absolute",
                inset: 0,
                textAlign: "center",
                fontWeight: 600,
                textShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
              }}
            >
              WELCOME TO
              <h1 className="header-text-name" style={{ fontWeight: 600 }}>
                Bedok Methodist Church
              </h1>
            </h1>
          </div>
          <div>
            <img
              src="bmc_img2.jpeg"
              alt="SHINE Banner"
              style={{ filter: " brightness(70%)" }}
            />
          </div>
          <div>
            <img
              src="bmc_img3.jpeg"
              alt="SHINE Banner"
              style={{ filter: " brightness(70%)" }}
            />
          </div>
          <div>
            <img
              src="bmc_img4.jpeg"
              alt="SHINE Banner"
              style={{ filter: " brightness(70%)" }}
            />
          </div>
          <div>
            <img
              src="bmc_img5.jpeg"
              alt="SHINE Banner"
              style={{ filter: " brightness(70%)" }}
            />
          </div>
          <div>
            <img
              src="bmc_img6.jpeg"
              alt="SHINE Banner"
              style={{ filter: " brightness(70%)" }}
            />
          </div>
        </Carousel>
      </section>
      <section>
        <Link to="/missions-release">
          <img src="SHINEbanner.png" alt="SHINE Banner"></img>
        </Link>
      </section>
      <section className="services-grid mt-30" style={{}}>
        <div style={{ position: "relative", zIndex: 0, height: "100%" }}>
          <h2
            className="large white b-600"
            style={{
              position: "absolute",
              padding: 20,
            }}
          >
            BMC NEWS AND UPDATES
          </h2>
          <img
            src="web_church1.jpg"
            alt="BMC News & Updates"
            width="100%"
            style={{ height: "100%" }}
          />
          <Button title="Click to read" href="/announcements" />
        </div>
        <div style={{ position: "relative", zIndex: 0 }}>
          <img src="worship-onsite.jpeg" alt="Worship On-Site" width="100%" />
        </div>
        <a href="online-services">
          <div style={{ position: "relative", zIndex: 0 }}>
            <img src="worship-online.jpeg" alt="Worship Online" width="100%" />
          </div>
        </a>
        <a href="sermon-series">
          <div
            style={{
              position: "relative",
              zIndex: 0,
              height: "100%",
            }}
          >
            <h2
              className="large white b-600"
              style={{
                position: "absolute",
                padding: 20,
              }}
            >
              SERMON SERIES
            </h2>

            <img
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              src="sermon-series.jpeg"
              alt="Sermon Series"
              width="100%"
            />
          </div>
        </a>

        <div style={{ position: "relative", zIndex: 0 }}>
          <h2
            className="large white b-600"
            style={{
              position: "absolute",
              padding: 20,
            }}
          >
            GIVING
          </h2>
          <img src="grapes.jpeg" alt="Giving" width="100%" />
          <Button title="Tithes & Offerings" href="tithes" />
        </div>
        <div style={{ position: "relative", zIndex: 0 }}>
          <h2
            className="large white b-600"
            style={{
              position: "absolute",
              padding: 20,
            }}
          >
            PRAYER SUPPORT
          </h2>
          <img src="prayers.jpeg" alt="Contact Us" width="100%" />
          <Button title="Contact Us" href="/prayer-requests" />
        </div>
      </section>
      <section
        className="mt-30"
        style={{
          display: "flex",
          alignItems: "center",
          backgroundImage: "url(BMC_Shermeen1.jpeg)",
          width: "100%",
          height: 345,
          backgroundSize: "cover",
          backgroundPosition: "50% 35%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          id="star-backdrop"
          style={{
            position: "absolute",
            inset: 0,
          }}
        ></div>
        <div
          style={{
            positon: "absolute",
            inset: 0,
            background: "grey",
            width: "inherit",
            height: "inherit",
            opacity: 0.8,
            mixBlendMode: "multiply",
          }}
        ></div>
        <div
          style={{
            zIndex: 1,
            position: "absolute",
            padding: 20,
          }}
        >
          <h2
            className="b-600 extra-large mb-30"
            style={{ color: "#FFFF00", maxWidth: "90%", lineHeight: "0.9" }}
          >
            NEW TO BMC? LET'S CONNECT!
          </h2>
          <Link
            to="/new"
            className="white b-600 services-button mt-10"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 10,
              borderRadius: 99,
              paddingBlock: 10,
              paddingInline: 20,
              textTransform: "uppercase",
              width: "fit-content",
              fontSize: 16,
            }}
          >
            Click here
            <img src="user.svg" alt="user icon" width={20} />
          </Link>
          <Link
            to="/whatsapp"
            className="white b-600 services-button mt-10"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 10,
              borderRadius: 99,
              paddingBlock: 10,
              paddingInline: 20,
              textTransform: "uppercase",
              width: "fit-content",
              fontSize: 16,
            }}
          >
            Join our telegram & whatsapp channels
            <img src="telegram.svg" alt="telegram icon" width={20} />
          </Link>
        </div>
      </section>

      <SubFooter />
    </div>
  );
};

export default Home;

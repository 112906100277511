import React from "react";

const StaffCard = ({ imgUrl, name, position }) => {
  return (
    <div
      className="staff-member"
      style={{
        position: "relative",
        width: "fit-content",
      }}
    >
      <img
        className="staff-member-img"
        src={imgUrl}
        alt={name}
        style={{ borderRadius: 25, width: 250, margin: "auto" }}
      />
      <div
        className="staff-member-overlay"
        style={{
          position: "absolute",
          inset: 0,
          backgroundColor: "#777777",
          borderRadius: 25,
          margin: "auto",
          cursor: "pointer",
        }}
      ></div>
      <div
        className="staff-member-info"
        style={{
          position: "absolute",
          top: "50%",
          left: 0,
          right: 0,
          marginInline: "auto",
          textAlign: "center",
        }}
      >
        <p
          style={{
            color: "#FFFFFF",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {name}
        </p>
        <p
          style={{
            color: "#FFFFFF",
            fontWeight: 400,
            fontSize: "0.6rem",
            textTransform: "uppercase",
          }}
        >
          {position}
        </p>
      </div>
    </div>
  );
};

export default StaffCard;

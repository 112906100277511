import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import Select from 'react-select'

export default function DiscipleGroupClustersFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let dg_cluster_id = new URLSearchParams(window.location.search).get("dg_cluster_id")

  useEffect(() => {
    fetchPlatformUsers()
    fetchDGClusterData()
    fetchDGGroupByClusterData()
  }, [])

  const [dgClusterData, setDGClusterData] = useState({
    name: "",
    description: "",
    user_id: "",
  })
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [dgGroupsData, setDgGroupsData] = useState([])

  const fetchDGClusterData = async () => {
    if (!dg_cluster_id || dg_cluster_id === "new") return
    let endpoint = config.endpoint + "/dg/clusters/id/" + dg_cluster_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setDGClusterData(data.data)
    }
  }


  const fetchDGGroupByClusterData = async () => {
    if (!dg_cluster_id || dg_cluster_id === "new") return
    let endpoint = config.endpoint + "/dg/clusters/groups/" + dg_cluster_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setDgGroupsData(data.data)
    }
  }

  const createDGCluster = async () => {
    let endpoint = config.endpoint + "/dg/clusters/"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(dgClusterData),
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/admin/dg/clusters/details?dg_cluster_id=" + data.data.id)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const updateDGCluster = async () => {
    let endpoint = config.endpoint + "/dg/clusters/id/" + dg_cluster_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(dgClusterData),
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/admin/dg/clusters/details?dg_cluster_id=" + dg_cluster_id)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteDGCluster = async () => {
    let endpoint = config.endpoint + "/dg/clusters/id/" + dg_cluster_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      // Go back
      navigate(-1)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchPlatformUsers = async () => {
    setLoading(true)
    let endpoint = config.endpoint + "/users/"
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setUsers(data.data)
      console.log(data.data)
    }
    setLoading(false)
  }

  return (
    <AdminLayout
      navSelected={"DG Clusters"}
      regularContainer={dg_cluster_id !== "new"}
    >
      <div className="row gap-10 alignStart">
        <div className={dg_cluster_id === "new" ? "w-100" : "w-70"}>
          <div className="card">
            <p className="card-title">DG Cluster Zones</p>
            <p className="card-subtitle">Grouping by location</p>
            <div className="row gap-10 alignCenter">
              <div className="input-container">
                <label className='input-label'>Name</label>
                <input
                  type="text"
                  className="text-input"
                  placeholder='e.g. Central-1A'
                  value={dgClusterData.name}
                  onChange={(e) => {
                    setDGClusterData({ ...dgClusterData, name: e.target.value })
                  }}
                />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Cluster Leader</label>
                <Select
                  options={users.map((user) => {
                    return {
                      value: user.id,
                      label: user.first_name + " " + user.last_name,
                    }
                  })}
                  value={users.map((user) => {
                    return {
                      value: user.id,
                      label: user.first_name + " " + user.last_name,
                    }
                  }).filter((user) => user.value === dgClusterData.user_id)?.[0]}
                  onChange={(e) => {
                    setDGClusterData({ ...dgClusterData, user_id: e.value })
                  }}
                />
              </div>
            </div>
            <div className="input-container">
              <label className='input-label'>Description</label>
              <textarea
                className='textarea-input'
                value={dgClusterData.description}
                onChange={(e) => {
                  setDGClusterData({ ...dgClusterData, description: e.target.value })
                }}
              />
            </div>
            <div className="column mt-10 w-100 right alignEnd">
              <div className="row gap-10 alignCenter">
                {dg_cluster_id !== "new" && (
                  <button className="button-delete"
                    onClick={() => {
                      deleteDGCluster()
                    }}
                  >Delete</button>
                )}
                <button
                  className="button"
                  onClick={() => {
                    if (dg_cluster_id === "new") {
                      createDGCluster()
                    } else {
                      updateDGCluster()
                    }
                  }}
                >Save</button>
              </div>
            </div>
          </div>
        </div>
        {dg_cluster_id !== "new" && (
          <div className="w-30">
            <div className="card">
              <p className="card-title">DG Groups</p>
              <p className="card-subtitle">Groups in this cluster</p>
              <div className="row gap-10 alignCenter">
                <div className="column w-100">
                  {dgGroupsData?.length > 0 && dgGroupsData.map((dgGroup, index) => {
                    return (
                      <div
                        key={index}
                        className="row alignCenter justifySplit w-100 card mt-10"
                      >
                        <div className="">
                          <h3>{dgGroup.name}</h3>
                          <h3 className='card-subtitle'>{dgGroup?.description}</h3>
                        </div>
                        <button
                          className="button"
                          onClick={() => {
                            navigate("/admin/dg/details?dg_id=" + dgGroup.id)
                          }}
                        >
                          View
                        </button>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

        )}
      </div>
    </AdminLayout>
  )
}

import MainLayout from "../layouts/MainLayout";
import SubFooter from "../components/SubFooter";
import { MdCheck } from "react-icons/md";
import { Helmet } from "react-helmet";

const Tithes = () => {
  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Tithes & Offerings - Bedok Methodist Church</title>
        </Helmet>
        <div
          style={{
            position: "relative",
            background: "url('grapes.jpeg')",
            minHeight: 250,
            backgroundSize: "cover",
            backgroundPosition: "50% 20%",
            backgroundColor: "#777777",
            backgroundBlendMode: "multiply",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1
            className="tithes-cta"
            style={{
              fontWeight: 600,
              color: "#ffffff",
              margin: "auto",
              textAlign: "center",
              position: "absolute",
              padding: 20,
            }}
          >
            TITHES & OFFERINGS VIA IBANKING
          </h1>
        </div>

        <section style={{ backgroundColor: "#ffffff", padding: 20 }}>
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <MdCheck size={30} />
            <h3 style={{ fontWeight: "bold", color: "#555555" }}>PAYNOW</h3>
          </div>
          <hr />
          <br />
          <div className="tithes-instructions">
            <div>
              <img
                width="200"
                src="https://bmc.org.sg/wp-content/uploads/Phone_PayNow.jpg"
                alt=""
              />
            </div>
            <div>
              <ul
                style={{
                  padding: 20,
                  margin: "auto",
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <li>Open iBanking app.</li>
                <li>Tap on PayNow icon.</li>
              </ul>
            </div>
          </div>
          <div className="tithes-instructions">
            <div>
              <img
                width="200"
                src="	https://bmc.org.sg/wp-content/uploads/Phone_PayNow2.jpg"
                alt=""
              />
            </div>
            <div>
              <ul
                style={{
                  padding: 20,
                  margin: "auto",
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <li>Select Unique Entity Number (UEN) option.</li>
                <li>Enter BMC’s UEN number: S87CC0532HDBS</li>
                <li>Make a transfer.</li>
                <li>
                  You can request for an e-receipt via email to
                  finance@bmc.org.sg.
                </li>
              </ul>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <MdCheck size={30} />
            <h3 style={{ fontWeight: "bold", color: "#555555" }}>OR CODE</h3>
          </div>
          <hr />
          <br />
          <div className="tithes-instructions">
            <div>
              <img
                width="200"
                src="https://bmc.org.sg/wp-content/uploads/BMC_QRcode1.jpg"
                alt=""
              />
            </div>
            <div>
              <ul
                style={{
                  margin: "auto",
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <h3 style={{ fontWeight: "bold", color: "#555555" }}>
                  Bedok Methodist Church’s QR code
                </h3>
              </ul>
            </div>
          </div>

          <div className="tithes-instructions">
            <div>
              <img
                width="200"
                src="https://bmc.org.sg/wp-content/uploads/Phone_QR.jpg"
                alt=""
              />
            </div>
            <div>
              <ul
                style={{
                  padding: 20,
                  margin: "auto",
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <li>Open iBanking app.</li>
                <li>Tap on Scan & Pay icon.</li>
                <li>Scan QR code.</li>
              </ul>
            </div>
          </div>
          <div className="tithes-instructions">
            <div>
              <img
                width="200"
                src="https://bmc.org.sg/wp-content/uploads/Phone_QR2.jpg"
                alt=""
              />
            </div>
            <div>
              <ul
                style={{
                  padding: 20,
                  margin: "auto",
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <li>Enter BMC’s UEN number: S87CC0532HDBS</li>
                <li>Make a transfer.</li>
                <li>
                  You can request for an e-receipt via email to
                  finance@bmc.org.sg.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <SubFooter />
    </MainLayout>
  );
};

export default Tithes;

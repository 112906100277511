import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import Switch from 'react-switch'
import Select from 'react-select'
import notify from '../../utils/IANotification';

export default function MembersFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  // let { first_name, last_name, preferred_name, email, contact_number, account_type, account_remarks, date_of_birth, is_church_member, member_number, date_joined, registered_address, } = req.body
  const [memberFormData, setMemberFormData] = useState({
    first_name: "",
    last_name: "",
    preferred_name: "",
    email: "",
    contact_number: "",
    account_type: "",
    account_remarks: "",
    date_of_birth: "",
    is_church_member: "",
    member_number: "",
    date_joined: "",
    registered_address: "",
  })
  let user_id = new URLSearchParams(window.location.search).get("user_id")

  const ACCOUNT_TYPE = [
    {
      value: "guest",
      label: "Guest"
    },
    {
      value: "member",
      label: "Member"
    },
    {
      value: "dg-core",
      label: "DG Core"
    },
    {
      value: "missionary",
      label: "Missionary"
    },
    {
      value: "staff",
      label: "Staff"
    },
    {
      value: "admin",
      label: "Admin"
    }
  ]

  const [serviceSettings, setServiceSettings] = useState([])

  const fetchConfigSettings = async () => {
    let endpoint = config.endpoint + "/config"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })

    let data = await fetchResponse.json()
    if (data.status === 200) {
      console.log(data)
      let services = data?.data?.services
      services.forEach(service => {
        service.is_editing = "0"
      })
      setServiceSettings(services)
    } else {
      notify({ message: "An error occurred", type: "error" })
    }
  }


  const fetchUserById = async () => {
    if (!user_id) return navigate("/admin/users")
    let endpoint = config.endpoint + "/users/id/" + user_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setMemberFormData(data.data)
    }
  }

  const unlockAccount = async () => {
    let endpoint = config.endpoint + "/auth/account/unlock"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ user_id: user_id })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "Account unlocked", type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  
  }

  useEffect(() => {
    fetchUserById()
    fetchConfigSettings()
  }, [])

  const saveUserDetails = async () => {
    if (!user_id || user_id === "new") return notify({ message: "Unable to update user at this moment", type: "error" })
    let endpoint = config.endpoint + "/users/id/" + user_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(memberFormData)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "User details saved", type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const createUser = async () => {

    let endpoint = config.endpoint + "/users/"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(memberFormData)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "User created", type: "success" })
      navigate("/admin/users")
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteUser = async () => {
    if (!user_id || user_id === "new") return notify({ message: "Unable to delete user at this moment", type: "error" })
    let endpoint = config.endpoint + "/users/id/" + user_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "User deleted", type: "success" })
      navigate("/admin/users")
    } else {
      notify({ message: "An error occurred", type: "error" })
    }
  }

  return (
    <AdminLayout
      navSelected={"Members"}
      regularContainer={true}
    >
      <div className="row alignStart gap-10 w-100">
        <div className="w-70">
          <div className="card">
            <p className="card-title">Personal Information</p>
            <div className="row gap-10 alignCenter">
              <div className="input-container">
                <label htmlFor="" className="input-label">First Name</label>
                <input
                  type="text"
                  className="text-input"
                  placeholder='e.g. John'
                  value={memberFormData.first_name}
                  onChange={(e) => setMemberFormData({ ...memberFormData, first_name: e.target.value })}
                />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Last Name</label>
                <input
                  type="text"
                  className="text-input"
                  placeholder='e.g. Tan'
                  value={memberFormData.last_name}
                  onChange={(e) => setMemberFormData({ ...memberFormData, last_name: e.target.value })}
                />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Preferred Name</label>
              <input
                type="text"
                className="text-input"
                value={memberFormData.preferred_name}
                placeholder='e.g. JT'
                onChange={(e) => setMemberFormData({ ...memberFormData, preferred_name: e.target.value })}
              />
            </div>

            <div className="row gap-10 alignCenter">
              <div className="input-container">
                <label htmlFor="" className="input-label">Email</label>
                <input
                  type="email"
                  className="text-input"
                  value={memberFormData.email}
                  placeholder='e.g. johnntan234@gmail.com'
                  onChange={(e) => setMemberFormData({ ...memberFormData, email: e.target.value })}
                />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Contact Number</label>
                <input
                  type="tel"
                  className="text-input"
                  value={memberFormData.contact_number}
                  placeholder='e.g. 91234567'
                  onChange={(e) => setMemberFormData({ ...memberFormData, contact_number: e.target.value })}
                />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Date of Birth</label>
              <input
                type="date"
                className="text-input"
                value={memberFormData.date_of_birth?.split("T")[0]}
                onChange={(e) => setMemberFormData({ ...memberFormData, date_of_birth: e.target.value })}
              />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Registered Address</label>
              <textarea
                type="text"
                className="textarea-input"
                value={memberFormData.registered_address}
                placeholder='e.g. 123, Orchard Road, #01-01, 123456'
                onChange={(e) => setMemberFormData({ ...memberFormData, registered_address: e.target.value })}
              />
            </div>
          </div>

          <div className="card mt-10">
            <p className="card-title">Church</p>
            <div className="input-container">
              <label htmlFor="" className="input-label">Regular Service</label>
              <p className="card-subtitle">Service that the member usually attend</p>
              <Select
                options={serviceSettings?.map(option => {
                  return { value: option.id, label: option.name }
                })}
                value={serviceSettings?.map(option => {
                  return { value: option.id, label: option.name }
                }).find(option => option.value == memberFormData.service_type_id)}
                onChange={(e) => setMemberFormData({ ...memberFormData, service_type_id: e.value })}
              />
            </div>
            <div className="row gap-10">
              <div className="row gap-10 alignCenter">
                <Switch
                  checked={memberFormData.is_church_member == 1 ? true : false}
                  onChange={(checked) => setMemberFormData({ ...memberFormData, is_church_member: checked ? 1 : 0 })}
                />
                <p className="input-label" style={{ whiteSpace: "nowrap" }}>Church Member</p>
              </div>
              {memberFormData?.is_church_member == 1 && <div className="input-container">
                <label htmlFor="" className="input-label">Membership Number</label>
                <input
                  type="text"
                  className="text-input"
                  value={memberFormData.member_number}
                  placeholder='e.g. 12345'
                  onChange={(e) => setMemberFormData({ ...memberFormData, member_number: e.target.value })}
                />
              </div>}
            </div>
          </div>

          {/* <div className="card mt-10"></div> */}

          <div className="card mt-10">
            <p className="card-title">Account Information</p>
            <div className="input-container">
              <label htmlFor="" className="input-label">Account Role</label>
              <Select
                options={ACCOUNT_TYPE}
                value={ACCOUNT_TYPE.find(option => option.value == memberFormData.account_type)}
                onChange={(e) => setMemberFormData({ ...memberFormData, account_type: e.value })}
              />
            </div>
            <button
              className="button mb-10"
              onClick={() => unlockAccount()}
            >
              Unlock Account
            </button>
            <div className="input-container">
              <label htmlFor="" className="input-label">Account Remarks</label>
              <textarea
                type="text"
                className="textarea-input"
                value={memberFormData.account_remarks}
                onChange={(e) => setMemberFormData({ ...memberFormData, account_remarks: e.target.value })}
              />
            </div>

          </div>
        </div>
        {/* <div className="w-30">
          <div className="card">
            <p className="card-title">Family</p>
          </div>

          <div className="card mt-10">
            <p className="card-title">Disciple Group</p>
          </div>
        </div> */}
      </div>

      <div className="mt-10 column right alignEnd">
        <div className="row gap-10 alignCenter">
          {user_id !== "new" && <button
            className="button-delete"
            onClick={() => {
              deleteUser()
            }}
          >Delete</button>}
          <button
            className="button"
            onClick={() => {
              if (user_id === "new") {
                createUser()
              } else {
                saveUserDetails()
              }
            }}
          >Save</button>
        </div>
      </div>
    </AdminLayout>
  )
}

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import JoditEditor from 'jodit-react';
import Switch from "react-switch";
import { MdAdd, MdBlock, MdDelete, MdOutlineArtTrack } from 'react-icons/md';
import TextComponent from '../../components/plan/TextComponent';
import VerseComponent from '../../components/plan/VerseComponent';
import WebComponent from '../../components/plan/WebComponent';
import ImageComponent from '../../components/plan/ImageComponent';
import { ViewComponent } from 'jodit/esm/modules';
import AudioComponent from '../../components/plan/AudioComponent';
import DocumentComponent from '../../components/plan/DocumentComponent';
import VideoComponent from '../../components/plan/VideoComponent';
import FileUploadField from '../../components/FileUploadField';

export default function PlanFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  let plan_id = new URLSearchParams(window.location.search).get("plan_id")

  const [userHeaderHeight, setUserHeaderHeight] = useState(document?.getElementById("user-header")?.clientHeight)
  const [planHeaderHeight, setPlanHeaderHeight] = useState(document?.getElementById("plan-component")?.clientHeight)

  useLayoutEffect(() => {
    setUserHeaderHeight(document?.getElementById("user-header")?.clientHeight)
    setPlanHeaderHeight(document?.getElementById("plan-component")?.clientHeight)
  }, [userHeaderHeight, planHeaderHeight])

  const [planData, setPlanData] = useState({
    plan_name: "",
    plan_description: "",
    media_asset_id: "",
    frequency: "1",
    is_published: "0",
  })
  const [loaded, setLoaded] = useState(false)
  const [isPreview, setIsPreview] = useState(false)
  const [previousSave, setPreviousSave] = useState()

  const [sectionData, setSectionData] = useState([]) // [{plan_id, section_name, section_description, section_order}]
  const [planComponentsData, setPlanComponentsData] = useState([]) // [{plan_id, content_type, is_question, question_type, content, json_content, question_options}]


  const [selectedSection, setSelectedSection] = useState(0)

  const editor = useRef(null);
  const placeholder = "Start typing...";

  const liveConfig = {
    height: 500,
    placeholder,
    // set custom buttons
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'superscript',
      'subscript',
      'ul',
      'ol',
      'outdent',
      'indent',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'table',
      'link',
      'undo',
      'redo',
      'eraser',
      'copyformat',
    ],
    askBeforePasteHTML: false,
    hotkeys: {
      redo: 'ctrl+z',
      undo: 'ctrl+y,ctrl+shift+z',
      indent: 'ctrl+]',
      outdent: 'ctrl+[',
      bold: 'ctrl+b',
      italic: 'ctrl+i',
      removeFormat: 'ctrl+shift+m',
      insertOrderedList: 'ctrl+shift+7',
      insertUnorderedList: 'ctrl+shift+8',
      openSearchDialog: 'ctrl+f',
      openReplaceDialog: 'ctrl+r',
    },
  };

  const fetchPlanById = async () => {
    if (plan_id && plan_id !== "new") {
      let endpoint = config.endpoint + "/plan/id/" + plan_id
      let response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
      let data = await response.json()
      if (data.status === 200) {
        setPlanData(data?.data)
      } else {
        notify({ type: "error", message: "Failed to fetch plan data" })
      }
    }
  }

  const createPlan = async () => {
    let endpoint = config.endpoint + "/plan"
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(planData),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Plan created successfully" })
      navigate("/admin/plan/details?plan_id=" + data.data.id)
    } else {
      notify({ type: "error", message: "Failed to create plan" })
    }
  }

  const updatePlan = async () => {
    if (!plan_id || plan_id === "new") return
    let endpoint = config.endpoint + "/plan/id/" + plan_id
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(planData),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Plan updated successfully" })
    } else {
      notify({ type: "error", message: "Failed to update plan" })
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (plan_id && plan_id !== "new") {
      updatePlan()
      saveComponents()
      saveSection()
    } else {
      createPlan()
    }
  }

  const deletePlan = async () => {
    if (!plan_id || plan_id === "new") return
    let endpoint = config.endpoint + "/plan/id/" + plan_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Plan deleted successfully" })
      navigate("/admin/plan")
    } else {
      notify({ type: "error", message: "Failed to delete plan" })
    }
  }

  const fetchPlanComponents = async () => {
    setLoaded(false)
    if (!plan_id || plan_id === "new") return
    let endpoint = config.endpoint + "/plan/components/id/" + plan_id
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setPlanComponentsData(data.data.components)
      setSectionData(data.data.sections)
      if (!selectedSection?.id) setSelectedSection(data.data.sections[0])
    } else {
      notify({ type: "error", message: "Failed to fetch plan data" })
    }
    setLoaded(true)
  }

  const saveComponents = async (temp) => {
    if (!plan_id) return
    let endpoint = config.endpoint + "/plan/components"
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        components: temp? temp: planComponentsData,
        section: sectionData,
        plan_id: plan_id,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchPlanComponents()
      notify({ type: "success", message: "Components saved successfully" })
    } else {
      notify({ type: "error", message: "Failed to save components" })
    }
  }

  const saveSection = async (temp) => {
    if (!loaded) return
    if (JSON.stringify(sectionData) === previousSave) return
    let endpoint = config.endpoint + "/plan/sections"
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        section: temp ? temp : sectionData,
        plan_id: plan_id,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      setPreviousSave(JSON.stringify(sectionData))
      fetchPlanComponents()
      notify({ type: "success", message: "Components saved successfully" })
    } else {
      notify({ type: "error", message: "Failed to save components" })
    }
  }

  // useEffect(() => {
  //   saveSection()
  // }, [sectionData])



  useEffect(() => {
    fetchPlanById()
    fetchPlanComponents()
  }, [])


  return (
    <AdminLayout
      navSelected={"Plans & Materials"}
      regularContainer={plan_id !== "new"}
    >
      <div className="row gap-10 alignStart">
        <div className='w-100' style={{ position: "sticky", top: `${userHeaderHeight}px` }}>
          <div className="card mt-10">
            <p className="card-title">{plan_id && plan_id !== "new" ? "Edit Plan" : "Create Plan"}</p>
            <p className="card-subtitle">An interactive way of learning the word</p>

            {plan_id !== "new" && <div className="input-container">
              <label htmlFor="" className="input-label">Cover Photo</label>
              <FileUploadField
                onChange={(newContent) => { }}
                upload_url={`${config.endpoint}/file/plan/cover/id/` + plan_id}
                max_files={1}
                max_file_size={5}
                text={"Upload Cover Photo"}
                subtext={"Upload a cover photo for this plan"}
              />
            </div>}

            <div className="input-container">
              <label htmlFor="" className="input-label">Name</label>
              <input
                type="text"
                className="text-input"
                value={planData.plan_name}
                placeholder='e.g. Road to Obedience'
                onChange={(e) => setPlanData({ ...planData, plan_name: e.target.value })}
              />
            </div>
            <div className="row gap-10 alignCenter">
              <div className="input-container">
                <label htmlFor="" className="input-label">Frequency</label>
                <label htmlFor="" className="card-subtitle">How often should this plan be published? (e.g. User will do this plan daily)</label>
                <select
                  className="select-input"
                  value={planData.frequency}
                  onChange={(e) => setPlanData({ ...planData, frequency: e.target.value })}
                >
                  <option value={1}>Daily</option>
                  <option value={7}>Weekly</option>
                  <option value={30}>Monthly</option>
                </select>
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Published</label>
                <Switch
                  checked={planData.is_published === "1"}
                  onChange={(checked) => setPlanData({ ...planData, is_published: checked ? "1" : "0" })}
                />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Description</label>
              <textarea className="textarea-input"
                value={planData.plan_description}
                placeholder='e.g. This plan is designed to help you grow in your faith'
                onChange={(e) => setPlanData({ ...planData, plan_description: e.target.value })}
              />
            </div>
            <div className="column right alignEnd mt-10">
              <div className="row gap-10 alignCenter">
                {plan_id && plan_id !== "new" && (
                  <button onClick={deletePlan} className="button-delete">Delete</button>
                )}
                <button onClick={handleFormSubmit} className="button">Save</button>
              </div>
            </div>
          </div>
          {plan_id !== "new" && <div className="mt-10 card column">
            <p className="card-title">Content Overview</p>
            {sectionData?.map((section, index) => {
              return (
                <div key={index} className="column gap-10 alignStart">
                  <button className="backgroundLess"
                    onClick={() => {
                      // scroll to section
                      let sectionElement = document.getElementById(`section-${index}`)
                      sectionElement.scrollIntoView({ behavior: "smooth" })
                    }}
                  >{section?.section_name}</button>
                  {planComponentsData?.map((component, componentIndex) => {
                    if (component.section_id != section.id) return
                    return (
                      <button className="backgroundLess"
                        onClick={() => {
                          // scroll to component
                          let componentElement = document.getElementById(`section-${index}-component-${componentIndex}`)
                          componentElement.scrollIntoView({ behavior: "smooth" })
                        }}
                        style={{
                          paddingLeft: 10
                        }}
                      >{component?.content_type}</button>
                    )
                  })}
                </div>
              )
            })}
          </div>}
        </div>

        {plan_id !== "new" && (
          <div className='w-100'>
            <div className="card mt-10" style={{ position: "sticky", top: userHeaderHeight }} id='plan-component'>
              <div className="row alignCenter justifySplit">
                <div>
                  <p className="card-title">Plan Components</p>
                  <p className="card-subtitle">Add sections and components to your plan</p>
                </div>
                <div className="row gap-20 alignCenter">
                  <div className="row gap-10 alignCenter">
                    <p className="input-label">Preview</p>
                    <Switch
                      checked={isPreview}
                      onChange={(checked) => setIsPreview(checked)}
                    />
                  </div>
                  <button className="row backgroundLess alignCenter gap-10"
                    onClick={() => {
                      setSectionData([...sectionData, {
                        plan_id: plan_id,
                        section_name: `Section ${sectionData.length + 1}`,
                      }])
                    }}
                  >
                    <MdOutlineArtTrack size={12} />
                    <p className='black small'>Section</p>
                  </button>
                </div>
              </div>
            </div>
            {/* <pre>{JSON.stringify(planData, null, 2)}</pre>
            <pre>{JSON.stringify(planComponentsData, null, 2)}</pre> */}
            <div className="row gap-10 alignCenter"
              style={{ overflowX: "scroll", maxWidth: `calc(100dvw - 50dvw - 20px - 20px - 100px)`, position: "sticky", top: `${userHeaderHeight + planHeaderHeight - 10}px`, backgroundColor: "var(--background)", zIndex: 1000 }}
            // className="hide-scrollbars"
            >
              <div className="card mt-10 alignCenter justifyCenter center"
                onClick={() => {
                  saveSection([...sectionData, {
                    plan_id: plan_id,
                    section_name: `Section ${sectionData.length + 1}`,
                  }])
                }}
                style={{ minWidth: "120px", display: "flex", flexDirection: "column", padding: 10, minHeight: 120, cursor: "pointer", maxWidth: "120px" }}
              >
                <MdAdd size={20} color='gray' />
                <p className="" style={{ color: "gray", fontSize: 12 }}>Create Section</p>
              </div>

              {sectionData.map((section, sectionIndex) => {
                return (
                  <div className="card mt-10"
                    style={{ minWidth: "120px", minHeight: 120, justifyContent: "center", display: "flex", alignItems: "center", border: selectedSection.id === section.id ? "2px solid blue" : "none", cursor: "pointer", maxWidth: "120px" }}
                    onClick={() => setSelectedSection(section)}
                    key={sectionIndex}
                    id={`section-${sectionIndex}`}
                  >
                    <p className="card-title">{section.section_name}</p>

                    {/* <div className="row alignCenter justifySplit">
                    <div>
                      <p className="card-title">{section.section_name}</p>
                      <p className="card-subtitle">Add components to this section</p>
                    </div>
                    <div className="row gap-20 alignCenter">
                      <button className="row backgroundLess alignCenter gap-10"
                        onClick={() => {
                          if (!section.id) return saveSection()

                          setPlanComponentsData([...planComponentsData, {
                            section_id: section.id,
                            plan_id: plan_id,
                            content_type: "verse",
                            is_question: "0",
                            question_type: "",
                            content: "",
                            json_content: "",
                            question_options: "",
                          }])
                        }}
                      >
                        <MdAdd size={12} />
                        <p className='black small'>Component</p>
                      </button>
                    </div>
                  </div> */}
                  </div>
                )
              })}
            </div>

            <>
              {planComponentsData.map((component, index) => {
                if (component.section_id != selectedSection.id) return
                let component_id = planComponentsData.filter((c) => c.section_id == selectedSection.id).findIndex((c) => c.id == component.id) + 1
                  
                  

                return (
                  <div key={index} className='mb-10' style={{ borderBottom: "2px solid #f0f0f0", padding: 10 }}
                  // id={`section-${selectedSection}-component-${index}`}
                  >
                    {!isPreview && <>
                      <div className="row justifySplit w-100 alignCenter">
                        <p className="small" style={{ fontSize: 12, fontWeight: "900" }}>Component {component_id}</p>
                        <button className="backgroundLess row gap-10 alignCenter button-delete"
                          onClick={() => {
                            let newComponents = [...planComponentsData]
                            newComponents.splice(index, 1)
                            setPlanComponentsData(newComponents)
                          }}
                        >
                          <MdDelete size={12} />
                          <p className='small'>Delete</p>
                        </button>
                      </div>
                      <div className="row alignCenter gap-10">
                        <div className="input-container">
                          <label htmlFor="" className="input-label">Type</label>
                          <select
                            className="select-input"
                            value={component.content_type}
                            onChange={(e) => {
                              let newComponents = [...planComponentsData]
                              newComponents[index].content_type = e.target.value
                              setPlanComponentsData(newComponents)
                            }}
                          >
                            <option value="verse">Verse</option>
                            <option value="rich-text">Rich Text</option>
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                            <option value="audio">Audio</option>
                            <option value="web-link">Embedded HTML</option>
                            <option value="document">Document</option>
                          </select>
                        </div>
                      </div>
                    </>}
                    {component?.content_type === "rich-text" &&
                      <TextComponent
                        readOnly={isPreview}
                        data={component?.json_content}
                        onChange={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].json_content = newContent
                          setPlanComponentsData(newComponents)
                        }}
                      />}
                    {component?.content_type === "verse" && (
                      <VerseComponent
                        readOnly={isPreview}
                        data={component?.json_content}
                        onChange={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].json_content = newContent
                          setPlanComponentsData(newComponents)
                        }}
                      />
                    )}
                    {component?.content_type === "web-link" && (
                      <WebComponent
                        readOnly={isPreview}
                        data={component}
                        onChangeUrl={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].content = newContent
                          setPlanComponentsData(newComponents)
                        }}
                      />
                    )}
                    {component?.content_type === "image" && (
                      <ImageComponent
                        readOnly={isPreview}
                        id={index}
                        data={component}
                        onChangeUrl={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].content = newContent
                          setPlanComponentsData(newComponents)
                        }}
                        onChangeFile={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].media_asset_id = newContent
                          setPlanComponentsData(newComponents)
                        }}
                      />
                    )}
                    {component?.content_type === "video" && (
                      <VideoComponent
                        readOnly={isPreview}
                        id={index}
                        data={component}
                        onChangeUrl={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].content = newContent
                          setPlanComponentsData(newComponents)
                        }}
                        onChangeFile={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].media_asset_id = newContent
                          setPlanComponentsData(newComponents)
                        }}
                      />
                    )}
                    {component?.content_type === "audio" && (
                      <AudioComponent
                        readOnly={isPreview}
                        id={index}
                        data={component}
                        onChangeUrl={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].content = newContent
                          setPlanComponentsData(newComponents)
                        }}
                        onChangeFile={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].media_asset_id = newContent
                          setPlanComponentsData(newComponents)
                        }}
                      />
                    )}
                    {component?.content_type === "document" && (
                      <DocumentComponent
                        readOnly={isPreview}
                        id={index}
                        data={component}
                        onChangeUrl={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].content = newContent
                          setPlanComponentsData(newComponents)
                        }}
                        onChangeFile={(newContent) => {
                          let newComponents = [...planComponentsData]
                          newComponents[index].media_asset_id = newContent
                          setPlanComponentsData(newComponents)
                        }}
                      />
                    )}
                  </div>
                )
              })}

              {selectedSection?.id && <button className="button mt-10"
                onClick={() => {
                  // setPlanComponentsData([...planComponentsData, {
                  //   section_id: selectedSection.id,
                  //   plan_id: plan_id,
                  //   content_type: "verse",
                  //   is_question: "0",
                  //   question_type: "",
                  //   content: "",
                  //   json_content: "",
                  //   question_options: "",
                  // }])

                  // create new component
                  let newComponents = [...planComponentsData]
                  newComponents.push({
                    section_id: selectedSection.id,
                    plan_id: plan_id,
                    content_type: "verse",
                    is_question: "0",
                    question_type: "",
                    content: "",
                    json_content: "",
                    question_options: "",
                  })
                  saveComponents(newComponents)
                }}
              >Add Component</button>}
            </>

          </div>
        )}
      </div>
    </AdminLayout>
  )
}

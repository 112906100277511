import React, { useEffect, useState } from 'react'
import { MdUploadFile } from 'react-icons/md'
import notify from '../../utils/IANotification'
import config from '../../app-config'
import { isMobile } from '../../utils/helper'

export default function VideoComponent({ id, readOnly, data, onChangeFile, onChangeUrl }) {

    const [filesToUpload, setFilesToUpload] = useState([])
    const [width, setWidth] = useState(window.innerWidth)

    const submitDocuments = async () => {
        let formData = new FormData()
        for (let i = 0; i < filesToUpload.length; i++) {
            formData.append("files", filesToUpload[i])
        }
        let endpoint = config.endpoint + "/file/components"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            body: formData,
            credentials: "include"
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            // console.log(data.data.files)
            onChangeFile(data.data.files[0])
            notify({ message: "Files uploaded successfully", type: "success" })
            setFilesToUpload([])
        } else {
            // error
            notify({ message: data.message, type: "error" })
        }
    }

    useEffect(() => {
        let fileInput = document?.getElementById(`file-input-${id}`)
        let fileInputContainer = document?.getElementById(`file-input-container-${id}`)

        if (!fileInput || !fileInputContainer) return
        fileInput.addEventListener("dragover", (e) => {
            // add a class hover
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.add("hover")
        })
        fileInput.addEventListener("dragleave", (e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.remove("hover")
        })

        fileInput.addEventListener("drop", (e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputContainer.classList.remove("hover")
            // get the files
            let files = e.dataTransfer.files
            // convert to array
            files = [...files]
            // check if the file is valid
            if (files.length > 0) {
                // set the file input to the files
                fileInput.files = files
                // call the function to upload the files
                setFilesToUpload(files.slice(0, 1))
            }
        })
    }, [])
    return (
        <>
            {
                readOnly ?
                    <>
                        {/* <p>{data.url}</p> */}
                        {/* <img src={(data?.url || `${config.endpoint}${data?.file_data?.ext_file_path}`)} alt="" style={{ maxWidth: "100%", minWidth: 100, minHeight: 50, objectFit: "contain" }} /> */}
                        {/* <iframe src={data?.url || `${config.endpoint}${data?.file_data?.ext_file_path}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{ width: "100%", maxWidth: 1000, height: 500 }}></iframe> */}
                        <video src={data?.url || `${config.endpoint}${data?.file_data?.ext_file_path}`} controls style={{ width: "100%", maxWidth: 1000, height: 500 }} />
                    </>
                    :
                    <>
                        <div className="input-container" id={`file-input-${id}`}>

                        {!data.media_asset_id ? <div className={`${isMobile(width) ? "column":"row"} gap-10 alignCenter`}>
                                {!data.url && <div style={{ width: "100%" }}>
                                    <p className="input-label">File(s)</p>
                                    <input className="input" type="file" multiple accept="video/*" style={{ display: "none" }} id={`file-tag-${id}`} onChange={(e) => {
                                        let files = e.target.files
                                        files = [...files]
                                        if (files.length > 0) {
                                            setFilesToUpload(files.slice(0, 1))
                                        }
                                    }} />
                                    <div className="file-input-container cursor-pointer" id={`file-input-container-${id}`} onClick={() => {
                                        document.getElementById(`file-tag-${id}`).click()
                                    }}>
                                        <MdUploadFile size={20} color={"black"} />
                                        <p className="file-input-label">{filesToUpload.length > 0 ? `${filesToUpload.length} video attached` : "Upload Video"}</p>
                                    </div>
                                </div>
                                }

                                {!(filesToUpload.length > 0 || data.url) && <p> or</p>}

                                {!filesToUpload.length > 0 && <div className="input-container">
                                    <label htmlFor="" className="input-label">URL</label>
                                    <input type="text" className="text-input" placeholder="Enter URL"
                                        value={data.url}
                                        onChange={(e) => {
                                            onChangeUrl(e.target.value)
                                        }} />
                                </div>}
                            </div> :
                                <div>
                                    <p className="input-label">Attached File</p>
                                    <div className="row gap-10 alignCenter">
                                        {/* <img src={data?.url || `${config.endpoint}${data?.file_data?.ext_file_path}`} alt="" style={{ maxWidth: "200px", }} /> */}
                                        {/* <iframe src={data?.url || `${config.endpoint}${data?.file_data?.ext_file_path}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{ width: "100%", maxWidth: 1000, height: 500 }}></iframe> */}
                                        {/* <video src={data?.url || `${config.endpoint}${data?.file_data?.ext_file_path}`} controls style={{ width: "100%", maxWidth: 1000, height: 500 }}>
                                            <source src={data?.url || `${config.endpoint}${data?.file_data?.ext_file_path}`} type="video/mp4" />
                                        </video> */}
                                        <video src={data?.url || `${config.endpoint}${data?.file_data?.ext_file_path}`} controls style={{ width: 300, height: 100 }} />
                                        <p className="file-input-label">{data?.file_data?.file_name}</p>
                                    </div>
                                </div>}

                            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                            {filesToUpload.length > 0 &&
                                <>
                                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 10 }}>
                                        {filesToUpload.map((file, index) => {
                                            return (
                                                <div style={{
                                                    maxWidth: 200,
                                                    backgroundColor: "gray",
                                                    display: "flex",
                                                    gap: 10,
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    cursor: "pointer",
                                                    borderRadius: 5
                                                }}
                                                    onClick={() => {
                                                        let newFilesToUpload = filesToUpload
                                                        newFilesToUpload.splice(index, 1)
                                                        setFilesToUpload([...newFilesToUpload])
                                                    }}
                                                >
                                                    <p style={{
                                                        fontSize: 12,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        color: "white"
                                                    }}>{file.name}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="mt-10 right alignStart row">
                                        <button className="button row alignCenter gap-10" onClick={() => {
                                            submitDocuments()
                                        }}>
                                            <MdUploadFile size={20} color={"white"} />
                                            Upload</button>
                                    </div>
                                </>
                            }
                        </div>
                    </>
            }
        </>
    )
}

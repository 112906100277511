import React from "react";

const SubFooter = () => {
  return (
    <section className="subfooter mt-30" style={{}}>
      <div>
        <h2 className="b-600" style={{}}>
          BEDOK METHODIST CHURCH
        </h2>
        <div
          style={{
            maxWidth: "7%",
            height: 3,
            backgroundColor: "lightgrey",
            marginBottom: 10,
            marginTop: 5,
          }}
        ></div>
        <p>86 Bedok Road, Singapore 469371</p>
      </div>
      <div>
        <p>Telephone: 6448 4215</p>
        <p>Email: office@bmc.org.sg</p>
      </div>
    </section>
  );
};

export default SubFooter;

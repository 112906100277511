import SubFooter from "../components/SubFooter";
import MainLayout from "../layouts/MainLayout";
import { Helmet } from "react-helmet";

const OnlineServicesChinese = () => {
  return (
    <MainLayout>
      <main>
        <Helmet>
          <title>Chinese Worship Service - Bedok Methodist Church</title>
        </Helmet>
        <div
          className="mb-30"
          style={{
            backgroundColor: "#0094b3",
            mixBlendMode: "multiply",
            padding: 20,
            position: "relative",
          }}
        >
          <h1
            style={{
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              textShadow: "2px 2px 4px #000000",
              fontSize: "1.5rem",
            }}
          >
            华语现场直播崇拜
          </h1>
        </div>
        <div>
          <iframe
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen=""
            data-src-no-ap="https://www.youtube-nocookie.com/embed/NWYvX4DsUCs?feature=oembed&amp;iv_load_policy=3&amp;modestbranding=1&amp;rel=0&amp;autohide=1&amp;playsinline=0&amp;autoplay=0"
            frameborder="0"
            src="https://www.youtube-nocookie.com/embed/8otuFTmxrgI?feature=oembed&iv_load_policy=3&modestbranding=1&rel=0&autohide=1&playsinline=0&autoplay=0"
            title="Video"
            loading="lazy"
            style={{ width: "100%", aspectRatio: "16/9", marginBottom: 30 }}
          ></iframe>
        </div>
        <div className="">
          <a
            href="https://bmc.org.sg/wp-content/uploads/BMC2JunBulletinC.pdf"
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
          >
            <img
              src="Bulletin-ch.jpeg"
              alt="Live Chinese"
              style={{ width: "50%" }}
            />
          </a>
        </div>
        <SubFooter />
      </main>
    </MainLayout>
  );
};

export default OnlineServicesChinese;
